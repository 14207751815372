import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ListOfTemplate from "../components/courses/CourseGradeSettings/ListOfTemplate";
import AddTemplate from "../components/courses/CourseGradeSettings/AddTemplate";
import AuditTrail from "../components/courses/CourseGradeSettings/AuditTrails";
import PageComponent from "../components/common/PageComponent";
import { GetEditStatemantTmp } from "../services/GradeSettingService";
import axios from "axios";
import ListOfSOR from "../components/courses/CourseGradeSettings/ListOfSOR";
import SORForm from "../components/courses/CourseGradeSettings/SORForm";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { TrimText } from "../components/common/TrimText";

const TemplateTable = () => {
  const [updateData, setUpdateData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [templateData, setTemplateData] = useState({});
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const stdName = params.get("stdName");
  const history = useHistory()
  const courseName = params.get("courseName");
  const { id, tab } = useParams();

  useEffect(() => {
    const cancelTokenSources = [];

    const getProgramsList = async () => {
      setLoading(true);

      cancelTokenSources.forEach((source) => {
        source.cancel("New request made");
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      const data = {
        id: id,
      };

      try {
        const res = await GetEditStatemantTmp(
          data,
          source.token
        );
        if (res.status === 200) {
          if(res.data.result){

            console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>",res.data);
            setTemplateData(res.data.result);
            setLoading(false);
          }else{
            Swal.fire({
              icon: "warning",
              title: "Info",
              text: "No record found with this id",
            })
            history.push("/courseAdministration/gradingTemplate/table")
          }
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
          setLoading(false);
        }
      }
    };
    if (tab === "editsor") {
      getProgramsList();
    }

    return () => {
      cancelTokenSources.forEach((source) => {
        source.cancel("Component unmounted");
      });
    };
  }, [updateData, tab, id]);

  return (
    <PageComponent
      heading={
        tab === "grades"
          ? ["Gradebook:", courseName, stdName]
          : tab === "create" || tab === "edit" || tab === "editAuditTrails"
          ? [
              "Grading",
              "Letter",
              "Templates",
              tab !== "create" ? `${TrimText(templateData.name,50) ?? ""}` : "Create New Template",
            ]
            : tab === "addsor"
            ? [
                "Add",
                "",
                "New SOR Template",
              ] 
              : tab === "editsor" || tab === "editSorAuditTrails"
              ? [
                "SOR",
                "Template",
                `${TrimText(templateData.name,50) ?? ""}`
              ]
              : tab === "table"
              ? ["Grading", "Templates"]
              : tab === "sorTable"
              ? ["Grading", "Templates"]
          : ["Grading", "Templates"]
      }
      isBackButtonShown
      isBackButtonStandard
      disableColonShown
      auditTrailUrl={tab === "edit" || tab === "editAuditTrails" ? `/courseAdministration/gradingTemplate/editAuditTrails/${id}` : `/courseAdministration/gradingTemplate/auditTrails`}
      studentGrades={tab === "grades" ? true : false}
      addTemplate={
        tab === "create" || tab === "edit" || tab === "editAuditTrails"
      }
      addSORTemplate={
        tab === "addsor" || tab === "editsor"
      }
      componentToRender={[
        {
          condition: tab === "table",
          component: <ListOfTemplate />,
          // component: <StudentGrades courseName={courseName} stdName={stdName}/>
        },
        {
          condition: tab === "sorTable",
          component: <ListOfSOR />,
        },
        {
          condition: tab === "addsor",
          component: <SORForm templateData={templateData} setUpdateData={setUpdateData} loading={loading} />,
        },
        {
          condition: tab === "editsor",
          component: <SORForm templateData={templateData} setUpdateData={setUpdateData} updateData={updateData} loading={loading} />,
        },
        {
          condition: tab === "create",
          component: <AddTemplate />,
        },
        {
          condition: tab === "edit",
          component: <AddTemplate />,
        },
        {
          condition: tab === "auditTrails",
          component: <AuditTrail />,
        },
        {
          condition: tab === "editAuditTrails",
          component: <AuditTrail />,
        },
        {
          condition: tab === "editSorAuditTrails",
          component: <AuditTrail />,
        },
      ]}
      tabs={
        tab === "table" ||
        tab === "auditTrails" ||
        tab === "sorTable" 
          ? [
              {
                tabName: "Grading Letter Templates",
                tabIcon: "fa-file-certificate",
                url: `/courseAdministration/gradingTemplate/table`,
                condition: tab === "table",
              },
              {
                tabName: "Statement of Results Templates",
                tabIcon: "fa-file-signature",
                url: `/courseAdministration/gradingTemplate/sorTable`,
                condition: tab === "sorTable" || tab === "addsor",
              },
              {
                globalIconName: "audittrail",
                url: `/courseAdministration/gradingTemplate/auditTrails`,
                condition: tab === "auditTrails",
              },
            ]
          : tab === "edit" || tab === "editAuditTrails"
          ? [
              {
                globalIconName: "details",
                url: `/courseAdministration/gradingTemplate/edit/${id}`,
                condition: tab === "edit",
              },
              {
                globalIconName: "audittrail",
                url: `/courseAdministration/gradingTemplate/editAuditTrails/${id}`,
                condition: tab === "editAuditTrails",
              },
            ]
          : tab === "editsor" || tab === "editSorAuditTrails"
          ? [
            {
              globalIconName: "details",
              url: `/courseAdministration/gradingTemplate/editsor/${id}`,
              condition: tab === "editsor",
            },
            {
              globalIconName: "audittrail",
              url: `/courseAdministration/gradingTemplate/editSorAuditTrails/${id}`,
              condition: tab === "editSorAuditTrails",
            },
          ]
          : []
      }
    />
  );
};

export default TemplateTable;
