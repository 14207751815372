import React from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { GlobalIconNames } from "../../utils/GlobalIconNames";
import Header from "./Header";
import SideBar from "./SideBar";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { RenderQuestionType, RenderQuestionTypeForm } from "../../utils/CommonGroupingItem";

function PageComponent({
  heading,
  componentToRender = [],
  tabs = [],
  isBackButtonShown,
  isBackButtonStandard,
  disableColonShown,
  addTemplate,
  studentGrades,
  extraHeading,
  addSORTemplate,
  isAuditTrailButtonShown = true,
  auditTrailUrl
}) {
  const history = useHistory();
  const {pathname} = useLocation();
  let currentTabValue = pathname.split("/");

  return (
    <>
      <Header />
      <div className="content-wrapper d-flex flex-wrap custom-component">
        <SideBar />
        <div className={"sidebar-right " + (studentGrades ? "bottom-100" : "")}>
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        {addTemplate ? (
                          <>
                            {heading[0]} {heading[1]} {heading[2] ? `${heading[2]} :` : ""} <span>{heading[3]}</span>
                          </> 
                        ) : addSORTemplate ? (
                          <>
                            {heading[0]} {heading[1] ? `${heading[1]} :` : ""} <span>{heading[2]}</span>
                          </> 
                        ) : studentGrades && !addTemplate ? (
                          <>
                          {heading[0]} <span>{heading[1]}</span> - {heading[2]}
                          </>
                        ) : disableColonShown && !addTemplate ? (
                          heading.length === 2 ? <>
                            {heading[0]} <span>{heading[1]}</span>
                          </> 
                          : <>
                          {heading[0]} {heading[1] + " :"} <span>{heading[2]}</span>
                        </> 
                        ) : disableColonShown && !addSORTemplate ? (
                          heading.length === 2 ? <>
                            {heading[0]} <span>{heading[1]}</span>
                          </> 
                          : <>
                          {heading[0]} {heading[1]} <span>{heading[2]}</span>
                        </> 
                        ) : heading && heading.length ? (
                          <>
                            {heading[0]}
                            {heading[1] ? ":" : ""} <span>{heading[1]}</span>
                          </>
                        ) : (
                          ""
                        )}
                      </h4>
                    </div>
                    {isBackButtonStandard ? (
                      <div class="text-md-right action2-blk">
                        <div class="ticket-view-btn d-flex align-items-center">
                          <div class="add-new-ticket-bx">
                            <button title="Back" class="btn btn-white-bordered" onClick={() => history.goBack()}>
                              <i class="fal fa-angle-left"></i>Back
                            </button>
                          </div>
                        </div>
                      </div>
                    ) : 
                  
                    isBackButtonShown && (
                      <div className="text-md-right action2-blk">
                        <div className="ticket-view-btn d-flex align-items-center">
                          <div className="add-new-ticket-bx">
                            <button
                              onClick={() => history.goBack()}
                              title="Back"
                              className="btn btn-primary rounded-circle"
                            >
                              <i className="fal fa-angle-left"></i>
                            </button>

                            {isAuditTrailButtonShown ? <button
                              onClick={() => history.push(auditTrailUrl ?? "/")}
                              title="Audit Trail"
                              className="btn btn-primary rounded-circle"
                            >
                              <i className="fal fa-history"></i>
                            </button> : ""}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                    {(extraHeading && extraHeading!="" && currentTabValue.includes("addquestion")) ?
                    <>
                    {RenderQuestionTypeForm(extraHeading).html}
                    </>
                    :null}
                </div>
                <div className="tabs-wrap">
                  {tabs.length ? (
                    <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                      {tabs.map((item, index) => {
                        return (
                          <li
                            className="nav-item"
                            onClick={() => history.push(item.url)}
                            key={index}
                          >
                            <Link
                              className={`nav-link ${
                                item.condition ? "active" : ""
                              }`}
                              id={`pills-tab${index}`}
                              data-toggle="pill"
                              to={item.url}
                              href={`#pills-tab${index + tabs.length}`}
                              role="tab"
                              aria-controls={`#pills-tab${index + tabs.length}`}
                              aria-selected="true"
                            >
                              {item.globalIconName
                                ? GlobalIconNames(item.globalIconName).html
                                : GlobalIconNames(
                                    item.tabName,
                                    item.tabIcon,
                                    item.tabName
                                  ).html}
                            </Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : (
                    ""
                  )}
                  {componentToRender.map((item, index) => {
                    return (
                      <div
                        className="tab-content"
                        id="pills-tabContent"
                        key={index}
                      >
                        <div
                          className="tab-pane fade active show"
                          id={`pills-tab${index + tabs.length}`}
                          role="tabpanel"
                          aria-labelledby={`pills-tab${index}`}
                        >
                          {item.condition ? item.component : ""}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PageComponent;
