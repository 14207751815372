import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams} from "react-router";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import PermissionsGate from "../../../utils/permissionGate";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import {GetDisplayList} from "../../../services/SmartSignageService";
import DisplayCard from "./components/DisplayCard";
const LayoutUsers = () => {
    const [loading, setLoading] = useState(true);
    const [displayList, setDisplayList] = useState([]);
    
    useEffect(() => {
        setLoading(true);
        GetDisplayList().then((res) => {
            setLoading(false);
            setDisplayList(res?.data?.data);
        });
    }, [])   

    return (
        <div className="my-tickets-info-list Tickets-main-wrap">
        <div className="content-wrapper d-flex flex-wrap">               
        {loading ? (
            <SkeletonTicketList />
        ) : (
            <>
            <div className="display-grid">
            {// create display card for each display
                displayList.map((display) => {
                    return <DisplayCard  
                    key = {`display-${display.id}`}
                    videoUrl={display.background_video ? display.background_video.replace("/home/myaie/public_html/", "https://myaie.ac/") : null} 
                    campusName={display.campus_name} 
                    scrollingText={display.scrolling_text} 
                    status={display.connected} 
                    name={display.name} 
                    location={display.room} 
                    displayGroupName={display.display_group_name} 
                    displayGroupId={display.display_group_id}
                    textSettings={
                        {
                            'font_size': display.font_size,
                            'font_color': display.font_color,
                            'banner_color': display.banner_color,
                            'scroll_speed': display.scroll_speed,
                            'text_position': display.text_position,
                            'banner_opacity': display.banner_opacity
                        }
                    }   />;
                })
            }
            </div>
            </>
        )
    }
    </div>
    </div>
)

}

export default LayoutUsers;