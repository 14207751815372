import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams} from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {GetDisplayGroup, AddUpdateDisplayGroup, GetDisplayList} from "../../../services/SmartSignageService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import { FileUploadField } from "../../common/FormFields";
import HtmlParser from "react-html-parser";
import Select from "react-select";
import DisplayCardPreview from "./components/DisplayCardPreview";

const CreateDisplayGroup = () => {
    
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const { id, type } = useParams();
    const [displayGroup, setDisplayGroup] = useState({});
    const [layoutOptions, setLayoutOptions] = useState([]);
    const [displayPreview, setDisplayPreview] = useState({});
    const [updateDisplay, setUpdateDisplay] = useState(false);
    useEffect(() => {
        setLoadingData(true);
        GetDisplayGroup({id: id}).then((res) => {                
            if(res.status === 200) {
                if(res?.data?.data){
                    setDisplayGroup(res.data.data);
                    GetDisplayList('undefined', id).then((res) => {
                        if(res.status === 200) {
                            if(res?.data?.data){
                                setDisplayPreview(res.data.data); 
                            }
                        }
                    })                        
                }
                if(res?.data?.layoutOptions){
                    setLayoutOptions(res.data.layoutOptions);
                }
            }
            setLoadingData(false);
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Error Fetching Details",
                text: error,
            })
            console.log(error);
        });
        
    }, [id])   
    useEffect(() => {
        if(updateDisplay == true){
            GetDisplayList('undefined', id).then((res) => {
                if(res.status === 200) {
                    if(res?.data?.data){
                        setDisplayPreview(res.data.data); 
                    }
                }  
                setUpdateDisplay(false);                    
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error Fetching Preview",
                    text: error,
                })
                console.log(error);
                setUpdateDisplay(false);
            });
        }
    }, [updateDisplay])  
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: displayGroup?.name || "",
            linked_layout_id: displayGroup?.linked_layout_id || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
        }),
        onSubmit: (values, action) => {
            const formData = new FormData();
            if(id) {
                formData.append("id", id);
            }
            setLoading(true);
            formData.append("linked_layout_id", values.linked_layout_id);
            formData.append("name", values.name);
            
            AddUpdateDisplayGroup(formData).then((res) => {
                if(res.status === 200) {
                    let successText = id ? "Layout Updated successfully" : "Layout created successfully";
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: successText,
                        confirmButtonText: "OK",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if(!id) {
                                if(res?.data?.id)
                                    {
                                    history.push(`/campusAdministration/smartsignage/displaygroups/edit/${res?.data?.id}`);
                                }else{
                                    history.push(`/campusAdministration/smartsignage/displaygroups/list`);
                                }
                            }
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: error,
                        })
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error,
                })
            });
            action.setSubmitting(false);
            setUpdateDisplay(true);
        }
    });
    
    return (
        <div className={`col-md-12 ${type == 'edit' ? 'display-user-container' : ""}`}>
        <div className={`card card-profile-info-card filter-table-bg ${type == 'edit' ? 'col-lg-8' : ""}`}>
        <div className="row">
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header" title="Programme Page Details">{type === "add" ? <p>Create Display Group</p>
            : <p>Modify Display Group{loadingData ? (<><i className="fa fa-cog fa-spin"></i></>) : (<>: {displayGroup?.name} </>)}</p>} </h4>
            </div>
            </div>            
            <div className="col-md-4 col-lg-6">
            <label htmlFor="name">Name *</label>
            <div className="form-icon-group mb-4" title="name">
            <input
            type="text"
            className={`form-control ${formik.touched.name && formik.errors.name && "is-invalid"
            }`}
            // name="name"
            id="name"
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
            <div className="col-md-4 col-lg-6">
            <label htmlFor="Linked Layout" >Linked Layout <i className="fal fa-info-circle"></i></label>
            <div className="form-icon-group mb-4">
            <Select
            className={`form-control custom-select-box ${formik.touched.linked_layout_id && formik.errors.linked_layout_id && "is-invalid"
            }`}
            value={layoutOptions.filter(
                (item) => item.value == formik.values.linked_layout_id
            )
        }
        id="linked_layout_id"
        name="linked_layout_id"
        onChange={(option) => formik.setFieldValue("linked_layout_id", option.value)}
        onBlur={formik.handleBlur}
        options={layoutOptions}
        maxMenuHeight={175}
        placeholder="Select Linked Layout"
        />
        </div>
        </div>
        </div>
        <div className="form-group form-group-save-cancel mt-4">
        <PermissionsGate scopes={[type == "add" ? 'casmartsignuseradd' : 'casmartsignuseredit']} errorProps={{ disabled: true }}>
        <button
        className="btn btn-save btn-success"
        type="submit"
        title="Save"
        disabled={loading}
        onClick={formik.handleSubmit}
        >
        {loading ? (
            <i className="fas fa-cog fa-spin"></i>
        ) : (
            <i className="fal fa-save"></i>
        )}
        Save
        </button>
        </PermissionsGate>    
        <button
        className="btn btn-close btn-danger"
        type="button"
        title="Cancel"
        onClick={() => {formik.resetForm()}}
        >
        <i className="fal fa-times"></i>Cancel
        </button>
        </div>
        </div>
        {(type == 'edit' && displayPreview) && 
            (
                <>
                <DisplayCardPreview
                videoUrl={displayPreview.background_video ? displayPreview.background_video.replace("/home/myaie/public_html/", "https://myaie.ac/") : null} 
                scrollingText={displayPreview.scrolling_text}
                textSettings={
                    {
                        'font_size': displayPreview.font_size,
                        'font_color': displayPreview.font_color,
                        'banner_color': displayPreview.banner_color,
                        'scroll_speed': displayPreview.scroll_speed,
                        'text_position': displayPreview.text_position,
                        'banner_opacity': displayPreview.banner_opacity
                    }
                }             
                />
                </>
            )
        }
        </div>
        
    )
    
}

export default CreateDisplayGroup;