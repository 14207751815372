import React, { useEffect, useRef } from "react";

const DisplayCardPreview = ({
  videoUrl,
  scrollingText,
  textSettings
}) => {
  const marqueeRef = useRef();

  const parseRichText = (text) => {
    // Replace *text* with <b>text</b>
    let formattedText = text.replace(/\*(.*?)\*/g, '<b>$1</b>');
  
    // Replace _text_ with <u>text</u>
    formattedText = formattedText.replace(/\_(.*?)\_/g, '<u>$1</u>');
  
    return formattedText;
  };

  useEffect(() => {
    if (marqueeRef.current && scrollingText) {
      const formattedText = parseRichText(scrollingText);
      // Duplicate the text to ensure smooth scrolling
      marqueeRef.current.innerHTML = formattedText + " " + formattedText 


      // Animate the scrolling text
      const keyframes = [
        { transform: "translateX(0%)" },
        { transform: "translateX(-200%)" },
      ];

      marqueeRef.current.animate(keyframes, {
        duration: 10000 / (textSettings.scroll_speed || 1), // Adjust speed as needed
        iterations: Infinity,
      });
    }
  }, [scrollingText, textSettings]);
  const bannerHeight = parseInt(textSettings.font_size) * 1.5;
  // Styles for marquee and banner
  const marqueeStyle = {
    fontSize: textSettings.font_size,
    color: textSettings.font_color,
    // animation: `scrollingTextAnimation ${textSettings.scroll_speed || 1}s linear infinite`,
    opacity: 1,
    top: textSettings.text_position === 0 ? "0" : "auto",  // Top position if text_position is 0
    bottom: textSettings.text_position === 1 ? "0" : "auto", // Bottom position if text_position is 1
  };
  const bannerStyle = {
    backgroundColor: textSettings.banner_color,
    opacity: textSettings.banner_opacity,
    height: `${bannerHeight}px`, // Apply dynamic height based on font size
    top: textSettings.text_position === 0 ? "0" : "auto",  // Top position if text_position is 0
    bottom: textSettings.text_position === 1 ? "0" : "auto", // Bottom position if text_position is 1
  };
  
  return (
    <div className="display-card">
      <div className="preview-title">Preview:</div>
      <div className="video-container">
        {videoUrl ? (
          <video
            src={videoUrl}
            autoPlay
            muted
            loop
            className="video-element"
          />
        ) : (
          <div className="no-video">No video added</div>
        )}
        {scrollingText && (<>
          <div className="scrolling-text-bg" style={bannerStyle}>        
        </div>
        <div ref={marqueeRef} className="scrolling-text" style={marqueeStyle}>
        {scrollingText}
      </div>
      </>
        )}
      </div>  
    </div>
  );
};

export default DisplayCardPreview;
