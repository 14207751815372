import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams} from "react-router";
import * as Yup from "yup";
import { useFormik } from "formik";
import "jspdf-autotable";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import {GetDisplayUser, AddUpdateDisplayUser, GetDisplayList} from "../../../services/SmartSignageService";
import SkeletonTicketList from "../../../loaders/SkeletonTicketList";
import PermissionsGate from "../../../utils/permissionGate";
import { FileUploadField } from "../../common/FormFields";
import HtmlParser from "react-html-parser";
import Select from "react-select";
import DisplayCardUser from "./components/DisplayCardUser";

const CreateDisplayUser = () => {
    
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const { id, type } = useParams();
    const [display, setDisplay] = useState({});
    const [groupOptions, setLayoutOptions] = useState([]);
    const [campusOptions, setCampusOptions] = useState([]);
    const [displayPreview, setDisplayPreview] = useState({});
    const [updateDisplay, setUpdateDisplay] = useState(false);
    useEffect(() => {
        setLoadingData(true);
        GetDisplayUser({id: id}).then((res) => {                
            if(res.status === 200) {
                if(res?.data?.data){
                    setDisplay(res.data.data); 
                    GetDisplayList(id).then((res) => {
                        if(res.status === 200) {
                            if(res?.data?.data){
                                setDisplayPreview(res.data.data); 
                            }
                        }
                    })                       
                }
                if(res?.data?.groupOptions){
                    setLayoutOptions(res.data.groupOptions);
                }
                if(res?.data?.campusesOptions){
                    setCampusOptions(res.data.campusesOptions);
                }
            }
            setLoadingData(false);
        }).catch((error) => {
            Swal.fire({
                icon: "error",
                title: "Error Fetching Details",
                text: error,
            })
            console.log(error);
        });
        
    }, [id])   
    
    useEffect(() => {
        if(updateDisplay == true){
            GetDisplayList(id).then((res) => {
                if(res.status === 200) {
                    if(res?.data?.data){
                        setDisplayPreview(res.data.data); 
                    }
                }  
                setUpdateDisplay(false);                    
            }).catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Error Fetching Preview",
                    text: error,
                })
                console.log(error);
                setUpdateDisplay(false);
            });
        }
    }, [updateDisplay])   
    
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: display?.name || "",
            campus: display?.campus || "",
            room: display?.room || "",
            // pairing_id: display?.pairing_id || "",
            display_group_id: display?.display_group_id || "",
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Name is required"),
        }),
        onSubmit: (values, action) => {
            const formData = new FormData();
            if(id) {
                formData.append("id", id);
            }
            setLoading(true);
            
            formData.append("name", values.name);
            formData.append("campus", values.campus);
            formData.append("room", values.room);
            // formData.append("pairing_id", values.pairing_id);
            formData.append("display_group_id", values.display_group_id);
            
            AddUpdateDisplayUser(formData).then((res) => {
                if(res.status === 200) {
                    let successText = id ? "Layout Updated successfully" : "Layout created successfully";
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: successText,
                        confirmButtonText: "OK",
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            if(!id) {
                                if(res?.data?.id)
                                    {
                                    history.push(`/campusAdministration/smartsignage/displayusers/edit/${res?.data?.id}`);
                                }else{
                                    history.push(`/campusAdministration/smartsignage/displayusers/list`);
                                }
                            }else{
                                //refresh page
                                setUpdateDisplay(true);
                            }
                        }
                        setLoading(false);
                    })
                    .catch((error) => {
                        console.log(error);
                        setLoading(false);
                        Swal.fire({
                            icon: "error",
                            title: "Error",
                            text: error,
                        })
                    });
                }
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error,
                })
            });
            action.setSubmitting(false);
        }
    });
    
    return (
        <div className={`col-md-12 ${type == 'edit' ? 'display-user-container' : ""}`}>
        <div style={{width: 'inherit'}} className={`card card-profile-info-card filter-table-bg with-margin ${type == 'edit' ? 'col-lg-8' : ""}"`}>
        <div className="row">
        <div className="col-md-12">
        <div className="edit-icon new-card-header">
        <h4 className="card-header" title="Programme Page Details">{type === "add" ? <p>Create Display</p>
            : <p>{loadingData ? (<><i className="fa fa-cog fa-spin"></i></>) : (<>{display?.name} - {display?.connected == 1 ? (<><span className="as-green-bg-smooth">ONLINE</span></>) : (<><span className="as-red-bg-smooth">OFFLINE</span></>)} </>)}</p>} </h4>
            </div>
            </div>            
            <div className="col-md-6 col-lg-3">
            <label htmlFor="name">Name *</label>
            <div className="form-icon-group mb-4" title="name">
            <input
            type="text"
            className={`form-control ${formik.touched.name && formik.errors.name && "is-invalid"
            }`}
            // name="name"
            id="name"
            placeholder="Name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            />
            </div>
            </div>
            <div className="col-md-6 col-lg-3">
            <label htmlFor="Campus" >Campus <i className="fal fa-info-circle"></i></label>
            <div className="form-icon-group mb-4">
            <Select
            className={`form-control custom-select-box ${formik.touched.campus && formik.errors.campus && "is-invalid"
            }`}
            value={campusOptions.filter(
                (item) => item.value == formik.values.campus
            )
        }
        id="campus"
        name="campus"
        onChange={(option) => formik.setFieldValue("campus", option.value)}
        onBlur={formik.handleBlur}
        options={campusOptions}
        maxMenuHeight={175}
        placeholder="Select Campus"
        />
        </div>
        </div>
        <div className="col-md-6 col-lg-3">
        <label htmlFor="room">Location</label>
        <div className="form-icon-group mb-4" title="room">
        <input
        type="text"
        className={`form-control ${formik.touched.room && formik.errors.room && "is-invalid"
        }`}
        // room="room"
        id="room"
        placeholder="Location"
        value={formik.values.room}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        />
        </div>
        </div>
        <div className="col-md-6 col-lg-3">
        <label htmlFor="Linked Display Group" >Linked Display Group <i className="fal fa-info-circle"></i></label>
        <div className="form-icon-group mb-4">
        <Select
        className={`form-control custom-select-box ${formik.touched.display_group_id && formik.errors.display_group_id && "is-invalid"
        }`}
        value={groupOptions.filter(
            (item) => item.value == formik.values.display_group_id
        )
    }
    id="display_group_id"
    name="display_group_id"
    onChange={(option) => formik.setFieldValue("display_group_id", option.value)}
    onBlur={formik.handleBlur}
    options={groupOptions}
    maxMenuHeight={175}
    placeholder="Select Linked Display Group"
    />
    </div>
    </div>
    </div>
    <div className="form-group form-group-save-cancel mt-4">
    <PermissionsGate scopes={[type == "add" ? 'casmartsignuseradd' : 'casmartsignuseredit']} errorProps={{ disabled: true }}>
    <button
    className="btn btn-save btn-success"
    type="submit"
    title="Save"
    disabled={loading}
    onClick={formik.handleSubmit}
    >
    {loading ? (
        <i className="fas fa-cog fa-spin"></i>
    ) : (
        <i className="fal fa-save"></i>
    )}
    Save
    </button>
    </PermissionsGate>    
    <button
    className="btn btn-close btn-danger"
    type="button"
    title="Cancel"
    onClick={() => {formik.resetForm()}}
    >
    <i className="fal fa-times"></i>Cancel
    </button>
    </div>
    </div>
    {(type == 'edit' && displayPreview) && 
        (
            <>
            <DisplayCardUser
            key = {`display-${displayPreview.id}`}
            videoUrl={displayPreview.background_video ? displayPreview.background_video.replace("/home/myaie/public_html/", "https://myaie.ac/") : null} 
            scrollingText={displayPreview.scrolling_text} 
            status={displayPreview.connected} 
            pairingId={displayPreview.pairing_id}
            textSettings={
                {
                    'font_size': displayPreview.font_size,
                    'font_color': displayPreview.font_color,
                    'banner_color': displayPreview.banner_color,
                    'scroll_speed': displayPreview.scroll_speed,
                    'text_position': displayPreview.text_position,
                    'banner_opacity': displayPreview.banner_opacity
                }
            }  
            />
            </>
        )
    }
    </div>
    
)

}

export default CreateDisplayUser;