import React, { useEffect, useRef } from "react";
import Select from "react-select";
import { IMAGE_URL, WS_URL, downloadURL } from "../../utils/Constants";
import { TrimText } from "../common/TrimText";
import { Link } from "react-router-dom";

export const FormField = ({
  field,
  formik,
  selectOptions,
  required = true,
  col_md = 6,
  mbTrue=true,
  col_lg = 3,
  placeholder,
  isClearable=true,
  inputTitle,
  callback
}) => {
  const {
    name,
    label,
    max,
    min,
    title,
    step,
    value,
    isMulti = false,
    type = "text",
    info = false,
    sign = false,
    isInvalid = formik.touched[name] && formik.errors[name],
  } = field;
  // const isInvalid = formik.touched[name] && formik.errors[name];
  const colorRef = useRef();
  return (
    <div className={`col-md-${col_md} col-lg-${col_lg}`} title={inputTitle ? inputTitle : label}>
      {required ? (
        <label htmlFor={name}>
          {label} * {info ? <i className="fal fa-info-circle"></i> : null}
        </label>
      ) : (
        <label htmlFor={name}>
          {label} {info ? <i className="fal fa-info-circle"></i> : null}
        </label>
      )}
      <div
        className={`form-icon-group mb-4 ${
          selectOptions ? "position-relative" : ""
        }`}
        title={label}
      >
        {selectOptions ? (
          <Select
            className={`form-control custom-select-box ${
              isInvalid && "is-invalid"
            }`}
            name={name}
            value={
              value
                ? selectOptions.find((val) => val.value == value) || "" 
                : selectOptions.find((val) => val.value == formik.values[name]) || ""
            }
            onChange={(selectedOption) => {
              
              formik.setFieldValue(name, selectedOption ? selectedOption.value : "")
              if(callback){
                callback()
              }
            }
            }
            isClearable={isClearable}
            onBlur={formik.handleBlur}
            options={selectOptions}
            maxMenuHeight={175}
            placeholder="Select"
            isMulti={isMulti}
          />
        ) : (
          <>
            {sign ? (
              <span
                style={{
                  position: "absolute",
                  marginLeft: "5px",
                  marginTop: "13px",
                  fontWeight: "bold",
                }}
              >
                R
              </span>
            ) : null}
            {type == 'number' ?
            <input
              type={type}
              className={`form-control ${isInvalid && "is-invalid"}`}
              name={name}
              // maxLength={type == "color" ? "7" : ""}
              id={name}
              step={step || 1}
              title={label}
              placeholder={placeholder}
              value={value ? value : formik.values[name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              min={min || -Infinity}
              max={max || Infinity}
            /> 
            :
              <input
              type={type == "color" ? "text" : type}
              className={`form-control ${isInvalid && "is-invalid"}`}
              name={name}
              // maxLength={type == "color" ? "7" : ""}
              id={name}
              title={label}
              placeholder={placeholder}
              value={value ? value : formik.values[name]}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            }
            
            {type == "color" ? (
              <>
                <input
                  className={`color-selector-brand ${isInvalid && "is-invalid"}`}
                  type="color"
                  ref={colorRef}
                  name={name}
                  id={name}
                  maxLength={6}
                  style={{ width: "10px" }}
                  value={formik.values[name]}
                  onChange={(e) => {
                    formik.setFieldValue(name, e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                />
              </>
            ) : null}
          </>
        )}
        {field.color && (
          <div
            className="position-absolute rounded"
            style={{
              backgroundColor: formik.values[name],
              height: "32px",
              width: "32px",
              top: "5px",
              right: "15px",
              cursor:"pointer"
            }}
            onClick={() => colorRef.current.click()}
          ></div>
        )}
      </div>
    </div>
  );
};

export const FileUploadField = ({
  name,
  label,
  formik,
  title,
  accept,
  info = true,
  required = false,
  col_md = 6,
  mbTrue=true,
  col_lg = 3,
}) => {
  const isInvalid = formik.touched[name] && formik.errors[name];
  const fileValue = formik.values[name];

  const isFileObject = fileValue instanceof File;
  const fileURL = isFileObject ? URL.createObjectURL(fileValue) : fileValue;

  return (
    <div className={`col-md-${col_md} col-lg-${col_lg} mb-1`} title={label}>
      <div className={`form-group-blk ${mbTrue && "mb-3"}`}> 
        {required ? (
          <label htmlFor={name} >
            {label} * {info ? <i title={title} className="fal fa-info-circle"></i> : null}
          </label>
        ) : (
          <label htmlFor={name}>
            {label} {info ? <i title={title} className="fal fa-info-circle"></i> : null}
          </label>
        )}
        <div className="form-group atttach-file m-0" title={title}>
          <label className={`${isInvalid && "file-req is-invalid"}`}>
            <i className="fal fa-upload"></i>
            <span>Upload</span>

            <input
              type="file"
              className="form-control form-control-aatch-file"
              onChange={(e) => {
                const file = e.currentTarget.files[0];
                if (file) {
                  formik.setFieldValue(name, file);
                }
                e.target.value=""
              }}
              // title={title}
              name={name}
              id={name}
              accept={accept}
            />
          </label>
        </div>
        {fileValue && (
          <>
            <div className="frm-group">
              <ul className="list-unstyled attached-file-ul m-0">
                <li
                  title={
                    fileValue.name
                      ? fileValue.name
                      : fileURL?.split("/")?.reverse()[0]
                  }
                >
                  <a
                    href={
                      fileURL.includes("blob")
                        ? fileURL
                        : `${IMAGE_URL}/${fileURL}`
                            .replaceAll("public/", "")
                            .replaceAll("home/myaie/public_html/", "")
                    }
                    target="_blank"
                  >
                    <i className="fal fa-file"></i>
                    &nbsp;
                    {fileValue.name
                      ? TrimText(fileValue.name, 40)
                      : TrimText(fileURL.split("/").reverse()[0], 40)}
                  </a>
                  <button
                    type="button"
                    className="btn btn-danger rounded-circle btn-dropdown-item"
                    title="Delete"
                    onClick={() => formik.setFieldValue(name, "")}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </li>
              </ul>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
export const AssignPageField = ({
  name,
  label,
  formik,
  dataTarget,
  assignedPage,
  setAssignedPage,
  info = true,
  required = false,
  col_md = 6,
  col_lg = 3,
  buttonName = "Assign Page"
}) => {
  const isInvalid = formik.touched[name] && formik.errors[name];

  if (!assignedPage) {
    assignedPage = {};
  }

  useEffect(() => {
    console.log("::::::::::::::::::::::::::",assignedPage);
  },[assignedPage])

  return (
    <div className={`col-md-${col_md} col-lg-${col_lg}`}>
      <div className="d-flex align-items-start flex-column" title={label}>
        {required ? (
          <label htmlFor={name}>
            {label} *{" "}
            {info ? <i className="fal fa-info-circle grade-icon"></i> : null}
          </label>
        ) : (
          <label htmlFor={name}>
            {label}{" "}
            {info ? <i className="fal fa-info-circle grade-icon"></i> : null}
          </label>
        )}
        <button
          className={
            "btn btn-save btn-success" +
            (isInvalid ? " file-req is-invalid" : "")
          }
          type="button"
          title="Assign Page"
          data-toggle="modal"
          data-target={dataTarget}
        >
          <i className="fal fa-plus"></i> {buttonName}
        </button>
        {formik.values[name] &&
        Object.keys(assignedPage).length &&
        assignedPage?.PageTitle || assignedPage?.title ||  assignedPage?.name ? (
          <>
            <div className="frm-group">
              <ul className="list-unstyled attached-file-ul m-0">
                <li>
                  <Link
                    className="textLimit100 as-text-blue pr-3"
                    title={assignedPage?.PageTitle ? assignedPage?.PageTitle : assignedPage?.title}
                    // to={assignedPage?.flag ? `/website_management/pages/staticpages/table/update/${assignedPage.PageID}` : `/program-management-center/programmes/programme/open/${assignedPage.Id}`}
                    to={`${assignedPage.flag == 4 ? `/website_management/pages/categorylandingpages/table/open/${assignedPage.PageID}` : assignedPage.flag == 0 ? `/website_management/pages/staticpages/table/update/${assignedPage.PageID}` : assignedPage.flag == 2 ? `/website_management/pages/courselandingpages/table/open/${assignedPage.PageID}` : assignedPage.flag == 3 ? `/website_management/pages/campuspages/table/update/${assignedPage.PageID}` : assignedPage.flag == 1 ? `/websiteManagement/events/landing_page/page_details/update/${assignedPage.PageID}` :
                    assignedPage.flag == 5 ? `/websiteManagement/pages/programmes_and_short_courses/programme_pages/details/open/${assignedPage.id}` : assignedPage.flag == 6 ? `/websiteManagement/pages/programmes_and_short_courses/short_course_page/details/open/${assignedPage.id}` : `/website_management/pages/staticpages/table/update/${assignedPage.PageID}`}`}
                  >
                      {TrimText(
                        assignedPage?.PageTitle
                          ? assignedPage.PageTitle
                          : assignedPage?.name
                            ? assignedPage.name
                            : assignedPage?.title,
                        35
                      )}                 
                    </Link>
                  <button
                    className="btn btn-danger rounded-circle btn-dropdown-item ml-3"
                    title="Delete"
                    onClick={() => {
                      formik.setFieldValue(name, "");
                      setAssignedPage({});
                    }}
                  >
                    <i className="fal fa-trash-alt"></i>
                  </button>
                </li>
              </ul>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};
