import React, { useEffect, useState } from "react";
import {
  AllBrandList,
  AllBrandListCancelToken,
  checkCheckboxRejectDetails,
  GetNetsuiteCustomerDetails,
  GetRegisteredStudentDetails,
  GetRegisteredStudentDetailsCancelToken,
  GetSalesPersonsNamesCancelToken,
  updateSectionToSave,
} from "../../../services/RegistrationService";
import Accounts from "./RegistrationBlocks/Accounts";
import Address from "./RegistrationBlocks/Address";
import ApplicationStatus from "./RegistrationBlocks/ApplicationStatus";
import Checks from "./RegistrationBlocks/Checks";
import Documents from "./RegistrationBlocks/Documents";
import ERPList from "./RegistrationBlocks/ERPList";
import ExamRegList from "./RegistrationBlocks/ExamRegList";
import QuoteAndPayment from "./RegistrationBlocks/QuoteAndPayment";
import RCO from "./RegistrationBlocks/RCO";
import RegistrationDetails from "./RegistrationBlocks/RegistrationDetails";
import Reject from "./RegistrationBlocks/Reject";
import SalesNotes from "./RegistrationBlocks/SalesNotes";
import StudentDetails from "./RegistrationBlocks/StudentDetails";
import StudentNumber from "./RegistrationBlocks/StudentNumber";
import StudyKits from "./RegistrationBlocks/StudyKits";
import SubjectCheck from "./RegistrationBlocks/subjectChecks";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { EMAIL_REGEX, MOBILE_REGEX, NAME_REGEX } from "../../../utils/Constants";
import { GetSalesPersonsNames } from "../../../services/RegistrationService";
import SubmitButton from "./RegistrationBlocks/SubmitButton";
import Swal from "sweetalert2";
import ERPData from "./RegistrationBlocks/ERPData";
import $ from "jquery"
import moment from "moment";
import StudykitLaptopOrdered from "./RegistrationBlocks/StudykitLaptopOrdered";
import axios from "axios";

function OpenRegistrationTab() {

  const search = window.location.search;
  const params = new URLSearchParams(search);
  const studentId = params.get("studentId");
  const [studentData, setStudentData] = useState({});
  const [status, setStatus] = useState("");
  const [reRegData, setReRegData] = useState({});
  const [dropdownValues, setDropdownValues] = useState(
    useSelector((state) => state.registerDropdownValues)
  );
  const [salesPerson, setSalesPerson] = useState([]);
  const [kitTypeVar, setKitType] = useState("");
  const [refreshComponent, setRefreshComponent] = useState();
  const [disabled, setDisabled] = useState(true);
  const [loaderPos, setLoaderPos] = useState("");

  const [btnDissable, setBtnDissable] = useState("");
  const [selectedBtn, setSelectedBtn] = useState("");

  const [ERPTableData, setERPTableData] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [SaveAndAddToLivePortal, setSaveAndAddToLivePortal] = useState([]);
  const [isSubmitionAllowed, setIsSubmitionAllowed] = useState(true);
  const [isPopupAllowed, setIsPopupAllowed] = useState(false);
  const [isAcademicDepartment, setisAcademicDepartment] = useState(false);

  const [accountCredit, setAccountCredit] = useState({
    upToDate: false, inCredit: false
  })

  const [isValidating, setIsValidating] = useState({ isChecking: true, isSetToFinal: false, skippingField: [] });

  const [rejectEditorState, setRejectEditorState] = useState("");
  const [salesNotesEditorState, setSalesNotesEditorState] = useState("");
  const [subjectCheckIds, setSubjectCheckIds] = useState({})
  const [reWriteIds, setReWriteIds] = useState({});
  const [redoIds, setRedoIds] = useState({});
  const [reSubmissionIds, setReSubmissionIds] = useState({});
  const [registerFor, setRegisterFor] = useState({})
  const [repeat, setRepeat] = useState({});
  const [elective, setElective] = useState({});
  const [loadingData, setLoadingData] = useState(false)
  const [netSuiteDataLoading, setNetSuiteDataLoading] = useState(false)
  const [courseItemNonMatch, setCourseItemNonMatch] = useState(false)
  const [isGDC, setIsGDC] = useState(false);

  useEffect(() => {
    const cancelTokenSources = [];
    const getRegisterDetails = async () => {
      setDisabled(true);
      setLoadingData(true)
      setNetSuiteDataLoading(true)
      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });
  
      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);
      try {
        const response = await GetRegisteredStudentDetailsCancelToken(studentId, source.token)
        if (response?.status == 200) {
          setAccountCredit({
            upToDate: response.data?.re_reg_data?.account_up_to_date, inCredit: response.data?.re_reg_data?.account_in_credit
          })
          if(response?.data.re_reg_data?.subject_notes && response?.data.re_reg_data?.subject_notes != "null"){
            setRejectEditorState(response.data.re_reg_data.subject_notes)
          }else if(response?.data.re_reg_data?.reject_status_message){
            setRejectEditorState(response.data.re_reg_data.reject_status_message)
          }
          if(response?.data.re_reg_data?.sales_notes && response?.data.re_reg_data?.sales_notes != "null"){
            setSalesNotesEditorState(response.data.re_reg_data.sales_notes)
          }
          setSaveAndAddToLivePortal(response?.data?.re_reg_data?.SaveAndLiveClicked);
          setStudentData(response?.data);
          if(response?.data?.courseMatchInfo && response?.data?.courseMatchInfo?.length > 0){
            for(let i = 0; i < response?.data?.courseMatchInfo?.length; i++){
              if(response?.data?.courseMatchInfo[i]?.Status == "Not Matched" || response?.data?.courseMatchInfo[i]?.Status == "Excluded"){
                setCourseItemNonMatch(true);
              } 
            }
          }
          // Check if laptop only opp (Department Academic in Netsuite / 14)
          if (response && response.data && response.data.re_reg_data && response.data.re_reg_data.netsuite_department_id && response.data.re_reg_data.netsuite_department_id == 14) // Laptop Only
          {
            setisAcademicDepartment(true);
          }
          if(response && response.data && response.data.re_reg_data && response.data.re_reg_data.brand_reg == "13"){
            setIsGDC(true);
          }
          setLoadingData(false)
          setStatus(response?.data?.re_reg_data.status);
          setReRegData(response?.data?.re_reg_data)
          // Below Function Will Set Subject Details
          setSubjectCheck(response?.data??{})
          if (
            response?.data?.re_reg_data?.ekit != "" &&
            response?.data?.re_reg_data?.student_kit_sent != "no_kit_required"
          ) {
            setKitType("eKit only");
          } else if (
            response?.data?.re_reg_data?.tracking_no != "" &&
            response?.data?.re_reg_data?.student_kit_sent != "no_kit_required"
          ) {
            setKitType("Deliver to Student");
          } else if (
            response?.data?.re_reg_data?.kit_collected_date != "" &&
            response?.data?.re_reg_data?.student_kit_sent != "no_kit_required"
          ) {
            setKitType("Student to Collect");
          }
          
          if ((response?.data?.re_reg_data.CustomerID || response?.data?.re_reg_data?.NScontactID || response?.data?.re_reg_data?.bookingId) && response?.data?.re_reg_data.email) {
            GetNetsuiteCustomerDetails(
              response?.data?.re_reg_data?.CustomerID,
              response?.data?.re_reg_data?.email,
              response?.data?.re_reg_data?.NScontactID,
              response?.data?.re_reg_data?.bookingId
            )
              .then((response) => {
                setNetSuiteDataLoading(false)
                setERPTableData(response.data?.data)
              })
              .catch((error) => {
                setNetSuiteDataLoading(false)
                console.log("error :", error)
              });
          } else {
            setNetSuiteDataLoading(false)
          }
          setDisabled(false);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error("error", error)
          setLoadingData(false)
          setNetSuiteDataLoading(false)
        }
      }
    }

    getRegisterDetails()

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };
  }, [refreshComponent]);
  
  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const response = await AllBrandListCancelToken(source.token)
        if (response.status == 200) {
          let brands = [];
          let res = response.data.allBrandList || []
          res.map((data) => {
            brands.push({ value: data.id, label: data.brand_name })
          })
          setAllBrands(brands)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };

  }, [refreshComponent]);
  
  useEffect(() => {
    const cancelTokenSources = [];
    const getData = async () => {

      cancelTokenSources.forEach(source => {
        source.cancel('New request made');
      });

      const source = axios.CancelToken.source();
      cancelTokenSources.push(source);

      try {
        const response = await GetSalesPersonsNamesCancelToken(source.token)
        if (response && response.status == 200) {
          const salesPersonArr =
          response && response.data && response.data.lecturer_coach
            ? response.data.lecturer_coach
            : [];
        const arr = [];
        salesPersonArr.map((elem, ind) => {
          arr.push({ value: elem.UserID, label: elem.name });
        });
        setSalesPerson(arr);
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error);
        }
      }
    }

    getData();

    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted');
      });
    };

  }, [refreshComponent]);

  const setSubjectCheck = (res) => {
    let progressArr = res.sp_students_progress_id ? res.sp_students_progress_id : [];
    let redoArr = res.sp_students_re_do_id ? res.sp_students_re_do_id : [];
    let reWriteArr = res.sp_students_re_write_id ? res.sp_students_re_write_id : [];
    let reSubmissionArr = res.resubmission ? res.resubmission : [];
    let registerForArr = res.re_reg_data.register_for ? res.re_reg_data.register_for.split(",") : [];
    let repeatArr = res.re_reg_data.repeat_for ? res.re_reg_data.repeat_for.split(",") : [];
    let electiveArr = res.re_reg_data.elective ? res.re_reg_data.elective.split(",") : [];

    let finalProgressVal = {}
    let finalRedoVal = {}
    let finalReWriteVal = {}
    let finalReSubmissionVal = {}
    let finalRegisterFor = {}
    let finalRepeat = {}
    let finalElective = {}

    progressArr && progressArr.length && progressArr.map((value, index) => {
      let num = ++index
      finalProgressVal[`qualification${num}`] = value.qualification
      finalProgressVal[`subject${num}`] = value.intake
      finalProgressVal[`progress${num}`] = value.id
      finalProgressVal[`subjectName${num}`] = value.name
    })

    redoArr && redoArr.length && redoArr.map((value, index) => {
      let num = ++index
      finalRedoVal[`redo${num}`] = value.intake
      finalRedoVal[`redoId${num}`] = value.id
    })

    reWriteArr && reWriteArr.length && reWriteArr.map((value, index) => {
      let num = ++index
      finalReWriteVal[`reWrite${num}`] = value.intake
      finalReWriteVal[`reWriteId${num}`] = value.id
    })

    reSubmissionArr && reSubmissionArr.length && reSubmissionArr.map((value, index) => {
      let num = ++index
      finalReSubmissionVal[`reSubmission${num}`] = value.intake
      finalReSubmissionVal[`reSubmissionId${num}`] = value.id
    })

    registerForArr && registerForArr.length && registerForArr.map((value, index) => {
      let num = ++index
      finalRegisterFor[`registerFor${num}`] = value
    })

    repeatArr && repeatArr.length && repeatArr.map((value, index) => {
      let num = ++index
      finalRepeat[`repeat${num}`] = value
    })

    electiveArr && electiveArr.length && electiveArr.map((value, index) => {
      let num = ++index
      finalElective[`elective${num}`] = value
    })

    setSubjectCheckIds({ ...finalProgressVal, totalArr: progressArr })
    setRedoIds({ ...finalRedoVal, totalArr: redoArr })
    setReWriteIds({ ...finalReWriteVal, totalArr: reWriteArr })
    setReSubmissionIds({ ...finalReSubmissionVal, totalArr: reSubmissionArr })
    setRegisterFor({ ...finalRegisterFor })
    setRepeat({ ...finalRepeat })
    setElective({ ...finalElective })
  }

  function getValue(arrName, label) {
    let labelName = "";
    if (arrName === "sales_person") {
      for (let item of salesPerson) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }
      return labelName;
    } else if (label == 0) {
      return label;
    } else {
      for (let item of dropdownValues[arrName]) {
        if (item.value.toString() == label.toString()) {
          labelName = item.label;
          break;
        }
      }

      if (labelName === "" && arrName === "re_registration_status")
        return label;
      return labelName;
    }
  }

  const initialValues = {
    MatchedProgramme: studentData?.matched_program?.length ? true : false,
    SaveAndAddToLivePortal:
    SaveAndAddToLivePortal ? SaveAndAddToLivePortal : 0,
    ApplicationStatus:
      studentData && studentData.re_reg_data && studentData.re_reg_data.status
        ? studentData.re_reg_data.status
        : "",
    ApplicationStatusLabel:
      studentData && studentData.re_reg_data && studentData.re_reg_data.status
        ? getValue("re_registration_status", studentData.re_reg_data.status)
        : "",
    toBeReceived: studentData?.re_reg_data?.to_be_reviewed ? true : false,
    StudentNumber:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.student_crm_id
        ? studentData.re_reg_data.student_crm_id
        : "-",
    Title:
      studentData && studentData.re_reg_data && studentData.re_reg_data.title
        ? studentData.re_reg_data.title
        : "",
    Initials:
      studentData && studentData.re_reg_data && studentData.re_reg_data.initials
        ? studentData.re_reg_data.initials
        : "",
    FirstName:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.first_name
        ? studentData.re_reg_data.first_name
        : "",
    SecondName:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.second_name
        ? studentData.re_reg_data.second_name
        : "",
    ThirdName:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.third_name
        ? studentData.re_reg_data.third_name
        : "",
    LastName:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.last_name
        ? studentData.re_reg_data.last_name
        : "",
    MaidenName:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.maiden_name
        ? studentData.re_reg_data.maiden_name
        : "",
    RSAID:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.id_number
        ? studentData.re_reg_data.id_number
        : "",
    Email:
      studentData && studentData.re_reg_data && studentData.re_reg_data.email
        ? studentData.re_reg_data.email
        : "",
    Mobile:
      studentData && studentData.re_reg_data && studentData.re_reg_data.mobile
        ? studentData.re_reg_data.mobile
        : "",
    DisablityId:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.disability
        ? studentData.re_reg_data.disability
        : "",
    DisablityIdId:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.disability
        ? studentData.re_reg_data.disability
        : "",
    AllowedExtraTime:
      studentData &&
        studentData.re_reg_data && studentData.re_reg_data.extra_time.toString().length ?
        studentData.re_reg_data.extra_time == 1
          ? "Yes"
          : "No" : false,
    StreetAddress:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.ph_street
        ? studentData.re_reg_data.ph_street
        : "",
    City:
      studentData && studentData.re_reg_data && studentData.re_reg_data.ph_city
        ? studentData.re_reg_data.ph_city
        : "",
    Province:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.ph_province
        ? studentData.re_reg_data.ph_province
        : "",
    Country:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.ph_country
        ? studentData.re_reg_data.ph_country
        : "",
    PostalCode:
      studentData && studentData.re_reg_data && studentData.re_reg_data.ph_zip
        ? studentData.re_reg_data.ph_zip
        : "",
    PostalStreetAddress:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.ph_street_postal
        ? studentData.re_reg_data.ph_street_postal
        : "",
    PostalCity:
      studentData && studentData.re_reg_data && studentData.re_reg_data.ph_city_postal
        ? studentData.re_reg_data.ph_city_postal
        : "",
    PostalProvince:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.ph_province_postal
        ? studentData.re_reg_data.ph_province_postal
        : "",
    PostalCountry:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.ph_country_postal
        ? studentData.re_reg_data.ph_country_postal
        : "",
    PostalCodePostal:
      studentData && studentData.re_reg_data && studentData.re_reg_data.ph_zip_postal
        ? studentData.re_reg_data.ph_zip_postal
        : "",
    school:
      studentData && studentData.re_reg_data && studentData.re_reg_data.academy
        ? studentData.re_reg_data.academy
        : "",
    school_id:
      studentData && studentData.re_reg_data && studentData.re_reg_data.academy
        ? studentData.re_reg_data.academy
        : isAcademicDepartment ? "academic" 
        : "",
    qualification:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.qualification
        ? studentData.re_reg_data.qualification
        : "",
    netsuite_department_id: studentData && 
    studentData.re_reg_data && 
    studentData.re_reg_data.netsuite_department_id ? 
    studentData.re_reg_data.netsuite_department_id 
    : "",
    delivery_method:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.delivery_methode
        ? studentData.re_reg_data.delivery_methode
        : "",
    delivery_method_id:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.delivery_methode
        ? studentData.re_reg_data.delivery_methode
        : "",
    campus:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.campus_selected
        ? studentData.re_reg_data.campus_selected
        : "",
    campus_id:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.campus_selected
        ? studentData.re_reg_data.campus_selected
        : "",
    intake:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.intake_year &&
        studentData.re_reg_data.intake_number
        ? `${studentData.re_reg_data.intake_year}/${studentData.re_reg_data.intake_number}`
        : "",
    // intake_year_id:
    //   studentData &&
    //     studentData.re_reg_data &&
    //     studentData.re_reg_data.intake_year
    //     ? studentData.re_reg_data.intake_year
    //     : "",
    // intake_number:
    //   studentData &&
    //     studentData.re_reg_data &&
    //     studentData.re_reg_data.intake_number
    //     ? studentData.re_reg_data.intake_number
    //     : "",
    // intake_number_id:
    //   studentData &&
    //     studentData.re_reg_data &&
    //     studentData.re_reg_data.intake_number
    //     ? studentData.re_reg_data.intake_number
    //     : "",
    finance_option:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.finance_option_chosen
        ? studentData.re_reg_data.finance_option_chosen
        : "",
    finance_option_id:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.finance_option_chosen
        ? studentData.re_reg_data.finance_option_chosen
        : "",
    laptop:
      studentData && studentData.re_reg_data && studentData.re_reg_data.laptop
        ? studentData.re_reg_data.laptop
        : "",
    laptop_id:
      studentData && studentData.re_reg_data && studentData.re_reg_data.laptop
        ? studentData.re_reg_data.laptop
        : "",
    brand:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.brand_reg
        ? studentData.re_reg_data.brand_reg
        : "",
    brand_id:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.brand_reg
        ? studentData.re_reg_data.brand_reg
        : "",
    sales_person:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.sale_person_id
        ? getValue("sales_person", studentData.re_reg_data.sale_person_id)
        : "",
    sales_person_id:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.sale_person_id
        ? studentData.re_reg_data.sale_person_id
        : "",
    early_bird_discount:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.early_bird_discount
        && studentData.re_reg_data.early_bird_discount
        ? "Yes"
        : "No",
    submission_date:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.submission_date
        ? studentData.re_reg_data.submission_date
        : "",
    StudentSelection:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.re_reg_subjects
        ? studentData.re_reg_data.re_reg_subjects
        : "",
    Disablity: "",
    invoiceForm: "",
    invoiceFormQuote: "",
    portalSubjectAssigned:
      studentData?.re_reg_data?.check_subject == 1 ? true : false,
    ebooksAssigned:
      studentData?.re_reg_data?.ebook_assigned == 1 ? true : false,
    email: true,
    examResult:
      studentData?.re_reg_data?.exam_results.length ? studentData?.re_reg_data?.exam_results == "failed" ? "failed" : "passed" : false,
    kitType: studentData?.re_reg_data?.kit_type,
    
    laptop_deliv_type: studentData?.re_reg_data?.laptop_deliv_type ? studentData?.re_reg_data?.laptop_deliv_type : "",
    laptop_collected_date: studentData && studentData.re_reg_data && studentData.re_reg_data.laptop_collected_date && studentData.re_reg_data.laptop_collected_date != "NaN" && studentData.re_reg_data.laptop_collected_date != "0" ? new Date(moment.unix(studentData.re_reg_data.laptop_collected_date)) : "",
    laptop_tracking_num: studentData?.re_reg_data?.laptop_tracking_num ? studentData?.re_reg_data?.laptop_tracking_num : "",

    laptopSerialNr: studentData && studentData?.re_reg_data && studentData?.re_reg_data?.laptop_sr && studentData?.re_reg_data?.laptop_sr !== "null" ? studentData?.re_reg_data?.laptop_sr : "",
    inCredit: studentData?.re_reg_data?.account_in_credit == 1 ? true : false,
    trackingNo: studentData && studentData.re_reg_data && studentData?.re_reg_data?.tracking_no ? studentData?.re_reg_data?.tracking_no : "",
    PrelimProgression: studentData && studentData.re_reg_data && studentData?.re_reg_data?.pre_progress_selection ? studentData?.re_reg_data?.pre_progress_selection : "",
    PrelimProgressionLabel: "",
    AssignedOfficer: studentData && studentData.re_reg_data && studentData?.re_reg_data?.assigned_officer ? studentData?.re_reg_data?.assigned_officer : "",
    AssignedOfficerLabel: "",
    Outcome: studentData && studentData.re_reg_data && studentData?.re_reg_data?.outcome ? studentData?.re_reg_data?.outcome : "",
    CRM: studentData?.re_reg_data?.crm_updated_rco ? true : false,
    XERO: studentData?.re_reg_data?.xero_updated_rco ? true : false,
    PORTAL: studentData?.re_reg_data?.portal_updated_rco ? true : false,
    QC: studentData?.re_reg_data?.qc_updated_rco ? true : false,
    Matric: "",
    Qualifications: "",
    Application: "",
    Invoice: "",
    Docs: "",
    upToDate: studentData?.re_reg_data?.account_up_to_date == 1 ? true : false,
    creditAmount: studentData?.re_reg_data?.credit_amount
      ? studentData?.re_reg_data?.credit_amount
      : 0,
    dateCollected: studentData && studentData.re_reg_data && studentData.re_reg_data.kit_collected_date && studentData.re_reg_data.kit_collected_date != "NaN" && studentData.re_reg_data.kit_collected_date != "0" ? new Date(moment.unix(studentData.re_reg_data.kit_collected_date)) : "",
    rcoNotes: studentData && studentData.re_reg_data && studentData?.re_reg_data?.rco_notes ? studentData?.re_reg_data?.rco_notes : "",
    rejectMsg: "",
    sales_notes: studentData?.re_reg_data?.sales_notes ? studentData?.re_reg_data?.sales_notes : "",
    CRMReject: studentData?.re_reg_data?.crm_checked ? true : false,
    XEROReject: studentData?.re_reg_data?.xero_checked ? true : false,
    PORTALReject: studentData?.re_reg_data?.portal_checked ? true : false,
    RejectReason: "",
    btnval: "",
    progress_q1: studentData && studentData.reRegIntakesProgress && studentData.reRegIntakesProgress[0] && studentData.reRegIntakesProgress[0].qualification ? studentData.reRegIntakesProgress[0].qualification : "",
    progress_intake_id: "",
    subject_notes: studentData && studentData.re_reg_data && studentData.re_reg_data.subject_notes,
    crm_checked_date: studentData && studentData.re_reg_data && studentData.re_reg_data.crm_checked_date,
    xero_checked_date: studentData && studentData.re_reg_data && studentData.re_reg_data.xero_checked_date,
    portal_checked_date: studentData && studentData.re_reg_data && studentData.re_reg_data.portal_checked_date,
    crm_checked_and_updated: studentData && studentData.crm_checked_and_updated,
    xero_checked_and_updated: studentData && studentData.xero_checked_and_updated,
    portal_checked_and_updated: studentData && studentData.portal_checked_and_updated,
    update_id: "",
    course_type:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.course_type
        ? studentData.re_reg_data.course_type
        : "",
    start_date:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.start_date && studentData.re_reg_data.start_date!="NaN"
        ? new Date(moment.unix(studentData.re_reg_data.start_date))
        : "",
    end_date:
      studentData &&
        studentData.re_reg_data &&
        studentData.re_reg_data.end_date && studentData.re_reg_data.end_date!="NaN"
        ? new Date(moment.unix(studentData.re_reg_data.end_date))
        : "",    
    RPL: studentData && studentData.re_reg_data?.recognition_prior_learning,
    laptop_ordered: studentData && studentData.re_reg_data?.laptop_ordered,
  };  
  const validationSchema = Yup.object({
    FirstName: isValidating?.isChecking && Yup.string().matches(NAME_REGEX, "Please enter valid first name").required("First Name is required").trim(),
    LastName: isValidating?.isChecking && Yup.string().matches(NAME_REGEX, "Please enter valid last name").required("Last Name is required").trim(),
    // Title: isValidating?.isChecking && Yup.string().when(["course_type"], {
    //   is : (course_type)=>(course_type != 'shortcourse'),
    //   then : Yup.string().required("Title is required")
    // }),
    RSAID: isValidating?.isChecking && Yup.string().when(["course_type"], {
      is : (course_type)=>(course_type != 'shortcourse'),
      then : Yup.string().required("RSA ID/Passport is required")
    }),
    DisablityId: isValidating?.isChecking && Yup.string().when(["course_type"], {
      is : (course_type)=>(course_type != 'shortcourse'),
      then : Yup.string().required("Disablity is required")
    }),
    intake: isValidating?.isChecking && Yup.string().when(["course_type"], {
      is : (course_type)=>(course_type != 'shortcourse'),
      then : Yup.string().required("Intake is required")
    }),
    laptopSerialNr: isValidating?.isSetToFinal && Yup.string().when(["laptop"], {
      is : (laptop)=>(laptop != 'no_laptop'),
      then : Yup.string().required("Laptop Serial Number is Required")
    }),
    laptop_deliv_type: isValidating?.isSetToFinal && Yup.string().when(["laptop"], {
      is : (laptop)=>(laptop != 'no_laptop'),
      then : Yup.string().required("Laptop Delivery Type is Required")
    }),
    laptop_collected_date: isValidating?.isSetToFinal && Yup.string().when(["laptop"], {
      is : (laptop)=>(laptop != 'no_laptop'),
      then : Yup.string().nullable().required("Laptop Date is Required"),
      else : Yup.string().nullable()
    }),
    laptop_tracking_num: isValidating?.isSetToFinal && Yup.string().when(["laptop_deliv_type"], {
      is : (laptop_deliv_type)=>(laptop_deliv_type && laptop_deliv_type == 'deliver'),
      then : Yup.string().required("Laptop Tracking Number is Required")
    }),
    // RSAID: isValidating?.isChecking && Yup.string().required("RSA ID is required").trim(),
    Email: isValidating?.isChecking && Yup.string()
      .required("Email is required")
      .matches(EMAIL_REGEX, "Please Enter a Valid Email"),
    Mobile: isValidating?.isChecking && Yup.string()
      .required("Mobile is required")
      .matches(MOBILE_REGEX, "Please Enter a Valid Mobile Number"),
    City: isValidating?.isChecking && Yup.string("Only alphabets are allowed for City")
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for this City")
      .trim(),
    PostalCode: isValidating?.isChecking && Yup.string()
      .test(
        "check pos",
        "Please Enter Valid Postal Code",
        (value) => (!value?.toString().includes(".")) || !value
      )
      .nullable(true),
    PostalCity: isValidating && Yup.string("Only alphabets are allowed for Postal City ")
      .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed for Postal City")
      .trim()
      .nullable(true),
    PostalCodePostal: isValidating && Yup.string()
      .nullable(true)
      .test(
        "check pos",
        "Please Enter Valid Postal Code",
        (value) => (value > 0 && !value.toString().includes(".")) || !value
      ),

    school: isValidating?.isChecking && !isAcademicDepartment && Yup.string().required("School is required"),
    qualification: isValidating?.isChecking && Yup.string().required("Qualification is required"),
    delivery_method: isValidating?.isChecking && Yup.string().required("Learning Method is required"),
    campus: isValidating?.isChecking && Yup.string().required("Campus is required"),
    // intake_year: isValidating?.isChecking && Yup.string().required("Intake Year is required"),
    // intake_number: isValidating?.isChecking && Yup.string().required("Intake Number is required"),
    laptop: (isValidating?.isChecking && !isValidating?.skippingField.includes("laptop")) && Yup.string().required("Laptop is required"),
    brand: isValidating?.isChecking && Yup.string().required("Brand is required"),
    dateCollected: isValidating?.isSetToFinal && Yup.string().when(["kitType"], {
      is : (kitType)=>{return (kitType == 'deliver' || kitType == 'collect')},
      then : Yup.string().nullable().required("Study Kit Date is Required")
    }),
    trackingNo: isValidating?.isSetToFinal && Yup.string().when(["kitType"], {
      is : (kitType)=>(kitType == 'deliver'),
      then : Yup.string().required("Tracking Number is Required")
    }),
    
    sales_person: (isValidating?.isChecking && !isValidating?.skippingField.includes("salesPerson")) && Yup.string().required("Sales Person is required"),
    // brand_id: isValidating?.isChecking && Yup.string().required("Brand is required"),
    portalSubjectAssigned: (isValidating?.isChecking && selectedBtn == "SettoFinal") && Yup.string().test("required", "Portal Subjects Assigned is required", (value) => {
      return isAcademicDepartment || value == "true";
    }),
    SaveAndAddToLivePortal: (isValidating?.isChecking && selectedBtn == "SettoFinal") && Yup.string().test("required", "Save and Add Programmes to Live portal has not been done", (value) => {      
      return isAcademicDepartment || value == 1;
    }),

    inCredit: isValidating?.isChecking && Yup.string().test("required", "Atleast one checkbox should be checked in Account", (value) => {
      if (!accountCredit.inCredit && !accountCredit.upToDate && (status
        && (status !== "Re-Registration"
          && status !== "Preliminary Re-Registration"
          && status !== "new-status"
          && status !== "Rejected"
          && status !== "Closed-Ordered"
          && status !== "Final-Status"
          && status !== "Re-Posted"
          && status !== "Re-Registration Rejected")
        || status == "Re-Registration-Accounts"
        || status == "Re-Registration-Accounts Rejected"
        || status == "In-Active"
        || status == "Re-Registration-Sales"
        || status == "Re-Registration-Sales Invoiced"
        || status == "Re-Reg Re-Posted"
        || status == "Re-Registration-Sales Admin")) {
        return false
      }
      return true
    }),
    creditAmount: isValidating?.isChecking && Yup.string().test("required", "Credit Amount Should be more than Zero", (value) => {
      if (status
        && ((status !== "Re-Registration"
          && status !== "Rejected"
          && status !== "Preliminary Re-Registration"
          && status !== "Re-Registration Rejected")
          || status == "Re-Registration-Accounts"
          || status == "Re-Registration-Accounts Rejected"
          || status == "In-Active"
          || status == "Re-Registration-Sales"
          || status == "Re-Registration-Sales Invoiced"
          || status == "Re-Posted"
          || status == "Re-Reg Re-Posted"
          || status == "Re-Registration-Sales Admin")) {
        if (accountCredit.inCredit && value <= 0) {
          return false
        } else if (!value || !value.toString()) {
          return false
        } else {
          return true
        }
      } else { return true }
    }),
    course_type: isValidating?.isChecking && Yup.string().required("Course Type is required"),
  });

  let SubjectDetailsAppend = (formData, values) => {
    let register_for = ""
    let repeat_for = ""
    let elective_for = ""
    Object.keys(registerFor).forEach((key, index) => {
      if (index) {
        register_for = register_for + "," + registerFor[key]
      } else {
        register_for = registerFor[key]
      }
    })
    Object.keys(repeat).forEach((key, index) => {
      if (index) {
        repeat_for = repeat_for + "," + repeat[key]
      } else {
        repeat_for = repeat[key]
      }
    })
    Object.keys(elective).forEach((key, index) => {
      if (index) {
        elective_for = elective_for + "," + elective[key]
      } else {
        elective_for = elective[key]
      }
    })
        
    formData.append("re_write[0]", reWriteIds.reWrite1 || 0);
    formData.append("re_write[1]", reWriteIds.reWrite2 || 0);
    formData.append("re_write[2]", reWriteIds.reWrite3 || 0);
    formData.append("re_write[3]", reWriteIds.reWrite4 || 0);
    formData.append("re_write[4]", reWriteIds.reWrite5 || 0);

    formData.append("re_write_id[0]", reWriteIds.reWriteId1 || 0);
    formData.append("re_write_id[1]", reWriteIds.reWriteId2 || 0);
    formData.append("re_write_id[2]", reWriteIds.reWriteId3 || 0);
    formData.append("re_write_id[3]", reWriteIds.reWriteId4 || 0);
    formData.append("re_write_id[4]", reWriteIds.reWriteId5 || 0);

    formData.append("re_submission[0]", reSubmissionIds.reSubmission1 || 0);
    formData.append("re_submission[1]", reSubmissionIds.reSubmission2 || 0);
    formData.append("re_submission[2]", reSubmissionIds.reSubmission3 || 0);
    formData.append("re_submission[3]", reSubmissionIds.reSubmission4 || 0);
    formData.append("re_submission[4]", reSubmissionIds.reSubmission5 || 0);
    formData.append("re_submission[5]", reSubmissionIds.reSubmission6 || 0);
    formData.append("re_submission[6]", reSubmissionIds.reSubmission7 || 0);

    formData.append("re_submission_id[0]", reSubmissionIds.reSubmissionId1 || 0);
    formData.append("re_submission_id[1]", reSubmissionIds.reSubmissionId2 || 0);
    formData.append("re_submission_id[2]", reSubmissionIds.reSubmissionId3 || 0);
    formData.append("re_submission_id[3]", reSubmissionIds.reSubmissionId4 || 0);
    formData.append("re_submission_id[4]", reSubmissionIds.reSubmissionId5 || 0);
    formData.append("re_submission_id[5]", reSubmissionIds.reSubmissionId6 || 0);
    formData.append("re_submission_id[6]", reSubmissionIds.reSubmissionId7 || 0);

    
    // Append dynamic redo fields
    // Append dynamic redo fields in pairs
    let redoKeys = Object.keys(redoIds).filter(key => /^redo\d+$/.test(key));
    let redoIdKeys = Object.keys(redoIds).filter(key => /^redoId\d+$/.test(key));
    
    let maxLength = Math.max(redoKeys.length, redoIdKeys.length);
    if((values.school_id === "business" || values.school_id == 'beauty' || values.school_id == 'IT') && redoKeys.length > 5){
      for (let i = 0; i < maxLength; i++) {
          formData.append(`re_do[${i}]`, redoIds[redoKeys[i]] || 0);
          formData.append(`re_do_id[${i}]`, redoIds[redoIdKeys[i]] || 0);
      }
    }else{
      formData.append("re_do[0]", redoIds.redo1 || 0);
      formData.append("re_do[1]", redoIds.redo2 || 0);
      formData.append("re_do[2]", redoIds.redo3 || 0);
      formData.append("re_do[3]", redoIds.redo4 || 0);
      formData.append("re_do[4]", redoIds.redo5 || 0);
      formData.append("re_do_id[0]", redoIds.redoId1 || 0);
      formData.append("re_do_id[1]", redoIds.redoId2 || 0);
      formData.append("re_do_id[2]", redoIds.redoId3 || 0);
      formData.append("re_do_id[3]", redoIds.redoId4 || 0);
      formData.append("re_do_id[4]", redoIds.redoId5 || 0);
    }

    formData.append("progress_id[0]", subjectCheckIds.progress1 || 0);
    formData.append("progress_id[1]", subjectCheckIds.progress2 || 0);
    formData.append("progress_id[2]", subjectCheckIds.progress3 || 0);
    formData.append("progress_id[3]", subjectCheckIds.progress4 || 0);
    formData.append("progress_id[4]", subjectCheckIds.progress5 || 0);

    formData.append("progress_q[0]", subjectCheckIds.qualification1 || 0);
    formData.append("progress_q[1]", subjectCheckIds.qualification2 || 0);
    formData.append("progress_q[2]", subjectCheckIds.qualification3 || 0);
    formData.append("progress_q[3]", subjectCheckIds.qualification4 || 0);
    formData.append("progress_q[4]", subjectCheckIds.qualification5 || 0);

    formData.append("progress_s[0]", subjectCheckIds.subject1 || 0);
    formData.append("progress_s[1]", subjectCheckIds.subject2 || 0);
    formData.append("progress_s[2]", subjectCheckIds.subject3 || 0);
    formData.append("progress_s[3]", subjectCheckIds.subject4 || 0);
    formData.append("progress_s[4]", subjectCheckIds.subject5 || 0);

    formData.append("register_for", register_for || "");
    formData.append("repeat_for", repeat_for || "");
    formData.append("elective", elective_for || "");
    
    return formData;
  }

  let AccountDetailsAppend = (formData, values) => {
    return (
      formData.append("account_up_to_date", values.upToDate == true ? 1 : 0),
      formData.append("account_in_credit", values.inCredit == true ? 1 : 0),
      formData.append("credit_amount", values.creditAmount)
    )
  }
  const formSubmit = async (values, actions) => {
    // console.log("cjonossss", values, subjectCheckIds)
    // return ;
    await setIsPopupAllowed(true)
    await setBtnDissable(selectedBtn)
    if (!isSubmitionAllowed) {
      $("#ProfileMatchModal").modal("show");
      await setIsSubmitionAllowed(true);
      return;
    } else if (isSubmitionAllowed == "SaveAccept") {
      await setIsSubmitionAllowed(true);
      return;
    }
    const formData = new FormData();
    formData.append("application_status", values.ApplicationStatus);
    formData.append("progress_q1", values.progress_q1);
    formData.append("progress_intake_id", values.progress_intake_id);
    formData.append("to_be_reviewed", values.toBeReceived == true ? 1 : 0);
    formData.append("student_id", studentId);
    formData.append("contact_id", reRegData.NScontactID || 0);
    formData.append("booking_id", reRegData.bookingId || 0);
    formData.append("first_name", values.FirstName);
    formData.append("initials", values.Initials);
    formData.append("maiden_name", values.MaidenName);
    formData.append("title", values.Title);
    formData.append("second_name", values.SecondName);
    formData.append("third_name", values.ThirdName);
    formData.append("last_name", values.LastName);
    formData.append("mobile", values.Mobile);
    formData.append("id_number", values.StudentNumber);
    formData.append("email", values.Email);
    formData.append("disability", values.DisablityId || "none");
    formData.append("extra_time", values.AllowedExtraTime == "Yes" ? 1 : 0);
    formData.append("ph_street", values.StreetAddress);
    formData.append("ph_city", values.City);
    formData.append("ph_province", values.Province);
    formData.append("ph_country", values.Country);
    formData.append("ph_zip", values.PostalCode);
    formData.append("ph_city_postal", values.PostalCity);
    formData.append("ph_street_postal", values.PostalStreetAddress);
    formData.append("ph_province_postal", values.PostalProvince);
    formData.append("ph_country_postal", values.PostalCountry);
    formData.append("ph_zip_postal", values.PostalCodePostal);
    formData.append("rsa_identity", values.RSAID);

    formData.append("intake_year", values.intake.split("/")[0]);
    formData.append("intake_number", values.intake.split("/")[1]);
    formData.append("finance_option_chosen", values.finance_option_id);
    formData.append("laptop", values.laptop);
    formData.append("lapreq1", values.laptopSerialNr);
    formData.append("brand_reg_val", values.brand);
    formData.append("brand_reg", values.brand_id);
    formData.append("sale_person_id", values.sales_person_id);
    formData.append("academy", values.school_id);
    formData.append("qualification_crm", values.qualification);
    formData.append("netsuite_department_id", values.netsuite_department_id);
    formData.append("delivery_method", values.delivery_method);
    formData.append("campus_selected", values.campus_id);
    formData.append("sales_notes", salesNotesEditorState);
    formData.append("early_bird_discount",values.early_bird_discount);
    formData.append("created_date", values.submission_date);
    formData.append("course_type", values.course_type);
    formData.append("start_date", values.start_date ? new Date(values.start_date).getTime()/1000 : "");
    formData.append("end_date", values.end_date ? new Date(values.end_date).getTime()/1000 : "");
    formData.append("reject_message", values.rejectMsg);      
    formData.append("subject_notes", rejectEditorState);
    formData.append("laptop_deliv_type", values.laptop_deliv_type);
    formData.append("laptop_collected_date", values.laptop_collected_date ? (new Date(values.laptop_collected_date).getTime() / 1000) : "");
    formData.append("laptop_tracking_num", values.laptop_tracking_num);
    formData.append(
      "live_portal_user_id",
      studentData?.re_reg_data?.live_portal_user_id
    );
    formData.append(
      "payment_received_date",
      studentData?.re_reg_data?.payment_received_date
    );
    formData.append("update_id", values.update_id);
    if (status != "Re-Registration-Sales-Rejected" && status != "Re-Registration Rejected" && status != "Rejected" && status != "Re-Registration Cancelled" && status != "Re-Registration-Study Kit Pending" && status != "Review" && status != "Re-Registration-Accounts" && status != "In-Active" && status != "New" && status != "new-status" && status != "Re-Posted" && status != "Re-Reg Re-Posted") {
      formData.append("reject_message", values.RejectReason);
    }

    if (status && status !== "Re-Registration-Sales-Rejected" && status !== "Re-Registration Rejected" && status !== "Re-Registration Cancelled" && status !==  "Re-Registration-Study Kit Pending" && status !== "Re-Registration-Accounts Rejected" && status !== "New" && status !== "new-status" && status !== "Rejected" && status != "Re-Posted" && status != "Re-Reg Re-Posted") {
      formData.append("subject_notes", values.RejectReason);
    }   

      

    if (
      status !== "Re-Registration"
      && status !== "Preliminary Re-Registration"
      && status !== "Re-Registration Rejected"
      && status !== "Re-Registration-Accounts"
      && status !== "Re-Registration-Accounts Rejected"
      && status !== "In-Active"
      && status !== "Re-Registration-Sales"
      && status !== "Re-Registration-Sales Invoiced"
      && status !== "Re-Posted"
      && status !== "Re-Reg Re-Posted"
      && status !== "Re-Registration-Sales Admin"
      && status !== "Closed-Ordered2"
      && status !== "assign-subjects"
      && status !== "new-status"
      && status !== "Rejected"
    ) {
      formData.append("kit_collected_date", values.dateCollected ? (new Date(values.dateCollected).getTime() / 1000) : "");
      formData.append("kit_type", values.kitType);
    }
    if (status && status == "Re-Registration-RCO") {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
      AccountDetailsAppend(formData, values)

      formData.append("quote_payment", values.invoiceFormQuote);
      formData.append("prelim_prog", values.PrelimProgression);
      formData.append("assign_to", values.AssignedOfficer);
      formData.append("outcome", values.Outcome);
      formData.append("rco_notes", values.rcoNotes);
      formData.append("crm_updated_rco", values.CRM);
      formData.append("xero_updated_rco", values.XERO);
      formData.append("portal_updated_rco", values.PORTAL);
      formData.append("qc_updated_rco", values.QC);
    }

    if (status && status == "Re-Registration-Sales-Rejected") {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
      AccountDetailsAppend(formData, values)

      formData.append("quote_payment", values.invoiceFormQuote);

      // formData.append("kit_type", values.kitType);
      // formData.append("kit_collected_date", (new Date(values.dateCollected).getTime() / 1000));      
      formData.append("crm_updated_rco", values.CRMReject);
      formData.append("xero_updated_rco", values.XEROReject);
      formData.append("portal_updated_rco", values.PORTALReject);
    }

    if (
      (status && status == "Re-Registration") ||
      status == "Preliminary Re-Registration"
    ) {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
      setLoaderPos("saveBtn");
    }

    if (status && status == "Re-Registration Rejected") {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)

      // formData.append("kit_type", values.kitType);
      // formData.append("kit_collected_date", (new Date(values.dateCollected).getTime() / 1000));
      formData.append("tracking_no", values.trackingNo);

      // formData.append("reject_message", values.rejectMsg);
      // formData.append("subject_notes", rejectEditorState);
      formData.append("crm_updated_rco", values.CRMReject);
      formData.append("xero_updated_rco", values.XEROReject);
      formData.append("portal_updated_rco", values.PORTALReject);
    }

    if (status && status == "Re-Registration Cancelled") {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
      AccountDetailsAppend(formData, values)

      // formData.append("kit_type", values.kitType);
      // formData.append("kit_collected_date", (new Date(values.dateCollected).getTime() / 1000));

      // formData.append("reject_message", values.rejectMsg);
      // formData.append("subject_notes", rejectEditorState);
      formData.append("crm_updated_rco", values.CRMReject);
      formData.append("xero_updated_rco", values.XEROReject);
      formData.append("portal_updated_rco", values.PORTALReject);

      formData.append(
        "check_subject",
        values.portalSubjectAssigned == true ? 1 : 0
      );
      formData.append("exam_result", values.examResult == true ? 1 : 0);
      formData.append("check_subject", values.examResult == true ? 1 : 0);
    }

    if (status && status == "Re-Registration-Final") {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
      AccountDetailsAppend(formData, values)

      // formData.append("kit_type", values.kitType);
      // formData.append("kit_collected_date", (new Date(values.dateCollected).getTime() / 1000));

      formData.append("quote_payment", values.invoiceFormQuote);

      formData.append(
        "check_subject",
        values.portalSubjectAssigned == true ? 1 : 0
      );
      formData.append("exam_result", values.examResult == true ? 1 : 0);
      formData.append("check_subject", values.examResult == true ? 1 : 0);
    }

    if (status !== "Re-Registration"
      && status !== "Preliminary Re-Registration"
      && status !== "Re-Registration Rejected"
      && status !== "Re-Registration-Accounts"
      && status !== "Re-Registration-Accounts Rejected"
      && status !== "In-Active"
      && status !== "Re-Registration-Sales"
      && status !== "Re-Registration-Sales Invoiced"
      && status !== "Re-Posted"
      && status !== "Re-Reg Re-Posted"
      && status !== "Re-Registration-Sales Admin"
      && status !== "Closed-Ordered2"
      && status !== "assign-subjects"
      && status !== "new-status"
      && status !== "Rejected") {
      formData.append("tracking_no", values.trackingNo);
    }

    if (
      (status && status == "Re-Registration-Study Kit Pending") ||
      status == "Review"
    ) {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
      AccountDetailsAppend(formData, values)

      // formData.append("kit_type", values.kitType);
      // formData.append("kit_collected_date", (new Date(values.dateCollected).getTime() / 1000));

      formData.append("quote_payment", values.invoiceFormQuote);

      // formData.append("reject_message", values.rejectMsg);
      // formData.append("subject_notes", rejectEditorState);
      formData.append("crm_updated_rco", values.CRMReject);
      formData.append("xero_updated_rco", values.XEROReject);
      formData.append("portal_updated_rco", values.PORTALReject);

      formData.append(
        "check_subject",
        values.portalSubjectAssigned == true ? 1 : 0
      );
      formData.append("exam_result", values.examResult == true ? 1 : 0);
      formData.append("check_subject", values.examResult == true ? 1 : 0);
      formData.append("save_and_add_to_live_portal", values.SaveAndAddToLivePortal);
    }
    if (
      (status && status == "Re-Registration-Accounts") ||
      status == "In-Active"
    ) {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
      AccountDetailsAppend(formData, values)
    }

    if (status && status == "Re-Registration-Accounts Rejected") {
      AccountDetailsAppend(formData, values)

      // formData.append("reject_message", values.rejectMsg);
      // formData.append("subject_notes", rejectEditorState);
      formData.append("crm_updated_rco", values.CRMReject);
      formData.append("xero_updated_rco", values.XEROReject);
      formData.append("portal_updated_rco", values.PORTALReject);
    }

    if (status && status == "Study Kit Pending") {
      // formData.append("kit_type", values.kitType);
      // formData.append("kit_collected_date", (new Date(values.dateCollected).getTime() / 1000));

      formData.append(
        "check_subject",
        values.portalSubjectAssigned == true ? 1 : 0
      );
      formData.append("save_and_add_to_live_portal", values.SaveAndAddToLivePortal);
      formData.append("exam_result", values.examResult == true ? 1 : 0);
      formData.append("check_subject", values.examResult == true ? 1 : 0);
    }

    if (status && status == "Rejected") {
      AccountDetailsAppend(formData, values)

      // formData.append("reject_message", values.rejectMsg);
      // formData.append("subject_notes", rejectEditorState);
      formData.append("crm_updated_rco", values.CRMReject);
      formData.append("xero_updated_rco", values.XEROReject);
      formData.append("portal_updated_rco", values.PORTALReject);

      formData.append(
        "check_subject",
        values.portalSubjectAssigned == true ? 1 : 0
      );
      formData.append("exam_result", values.examResult == true ? 1 : 0);
      formData.append("check_subject", values.examResult == true ? 1 : 0);
    }

    if (status && (status == "Re-Registration-Sales Admin" || status == "Re-Posted" || status == "Re-Reg Re-Posted")) {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
      AccountDetailsAppend(formData, values)

      formData.append("quote_payment", values.invoiceFormQuote);
    }

    if (status && status == "Re-Registration-Sales") {
      formData.append("re_reg_subjects", values.StudentSelection);
      SubjectDetailsAppend(formData, values)
    }

    if (
      status &&
      (status == "Re-Registration" ||
        status == "Re-Registration-Sales-Rejected" ||
        status == "Re-Registration-Study Kit Pending" ||
        status == "Re-Registration-RCO" ||
        status == "Review" ||
        status == "Study Kit Pending" ||
        status == "Final-Status" ||
        status == "Rejected" ||
        status == "Closed-Ordered" ||
        status == "Preliminary Re-Registration" ||
        status == "Re-Registration-Accounts Rejected" ||
        status == "Re-Registration-Sales" ||
        status == "Re-Registration-Final" ||
        status == "Re-Registration-Accounts" ||
        status == "Accounts" ||
        status == "Re-Registration Rejected" ||
        status == "Re-Posted" ||
        status == "Re-Reg Re-Posted" ||
        status == "Re-Registration-Sales Admin" ||
        status == "New" ||
        status == "new-status")
    ) {
      setLoaderPos("saveBtn");
      formData.append("section_to_save", "re_registration_about_section");
    }

    if (status == "New" || status == "new-status" || status == "Re-Posted" || status == "Re-Reg Re-Posted") {
      formData.append("reject_message", values.RejectReason);
      formData.append("subject_notes", rejectEditorState);
    }

    if (values.btnval === "Rejected") {
      formData.append("reject_message", values.RejectReason);
      formData.append("subject_notes", rejectEditorState);
      formData.append("student_reg_status", "Rejected");
    }

    formData.append("btnval", values.ApplicationStatus == "Re-Registration Rejected" ? "Rereg" : values.btnval);    
    updateSectionToSave(formData)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: `${res?.data?.message || "Rejected Successfully"}`,
        });
        setLoaderPos("");
        setRefreshComponent(!refreshComponent);
        setBtnDissable("")
        $("#ProfileMatchModal").modal("hide");
      })
      .catch((err) => {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err.response?.data?.message ? err.response?.data?.message : err.response?.data?.error ? err.response?.data?.error : ""}`,
        });
        setBtnDissable("")
      });
  };

  const checkboxDetails = (sendaction, sentorder) => {
    checkCheckboxRejectDetails({
      id: studentId,
      action: sendaction,
      order: sentorder ? 1 : 0,
    })
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  return (
    <>
      <div className="card card-profile-info-card mb-30">
        <div className="card-body">
          <div className="card-body-inr card-body-info">
            <Formik
              enableReinitialize
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={formSubmit}
            >
              <Form>
                <ApplicationStatus
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                  disabled={disabled}
                />
                <StudentNumber
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                />
                <StudentDetails
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                />
                <Address
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                />

                <RegistrationDetails
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                  salesPerson={salesPerson}
                  allBrands={allBrands}
                  studentId={studentId}
                  school={initialValues.school}
                  isAcademicDepartment={isAcademicDepartment}
                  loadingData={loadingData}
                />
                {/* <ERPList
                  loading={loadingData}
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                /> */}
                <ERPData
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                  ERPTableData={ERPTableData}
                  loading={netSuiteDataLoading}
                />

                {reRegData.academy == "engineering" && (
                  <>
                    <ExamRegList
                      studentData={studentData}
                      clickedCheck={checkboxDetails}
                    />
                    <hr />
                  </>
                )}

                {!disabled
                  && reRegData.status !== "new-status"
                  && reRegData.status !== "Closed-Ordered"
                  && reRegData.status !== "Final-Status"
                  && reRegData.status !== "Rejected"
                  && reRegData.status !== "Re-Posted"
                  && (reRegData.academy == "draughting"
                    || reRegData.academy == 'architecture'
                    || reRegData.academy == 'gdc'
                    || reRegData.academy == 'engineering'
                    || reRegData.academy == 'fst'
                    || reRegData.academy == 'fg' || reRegData.academy == 'beauty' || reRegData.academy == 'IT' || reRegData.academy == 'business')
                  ? (
                    <>
                      <SubjectCheck
                        studentData={studentData}
                        clickedCheck={checkboxDetails}
                        reRegData={reRegData}
                        status={status}
                        subjectCheckIds={subjectCheckIds}
                        setSubjectCheckIds={setSubjectCheckIds}
                        reWriteIds={reWriteIds}
                        setReWriteIds={setReWriteIds}
                        redoIds={redoIds}
                        setRedoIds={setRedoIds}
                        reSubmissionIds={reSubmissionIds}
                        setReSubmissionIds={setReSubmissionIds}
                        registerFor={registerFor}
                        setRegisterFor={setRegisterFor}
                        repeat={repeat}
                        setRepeat={setRepeat}
                        elective={elective}
                        setElective={setElective}
                      />
                      <hr />
                    </>
                  ) : ""}

                {(status
                  && (status !== "Re-Registration"
                    && status !== "Preliminary Re-Registration"
                    && status !== "new-status"
                    && status !== "Closed-Ordered"
                    && status !== "Final-Status"
                    && status !== "Rejected"
                    && status !== "Re-Posted"
                    && status !== "Re-Registration Rejected")
                  || (status == "Re-Registration-Accounts"
                    || status == "Re-Registration-Accounts Rejected"
                    || status == "In-Active"
                    || status == "Re-Registration-Sales"
                    || status == "Re-Registration-Sales Invoiced"
                    || status == "Re-Reg Re-Posted"
                    || status == "Re-Registration-Sales Admin")) && (
                    <>
                      <Accounts
                        studentData={studentData}
                        clickedCheck={checkboxDetails}
                        setAccountCredit={setAccountCredit}
                      />
                      <hr />
                    </>
                  )}

                {(status
                  && status !== "Re-Registration"
                  && status !== "Preliminary Re-Registration"
                  && status !== "Re-Registration Rejected"
                  && status !== "Re-Registration-Accounts"
                  && status !== "Re-Registration-Accounts Rejected"
                  && status !== "In-Active"
                  && status !== "new-status"
                  && status !== "Closed-Ordered"
                  && status !== "Final-Status"
                  && status !== "Rejected"
                  && status !== "Re-Registration-Sales"
                  && status !== "Re-Posted"
                  && status !== "Re-Reg Re-Posted"
                  && status !== "Re-Registration-Sales Admin"
                  && status !== "Re-Registration-Study Kit Pending"
                ) && (
                    <>
                      <QuoteAndPayment
                        studentData={studentData}
                        clickedCheck={checkboxDetails}
                      />
                      <hr />
                    </>
                  )
                }

                {((reRegData.reject_status_message && reRegData.reject_status_message !== "" && reRegData.reject_status_message !== "null" && reRegData.reject_status_message !== "undefined") || (reRegData.subject_notes && reRegData.subject_notes !== "" && reRegData.subject_notes !== "null" && reRegData.subject_notes !== "undefined")) && (
                  <>
                    <Reject
                      studentData={studentData}
                      clickedCheck={checkboxDetails}
                      rejectEditorState={rejectEditorState}
                      setRejectEditorState={setRejectEditorState}
                      disabled={disabled}
                    />
                    <hr />
                  </>
                )}
                {/* <SalesNotes
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                  disabled={disabled}
                  salesNotesEditorState={salesNotesEditorState}
                  setSalesNotesEditorState={setSalesNotesEditorState}
                /> */}
                {(status !== "Re-Registration"
                  && status !== "Preliminary Re-Registration"
                  && status !== "Re-Registration Rejected"
                  && status !== "Re-Registration-Accounts"
                  && status !== "Re-Registration-Accounts Rejected"
                  && status !== "In-Active"
                  && status !== "Re-Registration-Sales"
                  && status !== "Re-Registration-Sales Invoiced"
                  && status !== "Re-Posted"
                  && status !== "Re-Reg Re-Posted"
                  && status !== "Re-Registration-Sales Admin"
                  && status !== "Closed-Ordered2"
                  && status !== "Rejected"
                  && status !== "new-status"
                  && !isAcademicDepartment) && (
                    <>
                      <Checks
                        studentData={studentData}
                        clickedCheck={checkboxDetails}
                        refresh={refreshComponent}
                        setrefresh={setRefreshComponent}
                      />
                      <hr />
                    </>
                  )}

                {status &&
                  (status !== "Re-Registration"
                    && status !== "Preliminary Re-Registration"
                    && status !== "Re-Registration Rejected"
                    && status !== "Re-Registration-Accounts"
                    && status !== "Re-Registration-Accounts Rejected"
                    && status !== "In-Active"
                    && status !== "Re-Registration-Sales"
                    && status !== "Re-Registration-Sales Invoiced"
                    && status !== "Re-Posted"
                    && status !== "Re-Reg Re-Posted"
                    && status !== "Re-Registration-Sales Admin"
                    && status !== "Closed-Ordered2"
                    && status !== "assign-subjects"
                    && status !== "new-status"
                    && status !== "Rejected"
                  ) && (
                    <>
                      <StudyKits
                        studentData={studentData}
                        clickedCheck={checkboxDetails}
                        status={status}
                        refresh={refreshComponent}
                        setrefresh={setRefreshComponent}
                        reRegData={reRegData}
                        studyKitType={dropdownValues.study_kit_type_arr}
                        isAcademicDepartment={isAcademicDepartment}
                      />
                      <hr />
                    </>
                  )}
                {status &&
                  (status === "Re-Posted" || status === "Re-Reg Re-Posted") && (
                    <>
                      <StudykitLaptopOrdered />
                      <hr />
                    </>
                  )}

                {status && (status == "Re-Registration-RCO" || (reRegData.exam_results == "failed" && status == "Re-Registration-Final")) && (
                  <>
                    <RCO
                      studentData={studentData}
                      clickedCheck={checkboxDetails}
                    />
                    <hr />
                  </>
                )}
                <Documents
                  refresh={refreshComponent}
                  setrefresh={setRefreshComponent}
                  studentData={studentData}
                  clickedCheck={checkboxDetails}
                />
                <hr />

                <SubmitButton
                  studentData={studentData}                  
                  refresh={refreshComponent}
                  setrefresh={setRefreshComponent}
                  lPos={loaderPos}
                  setSelectedBtn={setSelectedBtn}
                  btnDissable={btnDissable}
                  setBtnDissable={setBtnDissable}
                  selectedBtn={selectedBtn}
                  setIsSubmitionAllowed={setIsSubmitionAllowed}
                  isPopupAllowed={isPopupAllowed}
                  setIsValidating={setIsValidating}
                  isAcademicDepartment={isAcademicDepartment}
                  courseItemNonMatch={courseItemNonMatch}
                  isGDC={isGDC}
                />
              </Form>
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default OpenRegistrationTab;
