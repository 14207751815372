import axiosInstance from "../utils/axios";

export const GetGradeMixList = async (data,cancelToken) => {
    return await axiosInstance().post(`/getGradeMixList`, data, {cancelToken: cancelToken});
};

export const AddGradeMix = async (data) => {
    return await axiosInstance().post(`/addGradeMix`, data);
};

export const EditGradeMix = async (data) => {
    return await axiosInstance().post(`/editGradeMix`, data);
};

export const GetGradeMixDetails = async (data, cancelToken) => {
    return await axiosInstance().get(`/getGradeMixDetails`, {params: data, cancelToken: cancelToken});
};

export const gradebookOfgradeMix = async (data, cancelToken) => {
    return await axiosInstance().post(`/gradebookOfgradeMix`, data, {cancelToken: cancelToken});
};

export const getAssessmentSubmissionResultGrademix = async (data, cancelToken) => {
    return await axiosInstance().post(`/getAssessmentSubmissionResultGrademix`, data, {cancelToken: cancelToken});
};

export const getGradeMixAssessments = async (id, cancelToken) => {
    return await axiosInstance().get(`/getGradeMixAssessments?id=${id}`, {cancelToken: cancelToken});
};

export const getGrademixListViaAssessment = async (values, cancelToken) => {
    return await axiosInstance().post(`/getGrademixListViaAssessment?id=${values.id}`,values, { cancelToken: cancelToken });
};


export const updateGradeMixScore = async (data) => {
    return await axiosInstance().post(`/updateGradeMixScore`, data);
};