import React, { useState, useMemo, useEffect } from 'react'
import Header from '../common/Header'
import SideBar from '../common/SideBar'
import Tablefilter from '../common/Tablefilter'
import { TABLE_DATE_FORMAT, TABLE_ROWS_PER_PAGE } from '../../utils/Constants'
import moment from 'moment'
import { Link, useHistory, useParams } from 'react-router-dom'
import AuditTrails from '../courses/AuditTrail'
import {
  getBookedCourseAuditFilters,
  getBookedCourseAuditTrail,
  getBookedCoursesList,
  getBookedCoursesListCancelToken,
  GetCourseAuditTrialDropdownCancelToken,
} from '../../services/CourseService'
import {
  RenderBookingState,
  RenderBrandListMultiLine,
  RenderCampus,
  RenderITSetup,
  RenderCateringSetup,
  RenderLearningMethod,
  RenderCourseResourceType,
} from '../../utils/CommonGroupingItem'
import axios from 'axios'
import SkeletonTicketList from '../../loaders/SkeletonTicketList'
import Swal from 'sweetalert2'
import PermissionsGate from '../../utils/permissionGate'
import { useSelector } from 'react-redux'
import Papa from 'papaparse'
import * as XLSX from 'xlsx'
import JsPDF from 'jspdf'
import 'jspdf-autotable'
import DataTable from 'react-data-table-component'
import DataTableComponentFrontPagination from '../common/DataTableComponentFrontPagination'
import AuditTrailsTable from '../common/AuditTrailsTable'

const BookedCourses = () => {
  const history = useHistory()
  const { tab } = useParams()
  const [search, setSearch] = useState('')
  const [dateFilter, setdateFilter] = useState({arr: ['Next_7'],checkObj:{ Next_7: true }})
  const [cateringFilter, setCateringFilter] = useState({arr: [],checkObj: {}})
  const [brandFilter, setBrandFilter] = useState({ arr: [], checkObj: {} })
  const [roomFilter, setRoomFilter] = useState({ arr: [], checkObj: {} })
  const [campusFilter, setCampusFilter] = useState({ arr: [], checkObj: {} })
  const [statusFilter, setStatusFilter] = useState({ arr: [], checkObj: {} })
  const [itSetup, setItSetup] = useState({ arr: [], checkObj: {} })
  const [courseDropdown, setCourseDropdown] = useState({
    state: [
      { label: 'Closed', value: 'Closed' },
      { label: 'Open', value: 'Open' },
      { label: 'Delivered', value: 'Delivered' },
      { label: 'Cancelled', value: 'Cancelled' },
    ],
    date: [
      { label: 'Upcomming', value: 'future' },
      { label: 'Next 30 Days', value: 'Next_30' },
      { label: 'Next 7 Days', value: 'Next_7' },
      { label: 'Today', value: 'today' },
      { label: 'Past', value: 'past' },
    ],
  })
  const [courseId, setCourseId] = useState('')
  const [catering_setup, setCatering_setup] = useState([])
  const [it_setup, setIt_setup] = useState([])
  const [brand, setBrand] = useState([])
  const [room, setRoom] = useState([])
  const [campus, setCampus] = useState([])
  const [status, setStatus] = useState([])
  const [loading, setLoading] = useState(false)
  const [coursebookingList, setcoursebookingList] = useState([])
  const [courseSearch, setCourseSearch] = useState('')

  useEffect(() => {
    const cancelTokenSources = []
    const getBookedCoursesList = async () => {
      setLoading(true)

      cancelTokenSources.forEach(source => {
        source.cancel('New request made')
      })

      const source = axios.CancelToken.source()
      // setCancelTokenSources([...cancelTokenSources, source]);
      cancelTokenSources.push(source)
      // var dateValue = dateFilter?.arr ? dateFilter.arr[0] : "All" ;
      const dateValue = dateFilter?.arr?.[0] || 'All'

      let data = {
        searchInput: search,
        filterDate: dateValue,
      }

      try {
        const res = await getBookedCoursesListCancelToken(data, source.token)
        if (res.status == 200) {
          setcoursebookingList(res.data.data)
          if (res?.data?.data) {
            const data = res.data.data
            const cateringSetup = [
              ...new Set(data.map(item => item?.catering_setup)),
            ].map(value => ({ label: value, value }))
            setCatering_setup(cateringSetup)
            const itSetup = [...new Set(data.map(item => item?.it_setup))].map(
              value => ({ label: value, value }),
            )
            setIt_setup(itSetup)
            const brandData = [
              ...new Set(data.map(item => item?.subsidiary)),
            ].map(value => ({ label: value, value }))
            setBrand(brandData)
            const roomData = [...new Set(data
              .map(item => item?.room)
              .filter(value => value))] // Filter out undefined or empty values
              .map(value => ({ label: value, value }));

            setRoom(roomData);

            const campusData = [
              ...new Set(data.map(item => item?.campus).filter(Boolean)),
            ].map(value => ({ label: value, value }))
            setCampus(campusData || [])
            const statusData = [...new Set(data.map(item => item?.state))].map(
              value => ({ label: value, value }),
            )
            setStatus(statusData)
          }
          setLoading(false)
        }
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)
          setLoading(false)
        }
      }
    }

    getBookedCoursesList()
    return () => {
      cancelTokenSources.forEach(source => {
        source.cancel('Component unmounted')
      })
    }
  }, [dateFilter])

  useEffect(() => {
    const storedFilters = JSON.parse(
      localStorage.getItem('filterStateBookedCourses'),
    )
    if (storedFilters) {
      setdateFilter(storedFilters.dateFilter)
    }
  }, [])

  useEffect(() => {
    const filterState = {
      dateFilter,
    }
    localStorage.setItem(
      'filterStateBookedCourses',
      JSON.stringify(filterState),
    )
  }, [dateFilter])

  useEffect(() => {
    const cancelTokenDropdownSources = []

    const fetchData = async () => {
      cancelTokenDropdownSources.forEach(source => {
        source.cancel('New request made')
      })

      const source = axios.CancelToken.source()
      cancelTokenDropdownSources.push(source)
      let data = {
        searchInput: '',
        filterDate: 'Next_7',
      }
      try {
        const res = await getBookedCoursesListCancelToken(data, source.token)
      } catch (error) {
        if (!axios.isCancel(error)) {
          console.error(error)
        }
      }
    }

    fetchData()

    return () => {
      cancelTokenDropdownSources.forEach(source => {
        source.cancel('Component unmounted')
      })
    }
  }, [tab])
  const myNameFilter = (a, b) => {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
  }
  const PrelimSort = (a, b) => {
    return a.prelim_total > b.prelim_total ? 1 : -1
  }
  const FinalSort = (a, b) => {
    return a.final_total > b.final_total ? 1 : -1
  }

  const sortingStart = (a, b) => {
    return moment(a.start).unix() > moment(b.start).unix() ? 1 : -1
  }

  const sortingEnd = (a, b) => {
    return moment(a.end).unix() > moment(b.end).unix() ? 1 : -1
  }
  const sortingLaptop = (a, b) => {
    return a.laptop_total > b.laptop_total ? 1 : -1
  }
  const handleSearchFilter = e => {
    const value = e.target.value
    setSearch(value)
  }
  const resetFilter = () => {
    setSearch('')
    setdateFilter({ arr: [], checkObj: {} })
  }
  const columns = useMemo(
    () => [
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        wrap: true,
        sortFunction: myNameFilter,
        cell: row => (
          <span className="feature-name">
            <PermissionsGate
              scopes={['cbview']}
              errorProps={{ disabled: true }}
            >
              <Link
                className="as-text-blue curser textLimit50"
                to={`/courseAdministration/bookedcourses/open/${row.id}`}
                title={row.name}
              >
                {row.name ? row.name : '-'}
              </Link>
            </PermissionsGate>
          </span>
        ),
      },
      {
        name: 'Status',
        selector: 'Status',
        sortable: false,
        wrap: true,
        cell: row =>
          RenderBookingState(row.state_id ? row.state_id : row.state).html,
      },
      {
        name: 'Final Total',
        selector: 'finaltotal',
        sortable: true,
        sortFunction: FinalSort,
        cell: row => (
          <span
            className="final-total"
            style={
              row.final_total && row.final_total > 0
                ? { backgroundColor: 'yellow', padding: '5px' }
                : {}
            }
          >
            {row.final_total}
          </span>
        ),
      },
      {
        name: 'Prelim Total',
        selector: 'prelimtotal',
        sortable: true,
        sortFunction: PrelimSort,
        cell: row => (
          <span
            className="prelim-total"
            style={
              row.prelim_total && row.prelim_total > 0
                ? { backgroundColor: '#FFCCCB', padding: '5px' }
                : {}
            }
          >
            {row.prelim_total}
          </span>
        ),
      },
      {
        name: 'Laptops Required',
        selector: 'laptops',
        sortable: true,
        sortFunction: sortingLaptop,
        cell: row => (
          <span
            className="laptops"
            style={
              row.laptop_total && row.laptop_total > 0
                ? { backgroundColor: 'lightblue', padding: '5px' }
                : {}
            }
          >
            {row.laptop_total}
          </span>
        ),
      },
      {
        name: 'Start',
        selector: 'start',
        sortable: true,
        wrap: true,
        sortFunction: sortingStart,
        cell: row => (
          <>
            <span className="start">
              {moment(row.start_date, 'DD/MM/YYYY').format(TABLE_DATE_FORMAT)}
            </span>
            <br />
            <span>{row.start_time}</span>
          </>
        ),
      },
      {
        name: 'End',
        selector: 'end',
        sortable: true,
        wrap: true,
        sortFunction: sortingEnd,
        cell: row => (
          <>
            <span className="start">
              {moment(row.end_date, 'DD/MM/YYYY').format(TABLE_DATE_FORMAT)}
            </span>
            <br />
            <span>{row.end_time}</span>
          </>
        ),
      },
      {
        name: 'Campus',
        selector: 'campus',
        sortable: false,
        cell: row => RenderCampus(row.campus).html,
      },
      {
        name: 'Room',
        selector: 'room',
        sortable: false,
        wrap: true,
        cell: row => <span className="room textLimit100">{row.room}</span>,
      },
      {
        name: 'Instructor',
        selector: 'instructor',
        sortable: false,
        cell: row => (
          <>
            {row.instructor_AMI_Id ? (
              <a
                href={`https://ami.myaie.ac/systemAdministration/userManagement/open/${row.instructor_AMI_Id}/aboutYou`}
              >
                {row.instructor}
              </a>
            ) : (
              <span
                className="laptops"
                style={
                  !row.instructor || row.instructor == ''
                    ? { backgroundColor: 'red', padding: '5px 30px' }
                    : {}
                }
              >
                {row.instructor}
              </span>
            )}
          </>
        ),
      },
      {
        name: 'Brand',
        selector: 'Brand',
        sortable: false,
        wrap: true,
        cell: row =>
          row.brand_color ? (
            RenderBrandListMultiLine(row.subsidiary).html
          ) : (
            <span className="brand textLimit50">{row.subsidiary}</span>
          ),
      },
      {
        name: 'Catering Setup',
        selector: 'catering',
        sortable: false,
        wrap: true,
        cell: row => RenderCateringSetup(row.catering_setup),
      },
      {
        name: 'IT Setup',
        selector: 'itsetup',
        sortable: false,
        wrap: true,
        cell: row => RenderITSetup(row.it_setup),
      },
      {
        name: 'Actions',
        selector: '',
        cell: row => (
          <div className="assessment-08 btn-dropdown-grp">
            <div className="as-buttons">
              <PermissionsGate
                scopes={['cbview']}
                RenderError={() => (
                  <button
                    className="btn btn-primary rounded-circle"
                    title="Open"
                    disabled
                  >
                    <i className="fal fa-folder-open"></i>
                  </button>
                )}
              >
                <Link
                  to={`/courseAdministration/bookedcourses/open/${row.id}`}
                  className="btn btn-primary rounded-circle"
                  title="Open"
                >
                  <i className="fal fa-folder-open"></i>
                </Link>
              </PermissionsGate>
            </div>
          </div>
        ),
      },
    ],
    [],
  )

  const exportData = () => {
    let data = renderCourseData()
    let header = [
      'Name',
      'State',
      'Final total',
      'Prelim total',
      'Laptop total',
      'Start date',
      'Start time',
      'End date',
      'End time',
      'Campus',
      'Room',
      'Instructor',
      'Brand',
      'Catering setup',
      'IT setup',
      'Learning method',
    ]
    Swal.fire({
      title: 'File downloading',
      onOpen: function () {
        Swal.showLoading()
      },
    })
    data = data.map(row => ({
      Name: row.name,
      State: row.state,
      'Final total': row.final_total,
      'Prelim total': row.prelim_total,
      'Laptop total': row.laptop_total,
      'Start date': row.start_date,
      'Start time': row.start_time,
      'End date': row.end_date,
      'End time': row.end_time,
      Campus: row.campus,
      Room: row.room,
      Instructor: row.instructor,
      Brand: row.subsidiary,
      'Catering setup': row.catering_setup,
      'IT setup': row.it_setup,
      'Learning method': row.learning_method,
    }))
    const pdfData = data.map(row => {
      return [
        row['Name'],
        row['State'],
        row['Final total'],
        row['Prelim total'],
        row['Laptop total'],
        row['Start date'],
        row['Start time'],
        row['End date'],
        row['End time'],
        row['Campus'],
        row['Instructor'],
        row['Brand'],
        row['Catering setup'],
        row['IT setup'],
        row['Learning method'],
      ]
    })
    Swal.close()
    return {
      header,
      data,
      pdfData,
      columnStyles: {},
    }
  }

  const renderCourseData = () => {
    let updatedData = []
    let allData = [...coursebookingList]
    if (courseSearch.length) {
      let name = allData.filter(item => {
        let includes = item.name
          ?.toString()
          ?.toLowerCase()
          ?.includes(courseSearch.toLowerCase())
        if (includes) {
          return includes
        } else return null
      })

      let data = [...name]
      let unique = [...new Set(data)]

      updatedData = unique
    } else {
      updatedData = allData
    }

    if (itSetup.arr.length) {
      let tempStatus = updatedData
      let tempResult = tempStatus.filter(item => {
        const startsWith = itSetup.arr.find(function (post, index) {
          if (
            item.it_setup && post.toLowerCase() == item.it_setup.toLowerCase()
              ? item.it_setup
              : ''
          )
            return item
        })
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempResult
    }
    if (statusFilter.arr.length) {
      let tempStatus = updatedData
      let tempResult = tempStatus.filter(item => {
        const startsWith = statusFilter.arr.find(function (post, index) {
          if (
            item.state && post.toLowerCase() == item.state.toLowerCase()
              ? item.state
              : ''
          )
            return item
        })
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempResult
    }
    if (brandFilter.arr.length) {
      let tempStatus = updatedData
      let tempResult = tempStatus.filter(item => {
        const startsWith = brandFilter.arr.find(function (post, index) {
          if (
            item.subsidiary &&
            post.toLowerCase() == item.subsidiary.toLowerCase()
              ? item.subsidiary
              : ''
          )
            return item
        })
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempResult
    }
    if (roomFilter.arr.length) {
      let tempStatus = updatedData
      let tempResult = tempStatus.filter(item => {
        const startsWith = roomFilter.arr.find(function (post, index) {
          if (
            item.room && post.toLowerCase() == item.room.toLowerCase()
              ? item.room
              : ''
          )
            return item
        })
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempResult
    }

    if (campusFilter.arr.length) {
      let tempStatus = updatedData
      let tempResult = tempStatus.filter(item => {
        const startsWith = campusFilter.arr.find(function (post, index) {
          if (
            item.campus.toLowerCase() &&
            post.toLowerCase() == item.campus.toLowerCase()
              ? item.campus.toLowerCase()
              : ''
          )
            return item
        })
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempResult
    }
    if (cateringFilter.arr.length) {
      let tempStatus = updatedData
      let tempResult = tempStatus.filter(item => {
        const startsWith = cateringFilter.arr.find(function (post, index) {
          if (
            item.catering_setup &&
            post.toLowerCase() == item.catering_setup.toLowerCase()
              ? item.catering_setup
              : ''
          )
            return item
        })
        if (startsWith) {
          return startsWith
        } else return null
      })
      updatedData = tempResult
    }

    if (
      courseSearch.length ||
      dateFilter.arr.length ||
      cateringFilter.arr.length ||
      itSetup.arr.length ||
      brandFilter.arr.length ||
      roomFilter.arr.length ||
      campusFilter.arr.length ||
      statusFilter.arr.length
    ) {
      const uniqueData = updatedData.filter(
        (value, index, self) =>
          index ==
          self.findIndex(
            t =>
              t.id == value.id &&
              t?.pmc_type == value?.pmc_type &&
              t?.type == value?.type,
          ),
      )

      return uniqueData
    } else {
      const uniqueData = allData.filter(
        (value, index, self) =>
          index ==
          self.findIndex(
            t =>
              t.id == value.id &&
              t?.pmc_type == value?.pmc_type &&
              t?.type == value?.type,
          ),
      )
      return uniqueData
    }
  }

  useEffect(() => {
    setdateFilter({
      arr: ['Next_7'],
      checkObj: {
        Next_7: true,
        assignedStatusNext_7: true,
      },
    })
  }, [])

  const columnsToShow = ['Action Type', 'Resource Type', 'Resource Name']

  const handleRedirect = (id, type = '', otherData = {}) => {
    switch (type) {
      case 'assesment':
        return `/courseAdministration/coursesdetails/${otherData?.course_id}/assessments/open/assigned/Details/${id}`

      case 'Course Gradebook Setting':
        return `/courseAdministration/coursesdetails/${id}/gradeSettings/show`

      case 'submission':
        return `/courseAdministration/coursesdetails/${otherData?.course_id}/assessments/open/assigned/submissionsAndResults/${id}`

      case 'notification':
        return `/courseAdministration/coursesdetails/${otherData?.course_id}/notifications/create/${id}`

      case 'assemblies':
        return `/courseAdministration/coursesdetails/${id}/assemblies/show`

      case 'onlineclass':
        return `/courseAdministration/coursesdetails/${id}/onlineClasses/show`

      default:
        return `/courseAdministration/coursesdetails/${id}/detailCourses/show`
    }
  }
  console.log('roomFilter', roomFilter,room)
  return (
    <>
      <Header></Header>
      <div className="content-wrapper d-flex flex-wrap">
        <SideBar></SideBar>
        <div className="sidebar-right">
          <div className="sub-menu-content-block">
            <div className="sub-menu-right-block">
              <div className="wrap-box-right">
                <div className="my-tickets-heading-blk flex-direction mb-2">
                  <div className="d-flex align-items-center ">
                    <div className="name-w-head d-flex align-items-center ">
                      <h4 className="text-left-align landing-heading heading_color_dynamic">
                        Course <span>Bookings</span>
                      </h4>
                    </div>
                    <div className="text-md-right action2-blk">
                      <div className="ticket-view-btn d-flex align-items-center">
                        <div className="add-new-ticket-bx">
                          <button
                            onClick={() => history.goBack()}
                            title="Back"
                            className="btn btn-white-bordered"
                          >
                            <i className="fal fa-angle-left"></i>Back
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tabs-wrap">
                  <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
                    <li
                      className="nav-item"
                      onClick={() => {
                        history.push('/courseAdministration/bookedcourses')
                      }}
                    >
                      <Link
                        className={`nav-link ${
                          tab == 'bookedcourses' ? 'active' : ''
                        }`}
                        // {`nav-link cursor-pointer active`}
                        id="pills-tab1"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="pills-tab12"
                        aria-selected="true"
                        to={`/courses/content/assemblies/table`}
                      >
                        <i className="fal fa-globe-africa"></i>Details
                      </Link>
                    </li>
                    <li
                      className="nav-item"
                      onClick={() => {
                        history.push('/courseAdministration/auditTrail')
                      }}
                    >
                      <Link
                        className={`nav-link cursor-pointer ${
                          tab == 'auditTrail' ? 'active' : ''
                        }`}
                        id="pills-tab2"
                        data-toggle="pill"
                        to={`/courseAdministration/auditTrail`}
                        role="tab"
                        aria-controls="pills-tab12"
                        aria-selected="true"
                      >
                        <i className="fal fa-history"></i>Audit trail
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {tab === 'auditTrail' && (
                      <AuditTrailsTable
                        apiFunction={getBookedCourseAuditTrail}
                        apiParams={{
                          parent_action_id: '',
                          // export : "false"
                        }}
                        columnsToShow={columnsToShow}
                        filterArrApi={getBookedCourseAuditFilters}
                        // actionId={id}
                        exportFileName={'Course_auditTrail'}
                        resourceNameRedirect={handleRedirect}
                        isResourceNameShown={true}
                        isShowingResourceTypeFilter
                        renderResourceTypeFunction={RenderCourseResourceType}
                      />
                    )}
                    {tab !== 'auditTrail' && (
                      <div
                        className="tab-pane fade active show"
                        id="pills-tab11"
                        role="tabpanel"
                        aria-labelledby="pills-tab1"
                      >
                        <>
                          <DataTableComponentFrontPagination
                            data={renderCourseData()}
                            columns={columns}
                            loading={loading}
                            search={courseSearch}
                            setSearch={setCourseSearch}
                            exportData={exportData}
                            exportFileName={'BookingList'}
                            hidePdf={true}
                            defaultSort={{
                              defaultSortColumn: 'start',
                              defaultSortAsc: false,
                            }}
                            filters={[
                              {
                                filterName: ' Date Range',
                                optionArr: courseDropdown.date,
                                state: dateFilter,
                                setState: setdateFilter,
                                // renderLabelFunction: RenderPMCStatus,
                                uniqueId: 'assignedStatus',
                                singleSelect: 'true',
                              },
                              {
                                filterName: ' Catering Setup',
                                optionArr: catering_setup,
                                state: cateringFilter,
                                setState: setCateringFilter,
                                // renderLabelFunction: RenderPMCStatus,
                                uniqueId: 'cateringSetup',
                              },
                              {
                                filterName: ' IT Setup',
                                optionArr: it_setup,
                                state: itSetup,
                                setState: setItSetup,
                                // renderLabelFunction: RenderBookingState,
                                uniqueId: 'itSetup',
                              },
                              {
                                filterName: ' Brand',
                                optionArr: brand || [],
                                state: brandFilter,
                                setState: setBrandFilter,
                                // renderLabelFunction: RenderPMCStatus,
                                uniqueId: 'brand',
                              },
                              {
                                filterName: ' Room',
                                optionArr: room || [],
                                state: roomFilter,
                                setState: setRoomFilter,
                                // renderLabelFunction: RenderBookingState,
                                uniqueId: 'room',
                              },
                              {
                                filterName: 'Campus',
                                optionArr: campus || [],
                                state: campusFilter,
                                // renderLabelFunction: RenderBookingState,
                                setState: setCampusFilter,
                                // uniqueId: "campus",
                              },
                              {
                                filterName: ' Status',
                                optionArr: status || [],
                                state: statusFilter,
                                setState: setStatusFilter,
                                // renderLabelFunction: RenderBookingState,
                                uniqueId: 'status',
                              },
                            ]}
                          />
                        </>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BookedCourses
