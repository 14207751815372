import React, { useEffect } from 'react'
import { useFormik } from 'formik';
export const GradMixAssesmentTotalChange = ({ stdIds,updateSingleGrademix,twoDecimalRound,item,isHybrid,percentageView,option,two,headerData,stdId,handleGetAppealDetails,handleGetComment,handleUpdateMarks,handleMouseUp,PermissionsGate,handleMouseDown ,options}) => {
    console.log('options------->',options)
    const formik = useFormik({
        initialValues: {
            grademix:[]
        },
        
        onSubmit: (values) => {
            console.log(values);
        },
    });
console.log('item', item)
    useEffect(() => {

        const grademix = item?.grademixArr?.map((data) => {
console.log('data', data?.grading_type)
            return {
                grade: '',
                grade_type: data?.grading_type,
                grademix_id: data?.grade_id,
                student_id: item?.student_id
            }
        })
        formik.setFieldValue('grademix', grademix)
    }, [item])
    return (
        item?.grademixArr?.map((ass, index) => {
            return (

                <td
                    key={index}
                    className={
                        ass?.grant_exemption
                            ? "exempted-td remove-padding"
                            : "remove-padding"
                    }
                    style={{ backgroundColor: item?.assTotaleMarkUpdate == 1 ? '#fef4e8' : undefined }}
                >
                    {/* {console.log('************************', item?.assTotaleMarkUpdate)} */}
                    <div className={`td-vertical-space-issue ${index == 0 ? 'px-2' : ''} pr-3`}>
                        {ass?.grant_exemption ? (
                            <>
                                <div
                                    className="as-widget-text grade-info-plus mt-2 curser"
                                    title="Remove from Exemption"
                                // onClick={() =>
                                //   handleNavigation(null, "exempted", [ass?.assessment_id,
                                //     item?.student_id,
                                //     ass?.grant_exemption ? 0 : 1])
                                //   // handleExemption(
                                //   //   ass?.assessment_id,
                                //   //   item?.student_id,
                                //   //   ass?.grant_exemption ? 0 : 1
                                //   // )
                                // }
                                >
                                    {ass?.grant_exemption ? (
                                        <i className="fal fa-user-plus"></i>
                                    ) : (
                                        <i className="fal fa-user-plus"></i>
                                    )}
                                    <div
                                        title="Exempted"
                                        className="exempted-text"
                                    >
                                        Exempted{" "}
                                        <i className="fal fa-info-circle"></i>
                                    </div>
                                </div>
                            </>
                        ) : ass.pass_status == "N/A" ? (
                            <div>
                                <span>N/A</span>
                            </div>
                        ) : (
                                    <tr className="smart-grademix-content">
                                <>
                                    {isHybrid ? <>
                                        <td style={{ backgroundColor: ass?.assTotaleMarkUpdate === 1 ? '#fef4e8' : undefined }}>
                                            <div className="d-flex">
                                                <div className="marks-number-info">
                                                    <div className="marks-input-text">
                                                        {percentageView ? (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <input
                                                                        className={ass.hybrid_percentage == "0" ? "input-red" : ""}
                                                                        type="number"
                                                                        {...formik.getFieldProps(`grademix.${index}.grade`)}
                                                                    />{" "}
                                                                    %
                                                                </div>
                                                            </div>
                                                        ) : ass.grading_type === 0 ? (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <input
                                                                        className={ass.hybrid_percentage == "0" ? "input-red" : ""}
                                                                        type="number"
                                                                        {...formik.getFieldProps(`grademix.${index}.grade`)}
                                                                                    
                                                                    />
                                                                    <span className="ml-1">%</span>
                                                                </div>
                                                            </div>
                                                        ) : ass.grading_type === 1 ? (
                                                            <div className="form-icon-group marks-input-text">
                                                                <select
                                                                    className={"mid-scroll-custom-table-select " + (ass.hybrid_percentage == "0" ? "percentage-select-red" : "")}
                                                                        defaultValue={((!ass.hybrid_percentage || ass.hybrid_percentage == 0) && ass.submited == "0") || ass.hybrid_percentage == null ? "" : ass?.hybrid_percentage == 0 ? "0" : (ass?.hybrid_percentage > 0 && ass?.hybrid_percentage <= 49) ? "49" : "100"}
                                                                        disabled
                                                                >
                                                                    {options?.length ? options.map((opt, index) => (
                                                                        <option key={index} value={opt.value}>{opt.label}</option>
                                                                    ))
                                                                        : ""}
                                                                </select>
                                                            </div>
                                                        ) : ass.grading_type === 2 ? (
                                                            <div className="form-icon-group marks-input-text">
                                                                <select
                                                                    className={"mid-scroll-custom-table-select " + (ass.hybrid_percentage == "0" ? "percentage-select-red" : "")}
                                                                    defaultValue={(ass.hybrid_percentage == 0 && ass.submited == "0") || ass.hybrid_percentage == null ? "" : ass?.hybrid_assCalculateGradeStatus?.grade}
                                                                    disabled
                                                                >
                                                                    {ass?.letterGrades && ass?.letterGrades?.length
                                                                        ? ass?.letterGrades.map((opt, index) => (
                                                                            <option key={index} value={opt.value}>
                                                                                {opt.label}
                                                                            </option>
                                                                        )
                                                                        )
                                                                        : ""}
                                                                </select>
                                                            </div>
                                                        ) : (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <input
                                                                        className={ass.ass_hybrid_marks == "0" ? "input-red" : ""}
                                                                        type="number"
                                                                        disabled
                                                                        defaultValue={(ass.ass_hybrid_marks == 0 && ass.submited == "0") || ass.ass_hybrid_marks == null ? "" : twoDecimalRound(ass.ass_hybrid_marks)}
                                                                    />
                                                                    <span className="ml-1 min-w-40">
                                                                        / {ass.ass_hybrid_total_marks}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td style={{ backgroundColor: ass?.assTotaleMarkUpdate === 1 ? '#fef4e8' : undefined }}>
                                            <div className="d-flex">
                                                <div className="marks-number-info">
                                                    <div className="marks-input-text">
                                                        {percentageView ? (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <input
                                                                        className={ass.normal_percentage == "0" ? "input-red" : ""}
                                                                        type="number"
                                                                        value={ass.normal_percentage == 0 && ass.submited == "0" ? "" : ass.normal_percentage}
                                                                        disabled
                                                                    />{" "}
                                                                    %
                                                                </div>
                                                            </div>
                                                        ) : ass.grading_type === 0 ? (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <input
                                                                        className={ass.normal_percentage == "0" ? "input-red" : ""}
                                                                        type="number"
                                                                        defaultValue={ass.normal_percentage == 0 && ass.submited == "0" ? "" : ass.normal_percentage}
                                                                        disabled
                                                                    />
                                                                    {<span className="ml-1">%</span>}
                                                                </div>
                                                            </div>
                                                        ) : ass.grading_type === 1 ? (
                                                            <div className="form-icon-group marks-input-text">
                                                                <select
                                                                    className={"mid-scroll-custom-table-select " + (ass.normal_percentage == "0" ? "percentage-select-red" : "")}
                                                                    defaultValue={(!ass.normal_percentage || ass.normal_percentage == 0) && ass.submited == "0" ? ""
                                                                        : ass?.normal_percentage == 0 ? "0"
                                                                            : ass?.normal_percentage > 0 && ass?.normal_percentage <= 49
                                                                                ? "49" : "100"
                                                                    }
                                                                    disabled
                                                                >
                                                                    {options?.length ? options.map((opt, index) => (
                                                                        <option key={index} value={opt.value}>
                                                                            {opt.label}
                                                                        </option>
                                                                    ))
                                                                        : ""}
                                                                </select>
                                                            </div>
                                                        ) : ass.grading_type === 2 ? (
                                                            <div className="form-icon-group marks-input-text">
                                                                <select
                                                                    className={"mid-scroll-custom-table-select " + (ass.normal_percentage == "0" ? "percentage-select-red" : "")}
                                                                    defaultValue={ass.normal_percentage == 0 && ass.submited == "0" ? "" : ass?.normal_assCalculateGradeStatus?.grade}
                                                                    disabled
                                                                >
                                                                        {ass?.letterGrades && ass?.letterGrades?.length ?
                                                                            ass?.letterGrades.map((opt, index) => (
                                                                                <option key={index} value={opt.value}>
                                                                                    {opt.label}
                                                                                </option>
                                                                            ))
                                                                            : ""}
                                                                </select>
                                                            </div>
                                                        ) : (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <input
                                                                        className={ass.ass_normal_marks == "0" ? "input-red" : ""}
                                                                        type="number"
                                                                        disabled
                                                                        defaultValue={ass.normal_percentage == 0 && ass.submited == "0" ? "" : twoDecimalRound(ass.ass_normal_marks)}
                                                                    />
                                                                    <span className="ml-1 min-w-40">
                                                                        / {ass.ass_normal_total_marks}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </>
                                        :
                                        <td style={{ backgroundColor: ass?.assTotaleMarkUpdate === 1 ? '#fef4e8' : undefined }}>
                                            <div className="d-flex">
                                                <div className="marks-number-info">
                                                            <div className="marks-input-text">
                                                                {console.log("percentageViewpercentageView", percentageView, ass.grading_type )}
                                                        {percentageView ? (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <input
                                                                        className={
                                                                            ass.ass_percentage ==
                                                                                "0"
                                                                                ? "input-red"
                                                                                : ""
                                                                        }
                                                                        type="number"
                                                                        value={ass.ass_percentage == 0 && ass.submited == "0" ? "" : ass.ass_percentage}
                                                                        disabled
                                                                    />{" "}
                                                                    %
                                                                </div>
                                                            </div>
                                                        ) : ass.grading_type === 0 ? (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <PermissionsGate
                                                                        scopes={[((headerData &&
                                                                            headerData?.quiz_id) ||
                                                                            isHybrid === true) ? "overidersmart" : "cagbedit"]}
                                                                        errorProps={{
                                                                            disabled: true,
                                                                        }}
                                                                    >
                                                                        <input
                                                                            className={
                                                                                ass.ass_percentage ==
                                                                                    "0"
                                                                                    ? "input-red"
                                                                                    : ""
                                                                            }
                                                                            type="number"
                                                                            // title={
                                                                            //   ass.ass_percentage
                                                                            // }
                                                                            defaultValue={
                                                                                ass.ass_percentage == 0 && ass.submited == "0" ? "" : ass.ass_percentage
                                                                            }
                                                                            onBlur={(e) => {
                                                                                stdIds.push(
                                                                                    item.quiz_student_id
                                                                                );
                                                                                handleUpdateMarks(
                                                                                    e,
                                                                                    item.quiz_student_id,
                                                                                    "inputbox",
                                                                                    ass.ass_total_mark,
                                                                                    ass
                                                                                )
                                                                            }}
                                                                            disabled={
                                                                                ass.lock_grades ==
                                                                                "1"
                                                                            }
                                                                            onMouseUp={handleMouseUp}
                                                                            onMouseDown={handleMouseDown}
                                                                        />
                                                                    </PermissionsGate>
                                                                    {
                                                                        <span className="ml-1">
                                                                            %
                                                                        </span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        ) : ass.grading_type === 1 ? (
                                                            <div className="form-icon-group marks-input-text">
                                                                <PermissionsGate
                                                                    scopes={[((headerData &&
                                                                        headerData?.quiz_id) ||
                                                                        isHybrid === true) ? "overidersmart" : "cagbedit"]}
                                                                    errorProps={{
                                                                        disabled: true,
                                                                    }}
                                                                >
                                                                    <select
                                                                        className={
                                                                            "mid-scroll-custom-table-select " +
                                                                            (ass.ass_percentage ==
                                                                                "0"
                                                                                ? "percentage-select-red"
                                                                                : "")
                                                                        }
                                                                        defaultValue={
                                                                            ass.ass_percentage == 0 && ass.submited == "0"
                                                                                ? ""
                                                                                : ass?.grade == 0
                                                                                    ? "0"
                                                                                    : ass?.grade > 0 &&
                                                                                        ass?.grade <= 49
                                                                                        ? "49"
                                                                                        : "100"
                                                                        }
                                                                        disabled={
                                                                            ass.lock_grades ==
                                                                            "1"
                                                                        }
                                                                        onChange={(e) => {
                                                                            stdIds.push(
                                                                                item.quiz_student_id
                                                                            );
                                                                            handleUpdateMarks(
                                                                                e,
                                                                                item.quiz_student_id,
                                                                                "selectbox",
                                                                                ass.ass_total_mark,
                                                                                ass
                                                                            )
                                                                        }}
                                                                        onMouseUp={handleMouseUp}
                                                                        onMouseDown={handleMouseDown}
                                                                    >
                                                                        {options?.length
                                                                            ? options.map(
                                                                                (opt, index) => (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={
                                                                                            opt.value
                                                                                        }
                                                                                    >
                                                                                        {opt.label}
                                                                                    </option>
                                                                                )
                                                                            )
                                                                            : ""}
                                                                    </select>
                                                                </PermissionsGate>
                                                            </div>
                                                        ) : ass.grading_type === 2 ? (
                                                            <div className="form-icon-group marks-input-text">
                                                                <PermissionsGate
                                                                    scopes={[((headerData &&
                                                                        headerData?.quiz_id) ||
                                                                        isHybrid === true) ? "overidersmart" : "cagbedit"]}
                                                                    errorProps={{
                                                                        disabled: true,
                                                                    }}
                                                                >
                                                                    <select
                                                                        className={
                                                                            "mid-scroll-custom-table-select " +
                                                                            (ass.ass_percentage ==
                                                                                "0"
                                                                                ? "percentage-select-red"
                                                                                : "")
                                                                        }
                                                                        defaultValue={ass.ass_percentage == 0 && ass.submited == "0" ? "" : ass?.grade}
                                                                        disabled={
                                                                            ass.lock_grades ==
                                                                            "1"
                                                                        }
                                                                        onChange={(e) => {
                                                                            stdIds.push(
                                                                                item.quiz_student_id
                                                                            );
                                                                            handleUpdateMarks(
                                                                                e,
                                                                                item.quiz_student_id,
                                                                                "selectbox",
                                                                                ass.ass_total_mark,
                                                                                ass
                                                                            )
                                                                        }}
                                                                        onMouseUp={handleMouseUp}
                                                                        onMouseDown={handleMouseDown}
                                                                    >
                                                                        {ass?.letterGrades &&
                                                                            ass?.letterGrades?.length
                                                                            ? ass?.letterGrades.map(
                                                                                (opt, index) => (
                                                                                    <option
                                                                                        key={index}
                                                                                        value={
                                                                                            opt.value
                                                                                        }
                                                                                    >
                                                                                        {opt.label}
                                                                                    </option>
                                                                                )
                                                                            )
                                                                            : ""}
                                                                    </select>
                                                                </PermissionsGate>
                                                            </div>
                                                        ) : (
                                                            <div className="resultstatus_flex">
                                                                <div className="form-icon-group status-select-form">
                                                                    <PermissionsGate
                                                                        scopes={[((headerData &&
                                                                            headerData?.quiz_id) ||
                                                                            isHybrid === true) ? "overidersmart" : "cagbedit"]}
                                                                        errorProps={{
                                                                            disabled: true,
                                                                        }}
                                                                    >
                                                                        <input
                                                                            className={
                                                                                ass.ass_mark == "0"
                                                                                    ? "input-red"
                                                                                    : ""
                                                                            }
                                                                            type="number"
                                                                            disabled={
                                                                                ass.lock_grades ==
                                                                                "1"
                                                                            }
                                                                            defaultValue={
                                                                                ass.ass_percentage == 0 && ass.submited == "0" ? "" : ass.ass_mark
                                                                            }
                                                                            onBlur={(e) => {
                                                                                stdIds.push(
                                                                                    item.quiz_student_id
                                                                                );
                                                                                handleUpdateMarks(
                                                                                    e,
                                                                                    item.quiz_student_id,
                                                                                    "inputbox",
                                                                                    ass.ass_total_mark,
                                                                                    ass
                                                                                )
                                                                            }}
                                                                            onMouseUp={handleMouseUp}
                                                                            onMouseDown={handleMouseDown}
                                                                        />
                                                                    </PermissionsGate>
                                                                    <span className="ml-1 min-w-40">
                                                                        / {ass.ass_total_mark}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                                </td>}
                                            {console.log('assass?.assTotaleMarkUpdate == 1 ', ass?.assTotaleMarkUpdate == 1)}
                                            <td style={{ backgroundColor: ass?.assTotaleMarkUpdate === 1 ? '#fef4e8' : undefined }}
>
                                        {isHybrid == true ? ((ass?.mark && item?.showValue) ?
                                            <p className="m-0">
                                                {ass?.grading_type === 0 ? `${(ass?.gradeText)}`
                                                    : ass?.grading_type === 3 ? `${(ass?.gradeText)}/${ass?.ass_total_mark}`
                                                        : ass.gradeText}
                                            </p>
                                            : headerData?.notify_assessed == 1 ?
                                                ass?.grading_type === 3 ? `${ass?.gradeText}/${ass?.ass_total_mark}`
                                                    : ass.gradeText : "N/A") :
                                            (((ass?.mark && ass?.submited > 0) || headerData?.notify_assessed == 1) ? <p className="m-0">
                                                {ass?.grading_type === 0 ? `${(ass?.gradeText)}`
                                                    : ass?.grading_type === 3 ? `${(ass?.gradeText)}/${ass?.ass_total_mark}`
                                                        : ass?.gradeText}
                                            </p>
                                                        : "N/A")}
                                               
                                            </td>
                                            <td>
                                                {ass?.assTotaleMarkUpdate == 1 && (
                                                    <i
                                                        className="fa fa-info-circle"
                                                        title="Total has been changed, please update your marks"
                                                        style={{ marginLeft: "5px", cursor: "pointer" }}
                                                    ></i>

                                                )}</td>
                                </>
                            </tr>
                        )}
                    </div>
                </td>
            );
        })
    )
}
