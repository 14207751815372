/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { Link, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectTab, setSideBarLinks } from "../../store/actions/index";
import { useLocation } from "react-router-dom";
import { getMenuItem } from "./Helper";
import PermissionsGate from "../../utils/permissionGate";
import hasPermission from "../../utils/hasMultiplePermission";
import { GlobalIconNames } from "../../utils/GlobalIconNames";

const SideBar = () => {
  
  const { pathname } = useLocation();
  const { sideTab, page } = useParams();
  let currentTabValue = pathname.split("/");
  const user = useSelector((state) => state.user);
  const givenPermsisions = useSelector((state) => state.givenPermission);
  const dispatch = useDispatch();
  const examReg = localStorage.getItem("examReg");
  const reReg = localStorage.getItem("reReg");
  const [subMenuStudentAdministration, setSubMenuStudentAdministration] = useState(
    localStorage.getItem("iCSAS") == "true" ||
    localStorage.getItem("iCSSR") == "true" ||
    localStorage.getItem("iCSBE") == "true" ||
    localStorage.getItem("iCSPR") == "true" ||
    localStorage.getItem("iSEES") == "true" ||
    localStorage.getItem("iSEE") == "true" ||
    localStorage.getItem("iSEEP") == "true" ||
    localStorage.getItem("iSEPP") == "true" ||
    localStorage.getItem("iSEEC") == "true" ? 'active-li' : ''
  );
  
  const [subMenuStudentSupport, setSubMenuStudentSupport] = useState(
    localStorage.getItem("iTFS") == "true" ||
    localStorage.getItem("iNTFS") == "true" ||
    localStorage.getItem("iSHFS") == "true" ||
    localStorage.getItem("iLCFS") == "true" ||
    localStorage.getItem("iALFS") == "true" ||
    localStorage.getItem("iBBFS") == "true" ||
    localStorage.getItem("iBNFS") == "true" ||
    localStorage.getItem("iNLFS") == "true" ||
    localStorage.getItem("iSHLFS") == "true" ||
    localStorage.getItem("iLBLFS") == "true" ||
    localStorage.getItem("iSHMLFS") == "true" ? 'active-li' : ''
  );
  
  const [subMenuCourse, setSubMenuCourse] = useState(
    localStorage.getItem("iCFS") == "true" ||
    localStorage.getItem("iCPP") == "true" ||
    localStorage.getItem("iCCA") == "true" ||
    localStorage.getItem("iQBFS") == "true" ||
    localStorage.getItem("iCSPR") == "true" ? 'active-li' : '')
    
    const [subMenuProgramManagement, setSubMenuProgramManagement] = useState(
      localStorage.getItem("iPMCPFS") == "true" ||
      localStorage.getItem("iPMCMFS") == "true" ||
      localStorage.getItem("iPMCFB") == "true" ? 'active-li' : '');
      
      const [subMenuSystemAdministration, setSubMenuSystemAdministration] = useState(
        localStorage.getItem("iBFS") == "true" ||
        localStorage.getItem("iUMFS") == "true" ||
        localStorage.getItem("iPMFS") == "true" ||
        localStorage.getItem("iCRFS") == "true" ||
        localStorage.getItem("iSMDFS") == "true" ? 'active-li' : '');
        
        const [subMenuCampusAdministration, setSubMenuCampusAdministration] = useState(
          localStorage.getItem("iCampAdmin") == "true" ||
          localStorage.getItem("iSchedM") == "true" ||
          localStorage.getItem("iSmartSign") == "true" ||
          localStorage.getItem("iBookM") == "true"  ? 'active-li' : ''
        )
        const [subMenuWebsiteManagement,setSubMenuWebsiteManagement] = useState()
        
        
        window.onload = function () {
          setSubMenuStudentAdministration("")
          setSubMenuStudentSupport("")
          setSubMenuCourse("")
          setSubMenuProgramManagement("")
          setSubMenuSystemAdministration("")
          setSubMenuCampusAdministration("")
          setSubMenuWebsiteManagement("")
        };
        
        var Tabs = [
          {
            name: "Home",
            class: "fa-home",
            refLink: "home",
            id: 1,
          },
          {
            name: "My Profile",
            class: "fa-user",
            refLink: "profile",
            id: 2,
          },
          {
            name: "Course Administration",
            class: "fa-books",
            refLink:
            currentTabValue.includes("courseAdministration") &&
            !currentTabValue.includes("Programmes")
            ? pathname
            : hasPermission({
              scopes: ["capview"],
              permissions: givenPermsisions,
            })
            ? "courseAdministration/Programmes/programme/show"
            : "courseAdministration/courses/list",
            id: 3,
          },
          {
            name: "Student Support",
            class: "fa-user-headset",
            refLink: "support",
            id: 12,
          },
          {
            name: "Program Management Center",
            class: "fa-window",
            refLink: "program-management-center/programmes/programme/table",
            id: 5,
          },
          {
            name: "Student Administration",
            class: "fa-graduation-cap",
            refLink: hasPermission({
              scopes: ["sview"],
              permissions: givenPermsisions,
            })
            ? "studentAdministration/students/table"
            : "studentadministration/registration/studentlist",
            id: 20,
          },
          {
            name: "Campus Administration",
            class: "fa-building",
            refLink: "administration/campuses/table",
            id: 22,
          },
          {
            name: "System Administration",
            class: "fa-cog",
            refLink: "systemAdministration/userManagement/table",
            id: 4,
          },
          {
            name: "Websites Management",
            class: "fa-globe",
            refLink: "website_management",
            permissions: ["wbsview"],
            id: 7
          }
        ];
        
        useEffect(() => {
          dispatch(setSideBarLinks(getLinkNameAndPath()));
        }, []);
        
        function checkPermissions(arr = []) {
          return hasPermission({ scopes: arr, permissions: givenPermsisions });
        }
        
        /** Show submenu according to main menu */
        const showSubMenu = (active) => {
          if (active === "studentAdministration") setSubMenuStudentAdministration("active-li")
            if (active === "supportAdministration") setSubMenuStudentSupport("active-li")
              if (active === "courseAdministration") setSubMenuCourse("active-li")
                if (active === "programManagement") setSubMenuProgramManagement("active-li")
                  if (active === "systemAdministration") setSubMenuSystemAdministration("active-li")
                    if (active === "campusAdministration") setSubMenuCampusAdministration("active-li")
                      if (active === "websiteManagement") setSubMenuWebsiteManagement("active-li")
                      }
        
        /** Hide submenu according to main menu */
        const hideSubMenu = (inactive) => {
          if (inactive === "studentAdministration") {
            setSubMenuStudentAdministration("");
            localStorage.setItem("iCSAS", false)
            localStorage.setItem("iCSSR", false)
            localStorage.setItem("iCSBE", false)
            localStorage.setItem("iCSPR", false)
            localStorage.setItem("iSEES", false)
            localStorage.setItem("iSEE", false)
            localStorage.setItem("iSEPP", false)
            localStorage.setItem("iSEEP", false)
            localStorage.setItem("iSEEC", false)
          }
          if (inactive === "supportAdministration") {
            setSubMenuStudentSupport("")
            localStorage.setItem("iTFS", false)
            localStorage.setItem("iNTFS", false)
            localStorage.setItem("iSHFS", false)
            localStorage.setItem("iLCFS", false)
            localStorage.setItem("iALFS", false)
            localStorage.setItem("iBBFS", false)
            localStorage.setItem("iBNFS", false)
            localStorage.setItem("iNLFS", false)
            localStorage.setItem("iSHLFS", false)
            localStorage.setItem("iLBLFS", false)
            localStorage.setItem("iSHMLFS", false)
          }
          if (inactive === "courseAdministration") {
            setSubMenuCourse("")
            localStorage.setItem("iCFS", false)
            localStorage.setItem("iCPP", false)
            localStorage.setItem("iCCA", false)
            localStorage.setItem("iQBFS", false)
            localStorage.setItem("iAFS", false)
          }
          if (inactive === "programManagement") {
            setSubMenuProgramManagement("");
            localStorage.setItem("iPMCPFS", false)
            localStorage.setItem("iPMCMFS", false)
            localStorage.setItem("iPMCFB", false)
          }
          if (inactive === "systemAdministration") {
            setSubMenuSystemAdministration("")
            localStorage.setItem("iBFS", false)
            localStorage.setItem("iUMFS", false)
            localStorage.setItem("iPMFS", false)
            localStorage.setItem("iCRFS", false)
            localStorage.setItem("iSMDFS", false)
          }
          if(inactive === "websiteManagement") {
            setSubMenuWebsiteManagement("")
          }
          
          if (inactive === "campusAdministration") {
            setSubMenuCampusAdministration("")
            localStorage.setItem("iCampAdmin", false)
            localStorage.setItem("iSchedM", false)
            localStorage.setItem("iBookM", false)
            localStorage.setItem("iSmartSign", false)
          }
        }
        
        function getLinkNameAndPath() {
          return [
            {
              pageName: "course_administration",
              options: [
                {
                  name: "Programmes",
                  path: "/courseAdministration/Programmes/programme/show",
                  icon: GlobalIconNames("Programmes").className,
                  permissions: ["capview"],
                  description: "Access and manage academic programmes.",
                },
                {
                  name: "Courses",
                  path: "/courseAdministration/courses/In Progress",
                  icon: GlobalIconNames("Courses").className,
                  permissions: ["cview"],
                  description: "Access and manage various courses.",
                },
                {
                  name: "Booked Courses",
                  path: "/courseAdministration/bookedcourses",
                  icon: GlobalIconNames("BookedCourses").className,
                  permissions: ["cbview"],
                  description: "Access and manage various SLP course instances.",
                },
                {
                  name: "Content Assemblies",
                  path: "/courses/content/assemblies/table",
                  icon: GlobalIconNames("contentassemblies").className,
                  permissions: ["caview"],
                  description: "Manage and update learning material.",
                },
                {
                  name: "Quizzes",
                  icon: GlobalIconNames("quizzes").className,
                  permissions: ["cquestionbview", "cquizmview"],
                  subPageName: "quizzes",
                  description:
                  "Create and manage quizzes and questions for your course assessments.",
                  subTabs: [
                    {
                      name: "Quiz Management",
                      path: "/courseAdministration/assessment/quizzes/list",
                      icon: GlobalIconNames("quizmanagement").className,
                      permissions: ["cquizmview"],
                      description: "Manage and create quizzes.",
                    },
                    {
                      name: "Question Bank",
                      path: "/courseAdministration/smartAssessment/questionBank",
                      icon: GlobalIconNames("questionbank").className,
                      permissions: ["cquestionbview"],
                      description: "Manage and create questions for quizzes.",
                    },
                  ],
                },
                {
                  name: "Gradebook Templates",
                  path: "/courseAdministration/gradingTemplate/table",
                  icon: GlobalIconNames("gradingLetterTemplates").className,
                  permissions: ["gradingLetterTemplateView"],
                  description: "Access and manage grading letter templates or Statement of Result templates.",
                },
                {
                  name: "Intake Copy",
                  path: "/courseAdministration/intake-qualification/list-copied-intake",
                  icon: GlobalIconNames("intakecopy").className,
                  permissions: ["intakeCopyView"],
                  description:
                  "Copy over an intakes programmes/courses to another intake.",
                },
                {
                  name: "Programme Articulation",
                  path: "/courseAdministration/articulation/list-articulations",
                  icon: GlobalIconNames("programmearticulation").className,
                  permissions: ["cparview"],
                  description: "Manage and process programme articulations.",
                },
                {
                  name: "Programme Re-Registration",
                  path: "/courseAdministration/registration/list-re-registration",
                  icon: GlobalIconNames("programmereregistration").className,
                  permissions: ["prrview"],
                  description: "Manage and process all re-registrations received.",
                },
                {
                  name: "Certificate Templates",
                  path: "/courseAdministration/certificates/list",
                  icon: GlobalIconNames("certificatetemplate").className,
                  permissions: ["ccertview"],
                  description: "Create and manage certificate templates.",
                },
              ],
            },
            {
              pageName: "student_support",
              options: [
                {
                  name: "Tickets",
                  path: "/support/TotalDangerTicket",
                  icon: "fal fa-ticket-alt",
                  permissions: ["tview"],
                  description: "View, respond, and manage student requests.",
                },
                {
                  name: "Student Hub & Library",
                  icon: GlobalIconNames("studenthublibrary").className,
                  permissions: ["sresourcemview", "slibraryborrowersview", "shview"],
                  subPageName: "student_hub_&_library",
                  description: "Manage and update resources for students.",
                  subTabs: [
                    {
                      name: "Resource Management",
                      permissions: ["sresourcemview"],
                      path: "/support/student-hub-library/resource-management/table",
                      icon: GlobalIconNames("resourcemanagement").className,
                      description: "Manage, track and maintain library resources.",
                    },
                    {
                      name: "Library Borrowers",
                      permissions: ["slibraryborrowersview"],
                      path: "/support/student-hub-library/library-borrowers/table",
                      icon: GlobalIconNames("libraryborrowers").className,
                      description: "Manage and track resources borrowed.",
                    },
                    {
                      name: "Student Hub Management",
                      permissions: ["shview"],
                      path: "/support/student-hub-library/student-hub/list",
                      icon: GlobalIconNames("libraryborrowers").className,
                      description:
                      "Manage the Student Portal Student Hub Tabs and resources.",
                    },
                  ],
                },
                {
                  name: "Live Chat",
                  path: "/support/live-chat",
                  icon: GlobalIconNames("livechat").className,
                  permissions: ["sslciew"],
                  description: "Access and manage student inquiries in real-time",
                },
                {
                  name: "Appeals",
                  path: "/supportAdministration/appeals/appeal/pending",
                  icon: GlobalIconNames("Appeals").className,
                  permissions: ["ssaview"],
                  description: "Access and manage student appeals.",
                },
                {
                  name: "Banners",
                  path: "/support/studentBanners/table",
                  icon: GlobalIconNames("banners").className,
                  permissions: ["ssbview"],
                  description: "Manage and update banner announcements for students.",
                },
                {
                  name: "Notifications",
                  icon: GlobalIconNames("bulknotificationsgroup").className,
                  permissions: [
                    "bnbnview",
                    "bnnlview",
                    "bnpeview",
                    "bnseview",
                    "bnntview",
                  ],
                  subPageName: "bulk_notifications",
                  description: "Manage and send notifications to students.",
                  subTabs: [
                    {
                      name: "Bulk Notifications",
                      path: "/notification/bulknotification/bulk/table",
                      icon: GlobalIconNames("bulknotifications").className,
                      permissions: ["bnbnview"],
                      description:
                      "Efficiently communicate important announcements and updates to students and groups.",
                    },
                    {
                      name: "Individual Notifications",
                      path: "/notification/bulknotification/pending-notifications/table",
                      icon: GlobalIconNames("notificationlists").className,
                      permissions: ["bnnlview"],
                      description: "Track all bulk notifications sent to students.",
                    },
                    // { name: "Pending Notifications", path: "/notification/bulknotification/pending-notifications/table", icon: GlobalIconNames("pendingnotification").className, permissions: ["bnpeview"], description:"Track and manage all notifications yet to be delivered to students." },
                    // { name: "Stopped Notifications", path: "/notification/bulknotification/stopped-notifications/table", icon: GlobalIconNames("stoppednotifications").className, permissions: ["bnseview"], description:"Track and view all notifications that have been cancelled." },
                    {
                      name: "Notification Templates",
                      path: "/notification/bulknotification/notifications-template/table",
                      icon: GlobalIconNames("notificationtemplates").className,
                      permissions: ["bnntview"],
                      description:
                      "Create and manage various notification templates.",
                    },
                  ],
                },
              ],
            },
            {
              pageName: "program_management_center",
              options: [
                {
                  name: "Programmes & Courses",
                  path: "/program-management-center/programmes/programme/table",
                  icon: GlobalIconNames("Programmes").className,
                  permissions: ["pmcpview"],
                  description: ["Manage outlines and creation for programmes & courses."],
                },
                {
                  name: "Modules",
                  path: "/program-management-center/modules/module/table",
                  icon: GlobalIconNames("Modules").className,
                  permissions: ["pmcmview"],
                  description: ["Manage module outlines and creation."],
                },
                {
                  name: "Features & Benefits",
                  path: "/program-management-center/features/benefits/table",
                  icon: GlobalIconNames("featuresandbenefits").className,
                  permissions: ["pmcfandbview"],
                  description: ["Manage features and benefits."],
                },
                {
                  name: "Resource Management",
                  permissions: ["sresourcemview"],
                  path: "/support/student-hub-library/resource-management/table",
                  icon: GlobalIconNames("resourcemanagement").className,
                  description: "Manage, track and maintain library resources.",
                },
              ],
            },
            {
              pageName: "student_administration",
              options: [
                {
                  name: "Students",
                  path: "/studentAdministration/students/table",
                  icon: GlobalIconNames("Students").className,
                  permissions: ["sview"],
                  description: "Manage, track and maintain student records.",
                },
                {
                  name: "Student Registrations",
                  path: "/studentadministration/registration/studentlist",
                  icon: GlobalIconNames("registration").className,
                  permissions: ["regview"],
                  description: "Manage and process all registrations received.",
                },
                // {
                //   name: "Registrations", icon: GlobalIconNames("studentregistration").className, permissions: ["regview", "ebeview", "prrview"], subPageName: "registrations", description:"Manage Registrations, Re-Registrations and E-Book Exports.", subTabs: [
                //     { name: "Student Registrations", path: "/studentadministration/registration/studentlist", icon: GlobalIconNames("registration").className, permissions: ["regview"], description:"Manage and process all registrations received." },
                //     // { name: "E-Books Export", path: "/studentadministration/registration/e-book", icon: GlobalIconNames("ebooksexport").className, permissions: ["ebeview"], description:"Send students their digital course materials." }
                //   ]
                // },
                {
                  name: "External Exam Registrations",
                  icon: GlobalIconNames("externalexam").className,
                  permissions: ["eeepview", "eerleview", "eeecview", "eeesview"],
                  description:
                  "Manage, maintain and process external exam registrations",
                  subPageName: "external-exam",
                  subTabs: [
                    {
                      name: "Exam Period",
                      path: "/studentAdministration/externalExam/examPeriod/table",
                      permissions: ["eeepview"],
                      icon: GlobalIconNames("examperiod").className,
                      description: "Manage and maintain exam periods",
                    },
                    {
                      name: "Exams",
                      path: "/studentAdministration/externalExam/details/table",
                      permissions: ["eerleview"],
                      icon: GlobalIconNames("exam").className,
                      description: "Manage exams, results and registrations",
                    },
                    {
                      name: "Exam Centres",
                      path: "/studentAdministration/externalExam/examCentres/table",
                      permissions: ["eeecview"],
                      icon: GlobalIconNames("examcentre").className,
                      description: "Manage and maintain exam centers",
                    },
                    {
                      name: "Exam Settings",
                      path: "/studentAdministration/externalExam/examSettings/add",
                      permissions: ["eeesview"],
                      icon: GlobalIconNames("examsetting").className,
                      description: "View and manage exam settings",
                    },
                  ],
                },
              ],
            },
            {
              pageName: 'campus_administration',
              options: [
                { name: "Campus Management",
                  path: "/administration/campusManagement/campusList",
                  icon: GlobalIconNames("Campus Management").className,
                  description: "Manage campus details",
                  permissions: ["sacmview"],
                },
                { name: "Schedule Management",
                  path: "/campusAdministration/schedule/calender",
                  icon: GlobalIconNames("Schedule Management").className,
                  description: "Manage schedules and venue availability",
                  permissions: ["caschedmanmview"],
                },
                { name: "Booking Management",
                  path: "/campusAdministration/bookings/table",
                  icon: GlobalIconNames("Booking Management").className,
                  description: "Manage and view submitted booking requests",
                  permissions: ["cabookmanview"],
                },
                { name: "Smart Signage",
                  path: "/campusAdministration/smartsignage/list",
                  icon: GlobalIconNames("Smart Signage").className,
                  description: "Manage and view digital signage displays and content",
                  permissions: ["casmartsignview"],
                }
              ]
            },
            {
              pageName: "system_administration",
              options: [
                {
                  name: "User Management",
                  path: "/systemAdministration/userManagement/table",
                  icon: GlobalIconNames("users").className,
                  permissions: ["umview"],
                  description: "Manage users of AMI.",
                },
                {
                  name: "Permission Management",
                  path: "/systemAdministration/permissionManagement/table",
                  icon: GlobalIconNames("Permissions").className,
                  permissions: ["pmview"],
                  description: "Rights management of users using AMI.",
                },
                {
                  name: "Brand Management",
                  path: "/administration/brandSetting/list",
                  icon: GlobalIconNames("brandmanagement").className,
                  permissions: ["bmview"],
                  description: "Manage the UI look and feel  of all the AIE brands.",
                },
                {
                  name: "NetSuite Management",
                  icon: GlobalIconNames("netSuitemanagement").className,
                  permissions: ["nmsmsview", "nmsmrview"],
                  subPageName: "netsuite_management",
                  description: "Manage customer records and merged student data.",
                  subTabs: [
                    {
                      name: "Customer Records",
                      path: "/administration/netsuite/customers/table",
                      icon: GlobalIconNames("customerrecords").className,
                      permissions: ["nmcrview"],
                      description: "Maintain existing records or add a new customer.",
                    },
                    {
                      name: "Student Merged Data",
                      path: "/administration/netsuite/mergedData/table/student",
                      icon: GlobalIconNames("studentmergeddata").className,
                      permissions: ["nmsmsview", "nmsmrview"],
                      description: "Manage student and Registration data.",
                    },
                  ],
                },
                {
                  name: "Ticket Settings",
                  icon: "fal fa-users",
                  permissions: [
                    "ticketsettingdepartmentview",
                    "ticketsettingcategoryview",
                  ],
                  subPageName: "ticket_settings",
                  description: "Manage ticket departments and categories.",
                  subTabs: [
                    {
                      name: "Departments",
                      path: "/administration/ticketSettings/department/table",
                      icon: "fal fa-users",
                      permissions: ["ticketsettingdepartmentview"],
                      description: "Maintain department statuses and creation.",
                    },
                    {
                      name: "Categories",
                      path: "/administration/ticketSettings/categories/table",
                      icon: "fas fa-bars",
                      permissions: ["ticketsettingcategoryview"],
                      description: "Maintain categories and creation.",
                    },
                  ],
                },
                {
                  name: "General",
                  path: "/administration/general/settings",
                  icon: "fal fa-wrench",
                  permissions: ["gview"],
                },
                {
                  name: "Server Management",
                  path: "/administration/serverManagement/serverSettings",
                  icon: "fal fa-server",
                  permissions: [],
                  description: "Manage and monitor the BBB servers.",
                },
                {
                  name: "Class Management",
                  path: "/administration/classManagement/onlineClasses",
                  icon: "fal fa-users-class",
                  permissions: [],
                  description: "Manage class settings and recordings checker.",
                },
                // {
                //   name: "Campus Management",
                //   path: "/administration/campusManagement/campusList",
                //   icon: "fal fa-building",
                //   permissions: [],
                //   description: "Manage campus details.",
                // },
                { name: "Softphone Management", path: "/administration/softphoneManagement/callLogs", icon: 'fal fa-phone-office', permissions: ['softphonecalllogview'], description:"Manage and monitor softphone activity and users" },
                
              ],
            },
            {
              pageName: "website_management",
              options: [
                {
                  name: "Site Setup",
                  path: hasPermission({scopes: ["wbssitewebsiteview"], permissions: givenPermsisions}) ? "/websiteManagement/siteSetup/table" : hasPermission({scopes: ["wbssitebrandtmpview"], permissions: givenPermsisions}) && "/websiteManagement/siteSetup/brand_Templates",
                  icon: GlobalIconNames("tools").className,
                  permissions: ["wbssitewebsiteview" , "wbssitebrandtmpview"],
                  description: "Configure and manage website settings and appearance",
                },
                {
                  name: "Layout & Navigation",
                  path: hasPermission({scopes: ["wbslncategoriesview"], permissions: givenPermsisions}) ? "/websiteManagement/layout_navigation/categories/table" : hasPermission({scopes: ["wbslnmenusview"], permissions: givenPermsisions}) ? "/websiteManagement/layout_navigation/menus/table" : hasPermission({scopes: ["wbslnheadersview"], permissions: givenPermsisions,}) ? "/websiteManagement/layout_navigation/header/table" : hasPermission({scopes: ["wbslntestimonialsview"], permissions: givenPermsisions}) && "/websiteManagement/layout_navigation/testimonials/table",
                  icon: GlobalIconNames("compass").className,
                  permissions: [
                    "wbslncategoriesview",
                    "wbslnmenusview",
                    "wbslnheadersview",
                    "wbslntestimonialsview"
                  ],
                  description:
                  "Manage and edit site categories, menus, headers and testimonials",
                },
                {
                  name: "Events",
                  path: hasPermission({scopes: ["wbseventlpageview"], permissions: givenPermsisions}) ? "/websiteManagement/events/landing_page/table" : hasPermission({scopes: ["wbseventupcomingview"], permissions: givenPermsisions}) ? "/websiteManagement/events/upcoming_events/table" : hasPermission({scopes: ["wbseventinfopview"], permissions: givenPermsisions}) && "/websiteManagement/events/info_packs/table" ,
                  icon: GlobalIconNames("bookedcourses").className,
                  permissions: ["wbseventlpageview" , "wbseventupcomingview" , "wbseventinfopview"],
                  description:
                  "Manage and edit upcoming events, info packs and event landing pages.",
                },
                {
                  name: "Pages",
                  path: "/links/website_management/Pages",
                  icon: GlobalIconNames("sitemap").className,
                  permissions: ["wbsview"],
                  description: "Manage and edit site pages.",
                  subPageName: "Pages",
                  subTabs: [
                    {
                      name: "Programmes & Courses",
                      path: hasPermission({scopes: ["wbspagespmcpview"], permissions: givenPermsisions}) ? "/websiteManagement/pages/programmes_and_short_courses/programme_pages/table" : hasPermission({scopes: ["wbspagespmcsview"], permissions: givenPermsisions}) && "/websiteManagement/pages/programmes_and_short_courses/short_course_page/table",
                      icon: GlobalIconNames("books").className,
                      permissions: ["wbspagespmcpview" , "wbspagespmcsview"],
                      description: "Manage and edit programme and course pages",
                    },
                    {
                      name: "Static Pages",
                      path: "/website_management/pages/staticpages/table",
                      permissions: ["wbsstaticpageview"],
                      icon: GlobalIconNames("file").className,
                      description: "Manage and edit static website pages.",
                    },
                    {
                      name: "Course Landing Pages",
                      path: "/website_management/pages/courselandingpages/table",
                      permissions: ["wbscourselpageview"],
                      icon: GlobalIconNames("graduation-cap").className,
                      description: "Manage and edit course landing pages.",
                    },
                    {
                      name: "Campus Pages",
                      path: "/website_management/pages/campuspages/table",
                      permissions: ["wbscampuslpageview"],
                      icon: GlobalIconNames("school").className,
                      description: "Manage and edit campus pages.",
                    },
                    {
                      name: "School Landing Pages",
                      path: "/website_management/pages/categorylandingpages/table",
                      permissions: ["wbscategorylpageview"],
                      icon: GlobalIconNames("layer-plus").className,
                      description: "Manage and edit school landing pages.",
                    },
                    {
                      name: "Page Popups",
                      path: "/website_management/pages/popupspages/table",
                      permissions: ["wbspopuppageview"],
                      icon: GlobalIconNames("window").className,
                      description: "Manage and edit page popups.",
                    },
                    {
                      name: "Blog & News",
                      path: "/website_management/pages/blogandnews/table",
                      permissions: ["wbsblognewspageview"],
                      icon: GlobalIconNames("globe").className,
                      description: "Manage and blog and news pages.",
                    },
                  ],
                },
                {
                  name: "Emails",
                  path: "/website_management/pages/emailspages/table",
                  icon: GlobalIconNames("envelope").className,
                  permissions: ["wbsemailview"],
                  description: "Manage and edit info packs and emails.",
                },
                {
                  name: "Partners",
                  path: "/website_management/partners/table",
                  icon: GlobalIconNames("users").className,
                  permissions: ["partnerview"],
                  description:
                  "Manage and edit partners and their websites.",
                },
              ],
            },
          ];
        }
        
        return (
          <>
          <div className="sidebar-left">
          <ul className="list-unstyled side-bar-nav m-0">
          {Tabs.map((tab, index) => {
            if (tab.id == 7 && tab.id != 20) {
              return (
                checkPermissions(["wbsview"]) && 
                <li
                className={subMenuWebsiteManagement}
                title="Website Management"
                key={tab.id}
                onMouseOver={() => showSubMenu("website_management")}
                onMouseOut={() => hideSubMenu("website_management")}
                >
                <Link
                to="/links/website_management"
                className={
                  currentTabValue.includes("websiteManagement") || currentTabValue.includes("website_management")
                  ? "active"
                  : ""
                }
                >
                <span className="nav-icon">
                <i className={"fal " + tab.class}></i>
                </span>
                {tab.name}
                </Link>
                <div className="sub-menu-content-block">
                <div className="sub-menu-left-block">
                <button className="close-btn-sidebar">
                <i className="close-bar close-bar-1"></i>
                <i className="close-bar close-bar-2"></i>
                <i className="close-bar close-bar-3"></i>
                </button>
                <div
                className="sub-menu accordion"
                id="accordionExample"
                >
                <div className="card">
                <PermissionsGate scopes={["wbssitewebsiteview" , "wbssitebrandtmpview"]}>
                <div
                className={
                  "card-header" +
                  (pathname?.includes("websiteManagement/siteSetup") ||
                  pathname == "/edituser" ||
                  pathname == "/addnewuser"
                  ? " active"
                  : "")
                }
                id="headingOne"
                >
                <Link
                to={hasPermission({scopes: ["wbssitewebsiteview"], permissions: givenPermsisions}) ? "/websiteManagement/siteSetup/table" : hasPermission({scopes: ["wbssitebrandtmpview"], permissions: givenPermsisions}) && "/websiteManagement/siteSetup/brand_Templates"}
                className="btn btn-link btn-block no-sub"
                // onClick={() => localStorage.setItem("iUMFS", true)}
                >
                Site Setup
                </Link>
                </div>
                </PermissionsGate>
                
                <PermissionsGate scopes={[
                  "wbslncategoriesview",
                  "wbslnmenusview",
                  "wbslnheadersview",
                  "wbslntestimonialsview"
                ]}>
                <div
                className={
                  "card-header" +
                  (sideTab == "websiteManagement" ||
                    pathname?.includes("websiteManagement/layout_navigation")
                    ? " active"
                    : "")
                  }
                  id="headingOne"
                  >
                  <Link
                  to={hasPermission({scopes: ["wbslncategoriesview"], permissions: givenPermsisions}) ? "/websiteManagement/layout_navigation/categories/table" : hasPermission({scopes: ["wbslnmenusview"], permissions: givenPermsisions}) ? "/websiteManagement/layout_navigation/menus/table" : hasPermission({scopes: ["wbslnheadersview"], permissions: givenPermsisions,}) ? "/websiteManagement/layout_navigation/header/table" : hasPermission({scopes: ["wbslntestimonialsview"], permissions: givenPermsisions}) && "/websiteManagement/layout_navigation/testimonials/table"}
                  className="btn btn-link btn-block no-sub"
                  // onClick={() => localStorage.setItem("iPMFS", true)}
                  >
                  Layout & Navigation
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  <PermissionsGate scopes={["bmview"]}>
                  <div
                  className={
                    "card-header" +
                    (pathname?.includes(
                      "websiteManagement/events"
                    )
                    ? " active"
                    : "")
                  }
                  id="headingOne"
                  >
                  <Link
                  to={{ pathname: "/websiteManagement/events/landing_page/table" }}
                  // onClick={() => localStorage.setItem("iBFS", true)}
                  className="btn btn-link btn-block no-sub"
                  >
                  Events
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  {checkPermissions(["nmcrview", "nmsmsview", "nmsmrview"]) && <div
                    className={
                      "card-header" +
                      (pathname?.includes("/website_management/pages") && !pathname.includes("/emailspages")
                      ? " active"
                      : "")
                    }
                    id="headingOne"
                    >
                    <a
                    className="btn btn-link btn-block collapsed "
                    data-toggle="collapse"
                    href="#collapseExamplenetsuit"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    >
                    Pages
                    </a>
                    </div>}
                    
                    <PermissionsGate scopes={["wbspagespmcpview" , "wbspagespmcsview"]}>
                    <div
                    className={
                      "collapse card-header sub-menu-box" +
                      (pathname?.includes("/website_management/pages") && !pathname.includes("/emailspages")
                      ? " show"
                      : "") +
                      (pathname.includes("programmes_and_short_courses")
                      ? " active"
                      : "")
                    }
                    id="collapseExamplenetsuit"
                    >
                    <Link
                    className="btn btn-link btn-block no-sub sub-sub-tab"
                    to={hasPermission({scopes: ["wbspagespmcpview"], permissions: givenPermsisions}) ? "/websiteManagement/pages/programmes_and_short_courses/programme_pages/table" : hasPermission({scopes: ["wbspagespmcsview"], permissions: givenPermsisions}) && "/websiteManagement/pages/programmes_and_short_courses/short_course_page/table"
                  }
                  // onClick={() => localStorage.setItem("iCRFS", true)}
                  >
                  <span className="ml-2">
                  Programmes & Courses
                  </span>
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  <PermissionsGate scopes={["wbsstaticpageview"]}>
                  <div
                  className={
                    "collapse card-header sub-menu-box" +
                    (pathname?.includes("/website_management/pages") && !pathname.includes("/emailspages")
                    ? " show"
                    : "") +
                    (pathname.includes("staticpages")
                    ? " active"
                    : "")
                  }
                  id="collapseExamplenetsuit"
                  >
                  <Link
                  className="btn btn-link btn-block no-sub sub-sub-tab"
                  to={{
                    pathname:
                    "/website_management/pages/staticpages/table",
                  }}
                  // onClick={() => localStorage.setItem("iCRFS", true)}
                  >
                  <span className="ml-2">
                  Static Pages
                  </span>
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  <PermissionsGate scopes={["wbscourselpageview"]}>
                  <div
                  className={
                    "collapse card-header sub-menu-box" +
                    (pathname?.includes("/website_management/pages") && !pathname.includes("/emailspages")
                    ? " show"
                    : "") +
                    (pathname.includes("courselandingpages")
                    ? " active"
                    : "")
                  }
                  id="collapseExamplenetsuit"
                  >
                  <Link
                  className="btn btn-link btn-block no-sub sub-sub-tab"
                  to={{
                    pathname:
                    "/website_management/pages/courselandingpages/table",
                  }}
                  // onClick={() => localStorage.setItem("iCRFS", true)}
                  >
                  <span className="ml-2">
                  Course Landing Pages
                  </span>
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  <PermissionsGate scopes={["wbscampuslpageview"]}>
                  <div
                  className={
                    "collapse card-header sub-menu-box" +
                    (pathname?.includes("/website_management/pages") && !pathname.includes("/emailspages")
                    ? " show"
                    : "") +
                    (pathname.includes("campuspages")
                    ? " active"
                    : "")
                  }
                  id="collapseExamplenetsuit"
                  >
                  <Link
                  className="btn btn-link btn-block no-sub sub-sub-tab"
                  to={{
                    pathname:
                    "/website_management/pages/campuspages/table",
                  }}
                  // onClick={() => localStorage.setItem("iCRFS", true)}
                  >
                  <span className="ml-2">
                  Campus Pages
                  </span>
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  <PermissionsGate scopes={["wbscategorylpageview"]}>
                  <div
                  className={
                    "collapse card-header sub-menu-box" +
                    (pathname?.includes("/website_management/pages") && !pathname.includes("/emailspages")
                    ? " show"
                    : "") +
                    (pathname.includes("categorylandingpages")
                    ? " active"
                    : "")
                  }
                  id="collapseExamplenetsuit"
                  >
                  <Link
                  className="btn btn-link btn-block no-sub sub-sub-tab"
                  to={{
                    pathname:
                    "/website_management/pages/categorylandingpages/table",
                  }}
                  // onClick={() => localStorage.setItem("iCRFS", true)}
                  >
                  <span className="ml-2">
                  School Landing Pages
                  </span>
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  <PermissionsGate scopes={["wbspopuppageview"]}>
                  <div
                  className={
                    "collapse card-header sub-menu-box" +
                    (pathname?.includes("/website_management/pages") && !pathname.includes("/emailspages")
                    ? " show"
                    : "") +
                    (pathname.includes("popupspages")
                    ? " active"
                    : "")
                  }
                  id="collapseExamplenetsuit"
                  >
                  <Link
                  className="btn btn-link btn-block no-sub sub-sub-tab"
                  to={{
                    pathname:
                    "/website_management/pages/popupspages/table",
                  }}
                  // onClick={() => localStorage.setItem("iCRFS", true)}
                  >
                  <span className="ml-2">
                  Page Popups
                  </span>
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  <PermissionsGate scopes={["wbsblognewspageview"]}>
                  <div
                  className={
                    "collapse card-header sub-menu-box" +
                    (pathname?.includes("/website_management/pages") && !pathname.includes("/emailspages")
                    ? " show"
                    : "") +
                    (pathname.includes("blogandnews")
                    ? " active"
                    : "")
                  }
                  id="collapseExamplenetsuit"
                  >
                  <Link
                  className="btn btn-link btn-block no-sub sub-sub-tab"
                  to={{
                    pathname:
                    "/website_management/pages/blogandnews/table",
                  }}
                  // onClick={() => localStorage.setItem("iCRFS", true)}
                  >
                  <span className="ml-2">
                  Blog & News
                  </span>
                  </Link>
                  </div>
                  </PermissionsGate>
                  
                  <PermissionsGate scopes={["wbsemailview"]}>
                  <div
                  className={
                    "card-header" +
                    (pathname?.includes(
                      "/website_management/pages/emailspages"
                    )
                    ? " active"
                    : "")
                  }
                  id="headingOne"
                  >
                  <Link
                  to={{ pathname: "/website_management/pages/emailspages/table" }}
                  // onClick={() => localStorage.setItem("clsm", true)}
                  className="btn btn-link btn-block no-sub"
                  >
                  Emails
                  </Link>
                  </div>
                  </PermissionsGate>
                  <PermissionsGate scopes={["partnerview"]}>
                  <div
                  className={
                    "card-header" +
                    (pathname?.includes(
                      "/website_management/partners"
                    )
                    ? " active"
                    : "")
                  }
                  id="headingOne"
                  >
                  <Link
                  to={{ pathname: "/website_management/partners/table" }}
                  // onClick={() => localStorage.setItem("clsm", true)}
                  className="btn btn-link btn-block no-sub"
                  >
                  Partners
                  </Link>
                  </div>
                  </PermissionsGate>
                  </div>
                  </div>
                  </div>
                  </div>
                  </li>
                )
              } else if (tab.id == 5) {
                return (
                  checkPermissions(["pmcpview", "pmcmview", "pmcfandbview"]) && <li title="Program Management Center" key={tab.id} onClick={() => dispatch(selectTab({ tab: tab.id }))} className={subMenuProgramManagement} onMouseOver={() => showSubMenu("programManagement")}
                  onMouseOut={() => hideSubMenu("programManagement")}>
                  <Link
                  to="/links/program_management_center"
                  className={currentTabValue.includes("program_management_center") || currentTabValue.includes("program-management-center") ? "active" : ""}
                  >
                  <span className="nav-icon">
                  <i className={"fal " + tab.class}></i>
                  </span>
                  {tab.name}
                  </Link>
                  <div className="sub-menu-content-block">
                  <div className="sub-menu-left-block">
                  <button className="close-btn-sidebar">
                  <i className="close-bar close-bar-1"></i>
                  <i className="close-bar close-bar-2"></i>
                  <i className="close-bar close-bar-3"></i>
                  </button>
                  <div
                  className="sub-menu accordion"
                  id="accordionExample"
                  >
                  <div className="card">
                  <PermissionsGate scopes={["pmcpview"]}>
                  <div className={"card-header" + (sideTab == "programmes" ? " active" : "")} id="headingOne" title="Programmes">
                  <Link
                  to="/program-management-center/programmes/programme/table"
                  className="btn btn-link btn-block no-sub"
                  onClick={() => localStorage.setItem("iPMCPFS", true)}
                  >
                  Programmes & Courses
                  </Link>
                  </div>
                  </PermissionsGate>
                  <PermissionsGate scopes={["pmcmview"]}>
                  <div className={"card-header" + (sideTab == "modules" ? " active" : "")} id="headingOne" title="Modules">
                  <Link
                  to="/program-management-center/modules/module/table"
                  className="btn btn-link btn-block no-sub"
                  onClick={() => localStorage.setItem("iPMCMFS", true)}
                  >
                  Modules
                  </Link>
                  </div>
                  </PermissionsGate>
                  <PermissionsGate scopes={["pmcfandbview"]}>
                  <div className={"card-header" + (sideTab == "features" ? " active" : "")} id="headingOne" title="features">
                  <Link
                  to="/program-management-center/features/benefits/table"
                  className="btn btn-link btn-block no-sub"
                  onClick={() => localStorage.setItem("iPMCFB", true)}
                  >
                  Features & Benefits
                  </Link>
                  </div>
                  </PermissionsGate>
                  <PermissionsGate scopes={["sresourcemview"]}>
                  <div
                  className={
                    "collapse card-header sub-menu-box" +
                    (currentTabValue?.includes("resource-management")
                    ? " active"
                    : "") +
                    (pathname.includes("student-hub-library")
                    ? " show"
                    : "")
                  }
                  id="collapseExamplecourse3"
                  >
                  <Link
                  className="btn btn-link btn-block no-sub sub-sub-tab"
                  to={{
                    pathname:
                    "/support/student-hub-library/resource-management/table",
                  }}
                  onClick={() => localStorage.setItem("iSHLFS", true)}
                  >
                  <span className="ml-2">Resource Management</span>
                  </Link>
                  </div>
                  </PermissionsGate>
                  </div>
                  </div>
                  </div>
                  </div>
                  </li>
                );
              } else if (
                tab.id < 8 &&
                tab.id != 6 &&
                tab.id != 3 &&
                user.status != "On Hold" &&
                user.status != "In Active"
              ) {
                if (tab.id !== 4) {
                  return (
                    <li
                    title={
                      tab.id == 1
                      ? "Student Portal Home"
                      : tab.id == 2
                      ? "Always keep your student profile up to date"
                      : tab.id == 4
                      ? "All your important course information for easy study"
                      : tab.id === 3
                      ? "Course Administration"
                      : ""
                    }
                    key={tab.id}
                    >
                    <NavLink
                    to={("/", "/" + tab.refLink)}
                    className={
                      currentTabValue[1].includes("appeals") ? "active" : ""
                    }
                    >
                    <span className="nav-icon">
                    <i className={"fal " + tab.class}></i>
                    </span>
                    {tab.name}
                    </NavLink>
                    </li>
                  )
                }
                if (tab.id === 4 &&
                  checkPermissions([
                    "pmview",
                    "umview",
                    "nmcrview",
                    "nmsmsview",
                    "nmsmrview",
                    "tsview",
                    "gview",
                  ])) {
                    return (
                      <li
                      className={subMenuSystemAdministration}
                      title={
                        tab.id == 1
                        ? "Student Portal Home"
                        : tab.id == 2
                        ? "Always keep your student profile up to date"
                        : tab.id == 4
                        ? "System Administration"
                        : null
                      }
                      key={tab.id}
                      onClick={() => dispatch(selectTab({ tab: tab.id }))}
                      onMouseOver={() => showSubMenu("systemAdministration")}
                      onMouseOut={() => hideSubMenu("systemAdministration")}
                      >
                      <Link
                      to="/links/system_administration"
                      className={
                        currentTabValue.includes("systemAdministration") ||
                        currentTabValue.includes("system_administration") ||
                        (currentTabValue.includes("administration") && !pathname.includes("campusManagement"))
                        ? "active"
                        : ""
                      }
                      >
                      <span className="nav-icon">
                      <i className={"fal " + tab.class}></i>
                      </span>
                      {tab.name}
                      </Link>
                      <div className="sub-menu-content-block">
                      <div className="sub-menu-left-block">
                      <button className="close-btn-sidebar">
                      <i className="close-bar close-bar-1"></i>
                      <i className="close-bar close-bar-2"></i>
                      <i className="close-bar close-bar-3"></i>
                      </button>
                      <div
                      className="sub-menu accordion"
                      id="accordionExample"
                      >
                      <div className="card">
                      <PermissionsGate scopes={["umview"]}>
                      <div
                      className={
                        "card-header" +
                        (pathname?.includes("userManagement") ||
                        pathname == "/edituser" ||
                        pathname == "/addnewuser"
                        ? " active"
                        : "")
                      }
                      id="headingOne"
                      >
                      <Link
                      to="/systemAdministration/userManagement/table"
                      className="btn btn-link btn-block no-sub"
                      onClick={() => localStorage.setItem("iUMFS", true)}
                      >
                      User Management
                      </Link>
                      </div>
                      </PermissionsGate>
                      
                      <PermissionsGate scopes={["pmview"]}>
                      <div
                      className={
                        "card-header" +
                        (sideTab == "permissionManagement" ||
                          pathname?.includes("permissionManagement")
                          ? " active"
                          : "")
                        }
                        id="headingOne"
                        >
                        <Link
                        to="/systemAdministration/permissionManagement/table"
                        className="btn btn-link btn-block no-sub"
                        onClick={() => localStorage.setItem("iPMFS", true)}
                        >
                        Permission Management
                        </Link>
                        </div>
                        </PermissionsGate>
                        
                        <PermissionsGate scopes={["bmview"]}>
                        <div
                        className={
                          "card-header" +
                          (pathname?.includes(
                            "/administration/brandSetting"
                          )
                          ? " active"
                          : "")
                        }
                        id="headingOne"
                        >
                        <Link
                        to={{ pathname: "/administration/brandSetting/list" }}
                        onClick={() => localStorage.setItem("iBFS", true)}
                        className="btn btn-link btn-block no-sub"
                        >
                        Brand Management
                        </Link>
                        </div>
                        </PermissionsGate>
                        
                        {checkPermissions(["nmcrview", "nmsmsview", "nmsmrview"]) && <div
                          className={
                            "card-header" +
                            (pathname?.includes(
                              "/administration/netsuite"
                            )
                            ? " active"
                            : "")
                          }
                          id="headingOne"
                          >
                          <a
                          className="btn btn-link btn-block collapsed "
                          data-toggle="collapse"
                          href="#collapseExamplenetsuit"
                          role="button"
                          aria-expanded="false"
                          aria-controls="collapseExample"
                          >
                          NetSuite Management
                          </a>
                          </div>}
                          
                          <PermissionsGate scopes={["nmcrview"]}>
                          <div
                          className={
                            "collapse card-header sub-menu-box" +
                            (pathname?.includes("netsuite")
                            ? " show"
                            : "") +
                            (pathname.includes("customers")
                            ? " active"
                            : "")
                          }
                          id="collapseExamplenetsuit"
                          >
                          <Link
                          className="btn btn-link btn-block no-sub sub-sub-tab"
                          to={{
                            pathname:
                            "/administration/netsuite/customers/table",
                          }}
                          onClick={() => localStorage.setItem("iCRFS", true)}
                          >
                          <span className="ml-2">
                          Customer Records
                          </span>
                          </Link>
                          </div>
                          </PermissionsGate>
                          
                          <PermissionsGate
                          scopes={["nmsmsview", "nmsmrview"]}
                          >
                          <div
                          className={
                            "collapse card-header sub-menu-box" +
                            (pathname?.includes("netsuite")
                            ? " show"
                            : "") +
                            (currentTabValue[2] && currentTabValue[2].includes("netsuite") &&
                            currentTabValue[3] && currentTabValue[3].includes("mergedData")
                            ? " active"
                            : "")
                          }
                          id="collapseExamplenetsuit"
                          >
                          <PermissionsGate
                          scopes={["nmsmsview"]}
                          RenderError={() => (
                            <PermissionsGate scopes={["nmsmrview"]}>
                            <Link
                            className="btn btn-link btn-block mt-0 no-sub sub-sub-tab"
                            to={{
                              pathname:
                              "/administration/netsuite/mergedData/table/registration",
                            }}
                            
                            >
                            <span className="ml-2">
                            Student Merged Data
                            </span>
                            </Link>
                            </PermissionsGate>
                          )}
                          >
                          <Link
                          className="btn btn-link btn-block mt-0 no-sub sub-sub-tab"
                          to={{
                            pathname:
                            "/administration/netsuite/mergedData/table/student",
                          }}
                          onClick={() => localStorage.setItem("iSMDFS", true)}
                          >
                          <span className="ml-2">
                          Student Merged Data
                          </span>
                          </Link>
                          </PermissionsGate>
                          </div>
                          </PermissionsGate>
                          
                          {checkPermissions(["ticketsettingdepartmentview", "ticketsettingcategoryview"]) && <div
                            className={
                              "card-header" +
                              (pathname?.includes(
                                "/administration/ticketSettings"
                              )
                              ? " active"
                              : "")
                            }
                            id="headingOne"
                            >
                            <a
                            className="btn btn-link btn-block collapsed "
                            data-toggle="collapse"
                            href="#collapseExampleticketsetting"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            >
                            Ticket Settings
                            </a>
                            </div>}
                            <PermissionsGate scopes={["ticketsettingdepartmentview"]}>
                            <div
                            className={
                              "collapse card-header sub-menu-box" +
                              (pathname?.includes("ticketSettings")
                              ? " show"
                              : "") +
                              (pathname.includes("department")
                              ? " active"
                              : "")
                            }
                            id="collapseExampleticketsetting"
                            >
                            <Link
                            className="btn btn-link btn-block no-sub sub-sub-tab"
                            to={{
                              pathname:
                              "/administration/ticketSettings/department/table",
                            }}
                            onClick={() => localStorage.setItem("ts", true)}
                            >
                            <span className="ml-2">
                            Departments
                            </span>
                            </Link>
                            </div>
                            </PermissionsGate>
                            
                            <PermissionsGate
                            scopes={["ticketsettingcategoryview"]}
                            >
                            <div
                            className={
                              "collapse card-header sub-menu-box" +
                              (pathname?.includes("ticketSettings")
                              ? " show"
                              : "") +
                              (currentTabValue[2] && currentTabValue[2].includes("ticketSettings") &&
                              currentTabValue[3] && currentTabValue[3].includes("categories")
                              ? " active"
                              : "")
                            }
                            id="collapseExampleticketsetting"
                            >
                            
                            <Link
                            className="btn btn-link btn-block mt-0 no-sub sub-sub-tab"
                            to={{
                              pathname:
                              "/administration/ticketSettings/categories/table",
                            }}
                            onClick={() => localStorage.setItem("ts", true)}
                            >
                            <span className="ml-2">
                            Categories
                            </span>
                            </Link>
                            
                            </div>
                            </PermissionsGate>
                            <div
                            className={
                              "card-header" +
                              (pathname?.includes(
                                "/administration/general"
                              )
                              ? " active"
                              : "")
                            }
                            id="headingOne"
                            >
                            <Link
                            to={{ pathname: "/administration/general/settings" }}
                            onClick={() => localStorage.setItem("gnl", true)}
                            className="btn btn-link btn-block no-sub"
                            >
                            General
                            </Link>
                            </div>
                            
                            <div
                            className={
                              "card-header" +
                              (pathname?.includes(
                                "/administration/serverManagement"
                              )
                              ? " active"
                              : "")
                            }
                            id="headingOne"
                            >
                            <Link
                            to={{ pathname: "/administration/serverManagement/serverSettings" }}
                            onClick={() => localStorage.setItem("sm", true)}
                            className="btn btn-link btn-block no-sub"
                            >
                            Server Management
                            </Link>
                            </div>
                            
                            <div
                            className={
                              "card-header" +
                              (pathname?.includes(
                                "/administration/classManagement"
                              )
                              ? " active"
                              : "")
                            }
                            id="headingOne"
                            >
                            <Link
                            to={{ pathname: "/administration/classManagement/onlineClasses" }}
                            onClick={() => localStorage.setItem("clsm", true)}
                            className="btn btn-link btn-block no-sub"
                            >
                            Class Management
                            </Link>
                            </div>
                            
                            {/* <div
                              className={
                              "card-header" +
                              (pathname?.includes(
                              "/administration/campusManagement"
                              )
                              ? " active"
                              : "")
                              }
                              id="headingOne"
                              >
                              <Link
                              to={{ pathname: "/administration/campusManagement/campusList" }}
                              onClick={() => localStorage.setItem("cpm", true)}
                              className="btn btn-link btn-block no-sub"
                              >
                              Campus Management
                              </Link>
                              </div>*/}
                              <div
                              className={
                                "card-header" +
                                (pathname?.includes(
                                  "/administration/softphoneManagement"
                                )
                                ? " active"
                                : "")
                              }
                              id="headingOne"
                              >
                              <Link
                              to={{ pathname: "/administration/softphoneManagement/callLogs" }}
                              onClick={() => localStorage.setItem("cpm", true)}
                              className="btn btn-link btn-block no-sub"
                              >
                              Softphone Management
                              </Link>
                              </div>
                              
                              </div>
                              </div>
                              </div>
                              </div>
                              </li>
                            )
                          }
                        } else if (
                          (tab.id == 8 || tab.id == 9) &&
                          user.status != "On Hold" &&
                          user.status != "In Active"
                        ) {
                          if (tab.id == 8 && examReg) {
                            return (
                              <li
                              title="Your Exam Registrations"
                              key={tab.id}
                              onClick={() => dispatch(selectTab({ tab: tab.id }))}
                              >
                              <NavLink
                              to={("/", "/" + tab.refLink)}
                              className={
                                tab.refLink == getMenuItem(currentTabValue[1])
                                ? "active"
                                : ""
                              }
                              >
                              <span className="nav-icon">
                              <i className={"fal " + tab.class}></i>
                              </span>
                              {tab.name}
                              </NavLink>
                              </li>
                            );
                          }
                        } else if (
                          tab.id == 9 &&
                          reReg &&
                          user.status != "On Hold" &&
                          user.status != "In Active"
                        ) {
                          return (
                            <li
                            title="Your Re-Registrations"
                            key={tab.id}
                            onClick={() => dispatch(selectTab({ tab: tab.id }))}
                            >
                            <NavLink
                            to={("/", "/" + tab.refLink)}
                            className={
                              tab.refLink == getMenuItem(currentTabValue[1])
                              ? "active"
                              : ""
                            }
                            >
                            <span className="nav-icon">
                            <i className={"fal " + tab.class}></i>
                            </span>
                            {tab.name}
                            </NavLink>
                            </li>
                          );
                        } else if (
                          tab.id == 10 &&
                          user.status != "On Hold" &&
                          user.status != "In Active"
                        ) {
                          return (
                            <li
                            title="How-To Guides and Academic Resources"
                            key={tab.id}
                            onClick={() => dispatch(selectTab({ tab: tab.id }))}
                            >
                            <NavLink
                            to={("/", "/" + tab.refLink)}
                            className={
                              tab.refLink == getMenuItem(currentTabValue[1])
                              ? "active"
                              : ""
                            }
                            >
                            <span className="nav-icon">
                            <i className={"fal " + tab.class}></i>
                            </span>
                            {tab.name}
                            </NavLink>
                            </li>
                          );
                        } else if (
                          tab.id == 13 &&
                          user.status != "On Hold" &&
                          user.status != "In Active"
                        ) {
                          return (
                            <li
                            title="How-To Guides and Academic Resources"
                            key={tab.id}
                            onClick={() => dispatch(selectTab({ tab: tab.id }))}
                            >
                            <NavLink
                            to={("/", "/" + tab.refLink)}
                            className={
                              tab.refLink == getMenuItem(currentTabValue[1])
                              ? "active"
                              : ""
                            }
                            >
                            <span className="nav-icon">
                            <i className={"fal " + tab.class}></i>
                            </span>
                            {tab.name}
                            </NavLink>
                            </li>
                          );
                        } else if (
                          tab.id == 12 &&
                          user.status != "On Hold" &&
                          user.status != "In Active"
                        ) {
                          return (
                            checkPermissions([
                              "tview",
                              "shview",
                              "sslciew",
                              "ssaview",
                              "ssbview",
                              "bnbnview",
                              "bnnlview",
                              "bnpeview",
                              "bnseview",
                              "bnntview",
                            ]) && (
                              <li
                              className={subMenuStudentSupport}
                              title="Student Support"
                              key={tab.id}
                              onClick={() => dispatch(selectTab({ tab: tab.id }))}
                              onMouseOver={() => showSubMenu("supportAdministration")}
                              onMouseOut={() => hideSubMenu("supportAdministration")}
                              >
                              <Link
                              to="/links/student_support"
                              className={
                                tab.refLink.includes(currentTabValue[1]) ||
                                currentTabValue.includes("supportAdministration") ||
                                currentTabValue.includes("student_support") ||
                                currentTabValue[1] == "addticket" ||
                                currentTabValue[1] == "editticket" ||
                                (currentTabValue[1] && currentTabValue[1].includes("notification"))
                                ? "active"
                                : ""
                              }
                              >
                              <span className="nav-icon">
                              <i className={"fal " + tab.class}></i>
                              </span>
                              {tab.name}
                              </Link>
                              <div className="sub-menu-content-block">
                              <div className="sub-menu-left-block">
                              <button className="close-btn-sidebar">
                              <i className="close-bar close-bar-1"></i>
                              <i className="close-bar close-bar-2"></i>
                              <i className="close-bar close-bar-3"></i>
                              </button>
                              <div
                              className="sub-menu accordion"
                              id="accordionExample"
                              >
                              <div className="card">
                              <PermissionsGate scopes={["tview"]}>
                              <div
                              className={
                                "card-header" +
                                (!pathname?.includes(
                                  "/support/student-hub-library/student-hub"
                                ) &&
                                ((pathname?.includes("/support") && !currentTabValue[2]) ||
                                pathname?.includes("/editticket") ||
                                pathname?.includes("/addticket")) ||
                                (currentTabValue[1] == "support" && currentTabValue[2] == "audittrail")
                                ? " active"
                                : "")
                              }
                              id="headingOne"
                              >
                              <Link
                              to={{ pathname: "/support/TotalDangerTicket" }}
                              onClick={() => localStorage.setItem("iTFS", true)}
                              className="btn btn-link btn-block no-sub"
                              >
                              Tickets
                              </Link>
                              </div>
                              </PermissionsGate>
                              {checkPermissions(["sresourcemview", "slibraryborrowersview", "shview"]) && <div
                                className={
                                  "card-header" +
                                  (currentTabValue.includes(
                                    "support"
                                  ) && currentTabValue.includes("student-hub-library")
                                  ? " active"
                                  : "")
                                }
                                id="headingOne"
                                >
                                <a
                                className="btn btn-link btn-block collapsed "
                                data-toggle="collapse"
                                href="#collapseExamplecourse3"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                >
                                Student Hub & Library
                                </a>
                                </div>}
                                <PermissionsGate scopes={["sresourcemview"]}>
                                <div
                                className={
                                  "collapse card-header sub-menu-box" +
                                  (currentTabValue?.includes("resource-management")
                                  ? " active"
                                  : "") +
                                  (pathname.includes("student-hub-library")
                                  ? " show"
                                  : "")
                                }
                                id="collapseExamplecourse3"
                                >
                                <Link
                                className="btn btn-link btn-block no-sub sub-sub-tab"
                                to={{
                                  pathname:
                                  "/support/student-hub-library/resource-management/table",
                                }}
                                onClick={() => localStorage.setItem("iSHLFS", true)}
                                >
                                <span className="ml-2">Resource Management</span>
                                </Link>
                                </div>
                                </PermissionsGate>
                                <PermissionsGate scopes={["slibraryborrowersview"]}>
                                <div
                                className={
                                  "collapse card-header sub-menu-box" +
                                  (currentTabValue?.includes("library-borrowers")
                                  ? " active"
                                  : "") +
                                  (pathname.includes("student-hub-library")
                                  ? " show"
                                  : "")
                                }
                                id="collapseExamplecourse3"
                                >
                                <Link
                                className="btn btn-link btn-block no-sub sub-sub-tab"
                                to={{
                                  pathname:
                                  "/support/student-hub-library/library-borrowers/table",
                                }}
                                onClick={() => localStorage.setItem("iLBLFS", true)}
                                >
                                <span className="ml-2">Library Borrowers</span>
                                </Link>
                                </div>
                                </PermissionsGate>
                                
                                <PermissionsGate scopes={["shview"]}>
                                <div
                                className={
                                  "collapse card-header sub-menu-box" +
                                  (currentTabValue?.includes("student-hub")
                                  ? " active"
                                  : "") +
                                  (pathname.includes("student-hub-library")
                                  ? " show"
                                  : "")
                                }
                                id="collapseExamplecourse3"
                                >
                                <Link
                                className="btn btn-link btn-block no-sub sub-sub-tab"
                                to={{
                                  pathname:
                                  "/support/student-hub-library/student-hub/list",
                                }}
                                onClick={() => localStorage.setItem("iSHMLFS", true)}
                                >
                                <span className="ml-2">Student Hub Management</span>
                                </Link>
                                </div>
                                </PermissionsGate>
                                
                                
                                
                                <PermissionsGate scopes={["sslciew"]}>
                                <div
                                className={
                                  "card-header" +
                                  (pathname?.includes("support") &&
                                  pathname?.includes("live-chat")
                                  ? " active"
                                  : "")
                                }
                                id="headingOne"
                                >
                                <Link
                                to="/support/live-chat"
                                className="btn btn-link btn-block no-sub"
                                onClick={() => localStorage.setItem("iLCFS", true)}
                                >
                                Live Chat
                                </Link>
                                </div>
                                </PermissionsGate>
                                
                                <PermissionsGate scopes={["ssaview"]}>
                                <div
                                className={
                                  "card-header" +
                                  (pathname?.includes("supportAdministration/appeals")
                                  ? " active"
                                  : "")
                                }
                                id="headingOne"
                                >
                                <Link
                                to="/supportAdministration/appeals/appeal/pending"
                                className="btn btn-link btn-block no-sub"
                                onClick={() => localStorage.setItem("iALFS", true)}
                                >
                                Appeals
                                </Link>
                                </div>
                                </PermissionsGate>
                                
                                <PermissionsGate scopes={["ssbview"]}>
                                <div
                                className={
                                  "card-header" +
                                  (pathname?.includes(
                                    "/support/studentBanners/table"
                                  ) || pathname?.includes("studentBanners")
                                  ? " active"
                                  : "")
                                }
                                id="headingOne"
                                >
                                <Link
                                to="/support/studentBanners/table"
                                className="btn btn-link btn-block no-sub"
                                onClick={() => localStorage.setItem("iBBFS", true)}
                                >
                                Banners
                                </Link>
                                </div>
                                </PermissionsGate>
                                <PermissionsGate scopes={["bnbnview", "bnnlview", "bnpeview", "bnseview", "bnntview"]}>
                                <div
                                className={"card-header" + (currentTabValue[1].includes("notification") ? " active" : "")}
                                id="headingOne"
                                >
                                <a
                                className="btn btn-link btn-block collapsed "
                                data-toggle="collapse"
                                href="#collapseExamplecourse2"
                                role="button"
                                aria-expanded="false"
                                aria-controls="collapseExample"
                                >
                                Notifications
                                </a>
                                </div>
                                </PermissionsGate>
                                
                                <PermissionsGate scopes={["bnbnview"]}>
                                <div
                                className={
                                  "collapse card-header sub-menu-box" +
                                  (currentTabValue?.includes("bulk")
                                  ? " active"
                                  : "") +
                                  (pathname.includes("bulknotification") || (currentTabValue[1] == "notification" && currentTabValue[2] == "email")
                                  ? " show"
                                  : "")
                                }
                                id="collapseExamplecourse2"
                                >
                                <Link
                                className="btn btn-link btn-block no-sub sub-sub-tab"
                                to={{
                                  pathname:
                                  "/notification/bulknotification/bulk/table",
                                }}
                                onClick={() => localStorage.setItem("iBNFS", true)}
                                >
                                <span className="ml-2">Bulk Notifications</span>
                                </Link>
                                </div>
                                </PermissionsGate>
                                <PermissionsGate scopes={["bnnlview"]}>
                                <div
                                className={
                                  "collapse card-header sub-menu-box" +
                                  ((currentTabValue?.includes("notifications-list") || currentTabValue?.includes("pending-notifications") || currentTabValue?.includes("stopped-notifications"))
                                  ? " active"
                                  : "") +
                                  (pathname.includes("bulknotification") || (currentTabValue[1] == "notification" && currentTabValue[2] == "email")
                                  ? " show"
                                  : "")
                                }
                                id="collapseExamplecourse2"
                                >
                                <Link
                                className="btn btn-link btn-block no-sub sub-sub-tab"
                                to={{
                                  pathname:
                                  "/notification/bulknotification/pending-notifications/table",
                                  
                                }}
                                onClick={() => localStorage.setItem("iNLFS", true)}
                                >
                                <span className="ml-2">Individual Notifications</span>
                                </Link>
                                </div>
                                </PermissionsGate>
                                
                                {/* <PermissionsGate scopes={["bnpeview"]}>
                                  <div
                                  className={
                                  "collapse card-header sub-menu-box" +
                                  (currentTabValue?.includes("pending-notifications")
                                  ? " active"
                                  : "") +
                                  (pathname.includes("bulknotification") || (currentTabValue[1] == "notification" && currentTabValue[2] == "email")
                                  ? " show"
                                  : "")
                                  }
                                  id="collapseExamplecourse2"
                                  >
                                  <Link
                                  className="btn btn-link btn-block no-sub sub-sub-tab"
                                  to={{
                                  pathname:
                                  "/notification/bulknotification/pending-notifications/table",
                                  }}
                                  >
                                  <span className="ml-2">Pending Notifications</span>
                                  </Link>
                                  </div>
                                  </PermissionsGate>
                                  <PermissionsGate scopes={["bnseview"]}>
                                  <div
                                  className={
                                  "collapse card-header sub-menu-box" +
                                  (currentTabValue?.includes("stopped-notifications")
                                  ? " active"
                                  : "") +
                                  (pathname.includes("bulknotification") || (currentTabValue[1] == "notification" && currentTabValue[2] == "email")
                                  ? " show"
                                  : "")
                                  }
                                  id="collapseExamplecourse2"
                                  >
                                  <Link
                                  className="btn btn-link btn-block no-sub sub-sub-tab"
                                  to={{
                                  pathname:
                                  "/notification/bulknotification/stopped-notifications/table",
                                  }}
                                  >
                                  <span className="ml-2">Stopped Notifications</span>
                                  </Link>
                                  </div>
                                  </PermissionsGate> */}
                                  
                                  <PermissionsGate scopes={["bnntview"]}>
                                  <div className={"collapse card-header sub-menu-box" + ((currentTabValue?.includes("notifications-template") || currentTabValue?.includes("email") ? " active" : "") +
                                    (pathname.includes("bulknotification") || (currentTabValue[1] == "notification" && currentTabValue[2] == "email")
                                    ? " show" : ""))}
                                    id="collapseExamplecourse2"
                                    >
                                    <Link
                                    className="btn btn-link btn-block no-sub sub-sub-tab"
                                    to={{
                                      pathname:
                                      "/notification/bulknotification/notifications-template/table"
                                    }}
                                    onClick={() => localStorage.setItem("iNTFS", true)}
                                    >
                                    <span className="ml-2">Notification Templates</span>
                                    </Link>
                                    </div>
                                    </PermissionsGate>
                                    
                                    
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                    </li>
                                  )
                                );
                              } else if (
                                tab.id == 11 &&
                                user.status != "On Hold" &&
                                user.status != "In Active"
                              ) {
                                return (
                                  <li
                                  title="How-To Guides and Academic Resources"
                                  key={tab.id}
                                  onClick={() => dispatch(selectTab({ tab: tab.id }))}
                                  >
                                  <NavLink
                                  to={("/", "/" + tab.refLink)}
                                  className={
                                    tab.refLink == getMenuItem(currentTabValue[1])
                                    ? "active"
                                    : ""
                                  }
                                  >
                                  <span className="nav-icon">
                                  <i className={"fal " + tab.class}></i>
                                  </span>
                                  {tab.name}
                                  </NavLink>
                                  </li>
                                );
                              } else if (
                                tab.id == 3 &&
                                user.status != "On Hold" &&
                                user.status != "In Active"
                              ) {
                                return (
                                  checkPermissions([
                                    "capview",
                                    "cview",
                                    "caview",
                                    "cquestionbview",
                                    "cquestionbview",
                                    'cparview ',
                                    'cparview',
                                    'cquestionbview',
                                    'cquizmview',
                                    'cquizmview',
                                  ]) && (
                                    <li
                                    className={subMenuCourse}
                                    title="Course Administration"
                                    key={tab.id}
                                    onMouseOver={() => showSubMenu("courseAdministration")}
                                    onMouseOut={() => hideSubMenu("courseAdministration")}
                                    >
                                    <Link
                                    to="/links/course_administration"
                                    className={
                                      tab.refLink.includes(currentTabValue[1]) ||
                                      (currentTabValue[1] == ("courses")
                                      || currentTabValue[1] == ("content") ||
                                      currentTabValue.includes("courseAdministration") ||
                                      currentTabValue.includes("course_administration")) ||
                                      (currentTabValue.includes("supportAdministration") && !currentTabValue.includes("appeals"))
                                      ? "active"
                                      : ""
                                    }
                                    >
                                    <span className="nav-icon">
                                    <i className={"fal " + tab.class}></i>
                                    </span>
                                    {tab.name}
                                    </Link>
                                    <div className="sub-menu-content-block" >
                                    <div className="sub-menu-left-block">
                                    <button className="close-btn-sidebar">
                                    <i className="close-bar close-bar-1"></i>
                                    <i className="close-bar close-bar-2"></i>
                                    <i className="close-bar close-bar-3"></i>
                                    </button>
                                    <div
                                    className="sub-menu accordion"
                                    id="accordionExample"
                                    >
                                    <div className="card">
                                    <PermissionsGate scopes={["capview"]}>
                                    <div
                                    className={
                                      "card-header" +
                                      (currentTabValue[1] == ("courseAdministration") &&
                                      currentTabValue[2] == ("Programmes") &&
                                      (currentTabValue[3]?.includes("programme")
                                      || currentTabValue[3]?.includes("auditTrail"))
                                      ? " active"
                                      : "")
                                    }
                                    id="headingOne"
                                    >
                                    <Link
                                    to="/courseAdministration/Programmes/programme/show"
                                    className="btn btn-link btn-block no-sub"
                                    onClick={() => localStorage.setItem("iCPP", true)}
                                    >
                                    Programmes
                                    </Link>
                                    </div>
                                    </PermissionsGate>
                                    
                                    <PermissionsGate scopes={["cview"]}>
                                    <div
                                    className={
                                      "card-header" +
                                      (currentTabValue[1] == ("courseAdministration") &&
                                      currentTabValue[2]?.includes("courses") && !currentTabValue[3]?.includes("templateTable")
                                      ? " active"
                                      : "")
                                    }
                                    id="headingOne"
                                    >
                                    <Link
                                    to={{ pathname: "/courseAdministration/courses/In Progress" }}
                                    onClick={() => localStorage.setItem("iCFS", true)}
                                    className="btn btn-link btn-block no-sub"
                                    >
                                    Courses
                                    </Link>
                                    </div>
                                    </PermissionsGate>
                                    
                                    <PermissionsGate scopes={["caview"]}>
                                    <div
                                    className={
                                      "card-header" +
                                      ((currentTabValue[1] == ("courses") &&
                                      currentTabValue[2]?.includes("content")) || currentTabValue[1] == ("content")
                                      ? " active"
                                      : "")
                                    }
                                    id="headingOne"
                                    title="Content Assemblies"
                                    >
                                    <Link
                                    to="/courses/content/assemblies/table"
                                    className="btn btn-link btn-block no-sub"
                                    onClick={() => localStorage.setItem("iCCA", true)}
                                    
                                    >
                                    Content Assemblies
                                    </Link>
                                    </div>
                                    </PermissionsGate>
                                    {checkPermissions(["cquizmview", "cquestionbview"]) && <div
                                      className={
                                        "card-header" +
                                        (currentTabValue.includes("courseAdministration") && (currentTabValue.includes("assessment")
                                        || currentTabValue.includes("questionBank")
                                        || currentTabValue.includes("smartAssessment"))
                                        ? " active"
                                        : "")
                                      }
                                      id="headingOne"
                                      >
                                      <a
                                      className="btn btn-link btn-block collapsed "
                                      data-toggle="collapse"
                                      href="#collapseExamplecourse"
                                      role="button"
                                      aria-expanded="false"
                                      aria-controls="collapseExample"
                                      >
                                      Quizzes
                                      </a>
                                      </div>}
                                      
                                      
                                      
                                      <PermissionsGate scopes={["cquizmview"]}>
                                      <div
                                      className={
                                        "collapse card-header sub-menu-box" +
                                        ((currentTabValue[3]?.includes("quizzes")) || (currentTabValue[3]?.includes("quiz"))
                                        ? " active"
                                        : "") +
                                        ((pathname.includes("courseAdministration/assessment") || (pathname.includes("questionBank")) || (pathname.includes("smartAssessment")))
                                        ? " show"
                                        : "")
                                      }
                                      id="collapseExamplecourse"
                                      >
                                      <Link
                                      className="btn btn-link btn-block no-sub sub-sub-tab"
                                      to={{
                                        pathname:
                                        "/courseAdministration/assessment/quizzes/list",
                                        state: { isFromSidebar: true }
                                      }}
                                      onClick={() => localStorage.setItem("iAFS", true)}
                                      >
                                      <span className="ml-2">Quiz Management</span>
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      <PermissionsGate scopes={["cquestionbview"]}>
                                      <div
                                      className={
                                        "collapse card-header sub-menu-box" +
                                        (currentTabValue[3]?.includes("addquestion") ||
                                        currentTabValue[3]?.includes("questionBank")
                                        ? " active"
                                        : "") +
                                        ((pathname.includes("courseAdministration/assessment") || (pathname.includes("smartAssessment")))
                                        ? " show"
                                        : "")
                                      }
                                      id="collapseExamplecourse"
                                      >
                                      <Link
                                      className="btn btn-link btn-block no-sub sub-sub-tab"
                                      to={{
                                        pathname:
                                        "/courseAdministration/smartAssessment/questionBank"
                                      }}
                                      onClick={() => localStorage.setItem("iQBFS", true)}
                                      >
                                      <span className="ml-2">Question Bank</span>
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      <PermissionsGate scopes={["cparview"]}>
                                      <div
                                      className={
                                        "card-header" +
                                        (currentTabValue[1] == ("courseAdministration") &&
                                        currentTabValue[2] == ("gradingTemplate")
                                        //  && (currentTabValue[3]?.includes("show"))
                                        ? " active"
                                        : "")
                                      }
                                      id="headingOne"
                                      title="Gradebook Templates"
                                      >
                                      <Link
                                      to="/courseAdministration/gradingTemplate/table"
                                      className="btn btn-link btn-block no-sub"
                                      >
                                      Gradebook Templates
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      <PermissionsGate scopes={["intakeCopyView"]}>
                                      <div
                                      className={
                                        "card-header" +
                                        (currentTabValue[1] == ("courseAdministration") &&
                                        currentTabValue[2] == ("intake-qualification") &&
                                        (currentTabValue[3]?.includes("list-copied-intake")
                                        || currentTabValue[3]?.includes("audittrails")
                                        || currentTabValue[3]?.includes("all-copied-logs")
                                        || currentTabValue[3]?.includes("open-copied-intake"))
                                        ? " active"
                                        : "")
                                      }
                                      id="headingOne"
                                      >
                                      <Link
                                      to="/courseAdministration/intake-qualification/list-copied-intake"
                                      className="btn btn-link btn-block no-sub"
                                      onClick={() => localStorage.setItem("iCPP", true)}
                                      >
                                      Intake Copy
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      <PermissionsGate scopes={["cparview"]}>
                                      <div
                                      className={
                                        "card-header" +
                                        (currentTabValue[1] == ("courseAdministration") &&
                                        currentTabValue[2] == ("articulation") &&
                                        (currentTabValue[3]?.includes("list-articulations")
                                        || currentTabValue[3]?.includes("auditTrail")
                                        || currentTabValue[3]?.includes("open-articulation-registration"))
                                        ? " active"
                                        : "")
                                      }
                                      id="headingOne"
                                      >
                                      <Link
                                      to="/courseAdministration/articulation/list-articulations"
                                      className="btn btn-link btn-block no-sub"
                                      onClick={() => localStorage.setItem("iCPP", true)}
                                      >
                                      Programme Articulation
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      <PermissionsGate scopes={["capview"]}>
                                      <div
                                      className={
                                        "card-header" +
                                        (currentTabValue[1] == ("courseAdministration") &&
                                        (currentTabValue[2] === ("registration") ||
                                        currentTabValue[3] === ("open-re-registration") ||
                                        currentTabValue[3] === ("list-re-registration"))
                                        ? " active"
                                        : "")
                                      }
                                      id="headingOne"
                                      >
                                      <Link
                                      to="/courseAdministration/registration/list-re-registration"
                                      className="btn btn-link btn-block no-sub"
                                      onClick={() => localStorage.setItem("iCPP", true)}
                                      >
                                      Programme Re-Registration
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      <PermissionsGate scopes={["ccertview"]}>
                                      <div
                                      className={
                                        "card-header" +
                                        (currentTabValue[1] == ("courseAdministration") &&
                                        currentTabValue[2] == ("certificates")
                                        ? " active"
                                        : "")
                                      }
                                      id="headingOne"
                                      title="Certificate Templates"
                                      >
                                      <Link
                                      to="/courseAdministration/certificates/list"
                                      className="btn btn-link btn-block no-sub"
                                      >
                                      Certificate Templates
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      </div>
                                      </div>
                                      </div>
                                      </div>
                                      </li>
                                    )
                                  );
                                } else if (
                                  tab.id == 20 &&
                                  user.status != "On Hold" &&
                                  user.status != "In Active"
                                ) {
                                  return (
                                    checkPermissions([
                                      "sview",
                                      "regview",
                                      "ebeview",
                                      "prrview",
                                    ]) && (
                                      <li
                                      className={subMenuStudentAdministration}
                                      title="Student Administration"
                                      key={tab.id}
                                      onMouseOver={() => showSubMenu("studentAdministration")}
                                      onMouseOut={() => hideSubMenu("studentAdministration")}
                                      >
                                      <Link
                                      to="/links/student_administration"
                                      className={
                                        currentTabValue.includes("studentAdministration") ||
                                        currentTabValue.includes("studentadministration") ||
                                        currentTabValue.includes("student_administration") ||
                                        currentTabValue.includes("StudentAdministration")
                                        ? "active"
                                        : ""
                                      }
                                      >
                                      <span className="nav-icon">
                                      <i className={"fal " + tab.class}></i>
                                      </span>
                                      {tab.name}
                                      </Link>
                                      
                                      <div className="sub-menu-content-block">
                                      <div className="sub-menu-left-block">
                                      <button className="close-btn-sidebar">
                                      <i className="close-bar close-bar-1"></i>
                                      <i className="close-bar close-bar-2"></i>
                                      <i className="close-bar close-bar-3"></i>
                                      </button>
                                      <div
                                      className="sub-menu accordion"
                                      id="accordionExample"
                                      >
                                      <div className="card">
                                      <PermissionsGate scopes={["sview"]}>
                                      <div
                                      className={
                                        "card-header" +
                                        (pathname?.includes("students")
                                        ? " active"
                                        : "")
                                      }
                                      id="headingOne"
                                      >
                                      <Link
                                      to="/studentAdministration/students/table"
                                      className="btn btn-link btn-block no-sub"
                                      onClick={() => localStorage.setItem("iCSAS", true)}
                                      >
                                      Students
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      <PermissionsGate scopes={["regview"]}>
                                      <div
                                      className={
                                        "card-header" +
                                        (pathname?.includes("registration") && (!pathname.includes("externalExam"))
                                        ? " active"
                                        : "")
                                      }
                                      id="headingOne"
                                      >
                                      <Link
                                      to={{
                                        pathname:
                                        "/studentadministration/registration/studentlist",
                                      }}
                                      onClick={() => localStorage.setItem("iCSSR", true)}
                                      className="btn btn-link btn-block no-sub"
                                      >
                                      Student Registrations
                                      </Link>
                                      </div>
                                      </PermissionsGate>
                                      {/* <div
                                        className={
                                        "card-header" +
                                        (pathname?.includes("registration") && pathname?.includes("studentadministration")
                                        ? " active"
                                        : "")
                                        }
                                        id="headingOne"
                                        >
                                        <a
                                        className="btn btn-link btn-block collapsed "
                                        data-toggle="collapse"
                                        href="#collapseExampleregistration"
                                        role="button"
                                        aria-expanded="false"
                                        aria-controls="collapseExample"
                                        >
                                        Registrations
                                        </a>
                                        </div> */}
                                        
                                        {/* <PermissionsGate scopes={["regview"]}>
                                          <div
                                          className={
                                          "collapse card-header sub-menu-box" +
                                          (currentTabValue[2] == ("registration") &&
                                          (currentTabValue[3] === ("studentlist") ||
                                          currentTabValue[3] === ("open") ||
                                          currentTabValue[3] === ("create"))
                                          ? " active"
                                          : "") +
                                          (pathname.includes("registration")
                                          ? " show"
                                          : "")
                                          }
                                          id="collapseExampleregistration"
                                          >
                                          <Link
                                          className={
                                          "btn btn-link btn-block no-sub sub-sub-tab"
                                          }
                                          to={{
                                          pathname:
                                          "/studentadministration/registration/studentlist",
                                          }}
                                          onClick={() => localStorage.setItem("iCSSR", true)}
                                          >
                                          <span className="ml-2">
                                          Student Registrations
                                          </span>
                                          </Link>
                                          </div>
                                          </PermissionsGate> */}
                                          
                                          {/* <PermissionsGate scopes={["ebeview"]}>
                                            <div
                                            className={
                                            "collapse card-header sub-menu-box" +
                                            (pathname?.includes("registration") &&
                                            pathname?.includes("e-book")
                                            ? " active"
                                            : "") +
                                            (pathname.includes("registration")
                                            ? " show"
                                            : "")
                                            }
                                            id="collapseExampleregistration"
                                            > }
                                            <Link
                                            className={
                                            "btn btn-link btn-block mt-0 no-sub sub-sub-tab"
                                            }
                                            to={{
                                            pathname:
                                            "/studentadministration/registration/e-book",
                                            }}
                                            onClick={() => localStorage.setItem("iCSBE", true)}
                                            >
                                            <span className="ml-2">E-Books Export</span>
                                            </Link>
                                            </div>
                                            </PermissionsGate> */}
                                            
                                            {/* <PermissionsGate scopes={["prrview"]}>
                                              <div
                                              className={
                                              "collapse card-header sub-menu-box" +
                                              (pathname.includes("registration") &&
                                              (currentTabValue[3] === ("audittrails") ||
                                              currentTabValue[3] === ("open-re-registration") ||
                                              currentTabValue[3] === ("list-re-registration") ||
                                              currentTabValue[3] === ("list-student-status"))
                                              
                                              ? " active"
                                              : "") +
                                              (pathname.includes("registration")
                                              ? " show"
                                              : "")
                                              }
                                              id="collapseExampleregistration"
                                              >
                                              <Link
                                              className="btn btn-link btn-block mt-0 no-sub sub-sub-tab"
                                              to={{
                                              pathname:
                                              "/studentadministration/registration/list-re-registration",
                                              }}
                                              onClick={() => localStorage.setItem("iCSPR", true)}
                                              >
                                              <span className="ml-2">
                                              Programme Re-Registration
                                              </span>
                                              </Link>
                                              </div>
                                              </PermissionsGate>*/}
                                              
                                              {checkPermissions(["eeepview", "eerleview", "eeecview", "eeesview"]) && <div
                                                className={
                                                  "card-header" +
                                                  (pathname.includes("externalExam")
                                                  ? " active"
                                                  : "")
                                                }
                                                id="headingOne"
                                                >
                                                <a
                                                // to="/studentAdministration/externalExam/details/table"
                                                className="btn btn-link btn-block collapsed "
                                                data-toggle="collapse"
                                                href="#collapseExternalExamSetting"
                                                role="button"
                                                aria-expanded="false"
                                                aria-controls="collapseExample"
                                                >
                                                External Exam Registrations
                                                </a>
                                                </div>}
                                                <PermissionsGate scopes={["eeepview"]}>
                                                <div
                                                className={
                                                  "collapse card-header sub-menu-box" +
                                                  (currentTabValue[2] == ("externalExam") &&
                                                  (currentTabValue[3] === ("examPeriod"))
                                                  ? " active"
                                                  : "") +
                                                  (pathname.includes("externalExam")
                                                  ? " show"
                                                  : "")
                                                }
                                                id="collapseExternalExamSetting"
                                                >
                                                <Link
                                                className={
                                                  "btn btn-link btn-block no-sub sub-sub-tab"
                                                }
                                                to={{
                                                  pathname:
                                                  "/studentAdministration/externalExam/examPeriod/table",
                                                }}
                                                onClick={() => localStorage.setItem("iSEPP", true)}
                                                >
                                                <span className="ml-2">
                                                Exam Period
                                                </span>
                                                </Link>
                                                </div>
                                                </PermissionsGate>
                                                <PermissionsGate scopes={["eerleview"]}>
                                                <div
                                                className={
                                                  "collapse card-header sub-menu-box" +
                                                  (currentTabValue[2] == ("externalExam") &&
                                                  (currentTabValue[3] === ("details"))
                                                  ? " active"
                                                  : "") +
                                                  (pathname.includes("externalExam")
                                                  ? " show"
                                                  : "")
                                                }
                                                id="collapseExternalExamSetting"
                                                >
                                                <Link
                                                className={
                                                  "btn btn-link btn-block no-sub sub-sub-tab"
                                                }
                                                to={{
                                                  pathname:
                                                  "/studentAdministration/externalExam/details/table",
                                                }}
                                                onClick={() => localStorage.setItem("iSEE", true)}
                                                >
                                                <span className="ml-2">
                                                Exams
                                                </span>
                                                </Link>
                                                </div>
                                                </PermissionsGate>
                                                <PermissionsGate scopes={["eeesview"]}>
                                                <div
                                                className={
                                                  "collapse card-header sub-menu-box" +
                                                  (currentTabValue[2] == ("externalExam") &&
                                                  (currentTabValue[3] === ("examSettings"))
                                                  ? " active"
                                                  : "") +
                                                  (pathname.includes("externalExam")
                                                  ? " show"
                                                  : "")
                                                }
                                                id="collapseExternalExamSetting"
                                                >
                                                <Link
                                                className={
                                                  "btn btn-link btn-block no-sub sub-sub-tab"
                                                }
                                                to={{
                                                  pathname:
                                                  "/studentAdministration/externalExam/examSettings/add",
                                                }}
                                                onClick={() => localStorage.setItem("iSEES", true)}
                                                >
                                                <span className="ml-2">
                                                Exam Settings
                                                </span>
                                                </Link>
                                                </div>
                                                </PermissionsGate>
                                                <PermissionsGate scopes={["eeecview"]}>
                                                <div
                                                className={
                                                  "collapse card-header sub-menu-box" +
                                                  (currentTabValue[2] == ("externalExam") &&
                                                  (currentTabValue[3] === ("examCentres"))
                                                  ? " active"
                                                  : "") +
                                                  (pathname.includes("externalExam")
                                                  ? " show"
                                                  : "")
                                                }
                                                id="collapseExternalExamSetting"
                                                >
                                                <Link
                                                className={
                                                  "btn btn-link btn-block no-sub sub-sub-tab"
                                                }
                                                to={{
                                                  pathname:
                                                  "/studentAdministration/externalExam/examCentres/table",
                                                }}
                                                onClick={() => localStorage.setItem("iSEEC", true)}
                                                >
                                                <span className="ml-2">
                                                Exam Centres
                                                </span>
                                                </Link>
                                                </div>
                                                </PermissionsGate>
                                                
                                                
                                                </div>
                                                </div>
                                                </div>
                                                </div>
                                                </li>
                                              )
                                            );
                                          } else if (
                                            tab.id == 21 &&
                                            user.status != "On Hold" &&
                                            user.status != "In Active"
                                          ) {
                                            return (
                                              <li
                                              title="How-To Guides and Academic Resources"
                                              key={tab.id}
                                              onClick={() => dispatch(selectTab({ tab: tab.id }))}
                                              >
                                              <NavLink
                                              to={("/", "/" + tab.refLink)}
                                              className={
                                                tab.refLink == getMenuItem(currentTabValue[1])
                                                ? "active"
                                                : ""
                                              }
                                              >
                                              <span className="nav-icon">
                                              <i className={"fal " + tab.class}></i>
                                              </span>
                                              {tab.name}
                                              </NavLink>
                                              </li>
                                            );
                                          }
                                          else if (
                                            tab.id == 22 &&
                                            user.status != "On Hold" &&
                                            user.status != "In Active"
                                          ) {
                                            return checkPermissions([
                                              "cabookmanview",
                                              "caschedmanmview",
                                              "sacmview",
                                              "casmartsignview"
                                            ]) && (
                                              <li
                                              className={subMenuCampusAdministration}
                                              title="Campus Administration"
                                              key={tab.id}
                                              onMouseOver={() => showSubMenu("campusAdministration")}
                                              onMouseOut={() => hideSubMenu("campusAdministration")}
                                              >
                                              <Link
                                              to="/links/campus_administration"
                                              className={
                                                currentTabValue.includes("campusAdministration") ||
                                                currentTabValue.includes("campusadministration") ||
                                                currentTabValue.includes("campus_administration") ||
                                                currentTabValue.includes("CampusAdministration") ||
                                                currentTabValue.includes("campusManagement")
                                                ? "active"
                                                : ""
                                              }           
                                              >
                                              <span className="nav-icon">
                                              <i className={"fal " + tab.class}></i>
                                              </span>
                                              {tab.name}
                                              </Link>
                                              <div className="sub-menu-content-block" >
                                              <div className="sub-menu-left-block">
                                              <button className="close-btn-sidebar">
                                              <i className="close-bar close-bar-1"></i>
                                              <i className="close-bar close-bar-2"></i>
                                              <i className="close-bar close-bar-3"></i>
                                              </button>
                                              <div
                                              className="sub-menu accordion"
                                              id="accordionExample"
                                              >
                                              <div className="card">
                                              <PermissionsGate scopes={["sacmview"]}>
                                              <div className={"card-header" + (pathname?.includes("campusList") ? " active" : "")} id="headingOne" title="campuses">
                                              <Link
                                              to={{ pathname: "/administration/campusManagement/campusList" }}
                                              onClick={() => localStorage.setItem("iCampAdmin", true)}
                                              className="btn btn-link btn-block no-sub"
                                              >
                                              Campus Management
                                              </Link>
                                              </div>
                                              </PermissionsGate>
                                              <PermissionsGate scopes={["caschedmanmview"]}>
                                              <div className={"card-header" + (pathname?.includes("schedule") ? " active" : "")} id="headingTwo" title="schedules">
                                              <Link
                                              to={{ pathname: "/campusAdministration/schedule/calender"}}
                                              className="btn btn-link btn-block no-sub"
                                              onClick={() => localStorage.setItem("iSchedM", true)}
                                              >
                                              Schedule Management
                                              </Link>
                                              </div>
                                              </PermissionsGate>
                                              <PermissionsGate scopes={["cabookmanview"]}>
                                              <div className={"card-header" + (pathname?.includes("bookings") ? " active" : "")} id="headingThree" title="bookings">
                                              <Link
                                              to="/campusAdministration/bookings/table"
                                              className="btn btn-link btn-block no-sub"
                                              onClick={() => localStorage.setItem("iBookM", true)}
                                              >
                                              Booking Management
                                              </Link>
                                              </div>
                                              </PermissionsGate>
                                              <PermissionsGate scopes={["casmartsignview"]}>
                                              <div className={"card-header" + (pathname?.includes("smartsignage") ? " active" : "")} id="headingThree" title="signage">
                                              <Link
                                              to="/campusAdministration/smartsignage/list"
                                              className="btn btn-link btn-block no-sub"
                                              onClick={() => localStorage.setItem("iSmartSign", true)}
                                              >
                                              Smart Signage
                                              </Link>
                                              </div>
                                              </PermissionsGate>
                                              </div>
                                              </div>
                                              </div>
                                              </div>
                                              </li>
                                            )
                                          }
                                        })}
                                        </ul>
                                        </div>
                                        </>
                                      );
                                    };
                                    
                                    export default SideBar;
                                    