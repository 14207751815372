import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { GlobalIconNames } from "../../../utils/GlobalIconNames";
import PermissionsGate from "../../../utils/permissionGate";
import AddNotification from "./AddNotification";
import AuditTrail from "./AuditTrail";
import Details from "./Details";
import GradeTab from "./GradeTab/GradeTab";
import ListOfPrograms from "./ListOfPrograms";
import Modules from "./Modules";
import Notification from "./Notification";
import ProgrameGradeSetting from "./ProgrameGradeSetting";
import ProgrammeForm from "./ProgrammeForm";
import ProgrammeGradeBookTable from "./ProgrammeGradeBookTable";
import Students from "./Students";
import ProgrammeGradeMix from "./ProgrammeGradeMix";
import ProgrammeGradeMixForm from "./ProgrammeGradeMixForm";
import ProgrammeGrademixGradeBookTable from "./ProgrammeGrademixGradeBookTable";

function DetailsOfPrograms({setEnableProgramOverview,enableProgramOverview, programmeName, programmeDetails, setProgrammeData, timeTableDoc, timeTableDocName, setTimeTableDoc, setTimeTableDocName, studentDetails, refresh, setRefresh }) {
  const history = useHistory();
  const { type, subtype, id, subId } = useParams();
  return (
    <>
      {type === "show" && <ListOfPrograms />}
      {type === "add" && <ProgrammeForm enableProgramOverview={enableProgramOverview} setEnableProgramOverview={setEnableProgramOverview} programmeFunction={setProgrammeData} timeTableDoc={timeTableDoc} timeTableDocName={timeTableDocName} setTimeTableDoc={setTimeTableDoc} setTimeTableDocName={setTimeTableDocName} programmeData={programmeDetails} />}
      {type === "duplicate" && <ProgrammeForm enableProgramOverview={enableProgramOverview} setEnableProgramOverview={setEnableProgramOverview} programmeFunction={setProgrammeData} timeTableDoc={timeTableDoc} timeTableDocName={timeTableDocName} setTimeTableDoc={setTimeTableDoc} setTimeTableDocName={setTimeTableDocName} programmeData={programmeDetails} />}
      {type === "open" && (
        <div className="tabs-wrap">
          <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <PermissionsGate scopes={["capview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/details`)}>
                <a className={`nav-link cursor-pointer ${subtype === "details" ? "active" : ""}`}>
                {GlobalIconNames("details").html}
                </a>
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["psview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/students`)}>
                <a className={`nav-link cursor-pointer ${subtype === "students" ? "active" : ""}`}>
                {GlobalIconNames("Students").html}
                </a>
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["pcview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/Courses`)}>
                <a className={`nav-link cursor-pointer ${subtype === "Courses" ? "active" : ""}`}>
                {GlobalIconNames("Courses").html}
                </a>
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["pnview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/notification`)}>
                <a
                  className={`nav-link cursor-pointer ${subtype === "notification" || subtype === "addNotification" || subtype === "editNotification" ? "active" : ""}`}
                >
                  {GlobalIconNames("notifications").html}
                </a>
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["capgview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/grade/table`)}>
                <a
                  className={`nav-link cursor-pointer ${subtype === "grade" ? "active" : ""}`}
                >
                  <i className="fal fa-chart-bar"></i>Grades
                </a> 
              </li>
            </PermissionsGate>
            <PermissionsGate scopes={["capgseview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/gradeSetting/table`)}>
                <a
                  className={`nav-link cursor-pointer ${subtype === "gradeSetting" ? "active" : ""}`}
                >
                  <i className="fal fa-sliders-h-square"></i>Grade Settings
                </a> 
              </li>
            </PermissionsGate>
            {programmeDetails?.grading_standard == 2   && <PermissionsGate scopes={["pgmixview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/gradeMix/table`)}>
                <a
                  className={`nav-link cursor-pointer ${subtype === "gradeMix" ? "active" : ""}`}
                >
                  <i className="fal fa-layer-group"></i>Grade Mix
                </a> 
              </li>
            </PermissionsGate>}
            {/* <PermissionsGate scopes={["pnview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/gradebook/table`)}>
                <a
                  className={`nav-link cursor-pointer ${subtype === "gradebook" ? "active" : ""}`}
                >
                  <i className="fal fa-users"></i>Gradebook
                </a> 
              </li>
            </PermissionsGate> */}
            <PermissionsGate scopes={["pnview"]}>
              <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/open/${id}/singleAuditTrail`)}>
                <a className={`nav-link cursor-pointer ${subtype == "singleAuditTrail" ? "active" : ""}`}>
                {GlobalIconNames("audittrail").html}
                </a>
              </li>
            </PermissionsGate>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
              {subtype === "details" && <Details enableProgramOverview={enableProgramOverview} setEnableProgramOverview={setEnableProgramOverview} programmeFunction={setProgrammeData} timeTableDoc={timeTableDoc} timeTableDocName={timeTableDocName} setTimeTableDoc={setTimeTableDoc} setTimeTableDocName={setTimeTableDocName} programmeData={programmeDetails} refresh={refresh} setRefresh={setRefresh}/>}
            </div>
            <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
              {subtype === "students" && <Students programmeDetails={programmeDetails}/>}
            </div>
            <div className="tab-pane fade active show" id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab3">
              {subtype === "Courses" && <Modules refresh={refresh} setRefresh={setRefresh} />}
            </div>
            <div className="tab-pane fade active show" id="pills-tab14" role="tabpanel" aria-labelledby="pills-tab4">
              {subtype === "notification" && <Notification />}
              {subtype === "addNotification" && <AddNotification programmeName={programmeName} />}
              {subtype === "editNotification" && <AddNotification programmeName={programmeName} />}
            </div>
            <div className="tab-pane fade active show" id="pills-tab15" role="tabpanel" aria-labelledby="pills-tab5">
              {subtype == "singleAuditTrail" && <AuditTrail />}
            </div>
            <div className="tab-pane fade active show" id="pills-tab18" role="tabpanel" aria-labelledby="pills-tab8">
              {subtype === "grade" && <GradeTab programmeName={programmeName} gradingStandard={programmeDetails?.grading_standard}/>}
            </div>
            <div className="tab-pane fade active show" id="pills-tab19" role="tabpanel" aria-labelledby="pills-tab9">
              {subtype === "gradeSetting" && <ProgrameGradeSetting gradingStandard={programmeDetails?.grading_standard}/>}
            </div>
            <div className="tab-pane fade active show" id="pills-tab22" role="tabpanel" aria-labelledby="pills-tab10">
              {subtype === "gradeMix" && subId !== "add" && <ProgrammeGradeMix/>}
            </div>
            <div className="tab-pane fade active show" id="pills-tab23" role="tabpanel" aria-labelledby="pills-tab11">
              {subtype === "gradeMix" && subId === "add" && <ProgrammeGradeMixForm/>}
            </div>
           {programmeDetails && programmeDetails.grading_standard == 2 ? (
              <div className="tab-pane fade active show" id="pills-tab21" role="tabpanel" aria-labelledby="pills-tab21">
                    {subtype === "gradebook" && <ProgrammeGrademixGradeBookTable programmeDetails={programmeDetails} studentDetails={studentDetails}/>}
                  </div>
              ) : (
              <div className="tab-pane fade active show" id="pills-tab21" role="tabpanel" aria-labelledby="pills-tab21">
                    {subtype === "gradebook" && <ProgrammeGradeBookTable programmeDetails={programmeDetails} studentDetails={studentDetails}/>}
                  </div>
              )
            }
          </div>
        </div>
      )}
      {/* {type === "duplicate" && (
        <div className="tabs-wrap">
          <ul className="nav nav-pills mb-4" id="tabs" role="tablist">
            <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/duplicate/${id}/details`)}>
              <a className={`nav-link cursor-pointer ${subtype === "details" ? "active" : ""}`} id="pills-tab1" data-toggle="pill" role="tab" aria-controls="pills-tab11" aria-selected="true">
                <i className="fal fa-chart-pie-alt"></i>
                Details
              </a>
            </li>
            <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/duplicate/${id}/students`)}>
              <a className={`nav-link cursor-pointer ${subtype === "students" ? "active" : ""}`} id="pills-tab2" data-toggle="pill" role="tab" aria-controls="pills-tab12" aria-selected="true">
                <i className="fal fa-books"></i>Students
              </a>
            </li>
            <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/duplicate/${id}/Courses`)}>
              <a className={`nav-link cursor-pointer ${subtype === "Courses" ? "active" : ""}`} id="pills-tab3" data-toggle="pill" role="tab" aria-controls="pills-tab13" aria-selected="true">
                <i className="fal fa-file-alt"></i>Courses
              </a>
            </li>
            <li className="nav-item" onClick={() => history.push(`/courseAdministration/Programmes/programme/duplicate/${id}/notification`)}>
              <a className={`nav-link cursor-pointer ${subtype === "notification" ? "active" : ""}`} id="pills-tab4" data-toggle="pill" role="tab" aria-controls="pills-tab14" aria-selected="true">
                <i className="fas fa-bars"></i>Notification
              </a>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade active show" id="pills-tab11" role="tabpanel" aria-labelledby="pills-tab1">
              {subtype === "details" && <Details />}
            </div>
            <div className="tab-pane fade active show" id="pills-tab12" role="tabpanel" aria-labelledby="pills-tab2">
              {subtype === "students" && <Students />}
            </div>
            <div className="tab-pane fade active show" id="pills-tab13" role="tabpanel" aria-labelledby="pills-tab3">
              {subtype === "Courses" && <Modules />}
            </div>
            <div className="tab-pane fade active show" id="pills-tab14" role="tabpanel" aria-labelledby="pills-tab4">
              {subtype === "notification" && <Notification />}
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}

export default DetailsOfPrograms;
